import { visionURL } from "../../../config/environment";
import normalize from "../../utils/normalize";
import JsonApiService from "../../../services/jsonapiService";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export const FETCH_ACTIVITY_BEGIN = "FETCH_ACTIVITY_BEGIN";
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const FETCH_ACTIVITY_FAILURE = "FETCH_ACTIVITY_FAILURE";

const activitiesApiService = async userAssignmentId =>
  new JsonApiService("activities", {
    baseUrl: `${visionURL}/learning_center/api/user_assignments/${userAssignmentId}`,
    credentials: "include"
  });

export const fetchActivityBegin = () => ({
  type: FETCH_ACTIVITY_BEGIN
});

export const fetchActivitySuccess = activityData => ({
  type: FETCH_ACTIVITY_SUCCESS,
  payload: activityData
});

export const fetchActivityFailure = error => ({
  type: FETCH_ACTIVITY_FAILURE,
  payload: { error }
});

export const fetchData = (
  userAssignmentId,
  courseContentableId,
  preloaded = false
) => {
  return async dispatch => {
    const service = await activitiesApiService(userAssignmentId);
    dispatch(fetchActivityBegin());
    const json = await service
      .customParams({ preloaded })
      .find(courseContentableId)
      .then(response => response.json());
    if (json.errors) {
      dispatch(fetchActivityFailure(json.errors));
    } else {
      dispatch(fetchActivitySuccess(normalize(json)));
    }
    return dataFormatter.deserialize(json);
  };
};
