import visionCallService from "../../../../services/visionCallService";
import { visionURL } from "../../../../config/environment";

export const FETCH_AC_CONTENT_ASSIGNMENTS_BEGIN =
  "FETCH_AC_CONTENT_ASSIGNMENTS_BEGIN";
export const FETCH_AC_CONTENT_ASSIGNMENTS_SUCCESS =
  "FETCH_AC_CONTENT_ASSIGNMENTS_SUCCESS";
export const FETCH_AC_CONTENT_ASSIGNMENTS_FAILURE =
  "FETCH_AC_CONTENT_ASSIGNMENTS_FAILURE";
export const UPDATE_AC_CONTENT_ASSIGNMENTS_BEGIN =
  "UPDATE_AC_CONTENT_ASSIGNMENTS_BEGIN";
export const UPDATE_AC_CONTENT_ASSIGNMENTS_SUCCESS =
  "UPDATE_AC_CONTENT_ASSIGNMENTS_SUCCESS";
export const UPDATE_AC_CONTENT_ASSIGNMENTS_FAILURE =
  "UPDATE_AC_CONTENT_ASSIGNMENTS_FAILURE";
export const UPDATE_AC_CONTENT_ASSIGNMENTS_JOB_SUCCESS =
  "UPDATE_AC_CONTENT_ASSIGNMENTS_JOB_SUCCESS";

const fetchReferenceBegin = () => ({
  type: FETCH_AC_CONTENT_ASSIGNMENTS_BEGIN,
  payload: {}
});

const fetchReferenceSuccess = referenceObjects => ({
  type: FETCH_AC_CONTENT_ASSIGNMENTS_SUCCESS,
  payload: { referenceObjects }
});

const fetchReferenceFailure = error => ({
  type: FETCH_AC_CONTENT_ASSIGNMENTS_FAILURE,
  payload: { error }
});

const updateReferenceBegin = () => ({
  type: UPDATE_AC_CONTENT_ASSIGNMENTS_BEGIN,
  payload: {}
});

const updateReferenceSuccess = result => ({
  type: UPDATE_AC_CONTENT_ASSIGNMENTS_SUCCESS,
  payload: { result }
});

const updateReferenceFailure = error => ({
  type: UPDATE_AC_CONTENT_ASSIGNMENTS_FAILURE,
  payload: { error }
});

export function fetchData(courseId, type, successCallback) {
  return dispatch => {
    dispatch(fetchReferenceBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/admin_console/api/content/courses/${courseId}/assignments?type=${type}`,
      function(response) {
        successCallback(response);
        dispatch(fetchReferenceSuccess(response));
      },
      function(error) {
        fetchReferenceFailure(error);
      }
    );
  };
}

export function updateAssignments(courseId, type, idsToAdd, idsToRemove) {
  const data = visionCallService.jsonApiSerialize("assignments", {
    type: type,
    idsToAdd: idsToAdd,
    idsToRemove: idsToRemove
  });
  return dispatch => {
    dispatch(updateReferenceBegin());
    return visionCallService.putJsonDataRequest(
      `${visionURL}/admin_console/api/content/courses/${courseId}/assignments`,
      data,
      function(response) {
        dispatch(updateReferenceSuccess(response));
      },
      function(error) {
        updateReferenceFailure(error);
      }
    );
  };
}

export function markJobFinished() {
  return dispatch => {
    dispatch({
      type: UPDATE_AC_CONTENT_ASSIGNMENTS_JOB_SUCCESS,
      payload: {}
    });
  };
}
