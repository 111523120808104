import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = usersData => ({
  type: FETCH_USERS_SUCCESS,
  payload: {
    page: usersData.page,
    totalCount: usersData.totalCount,
    users: usersData
  }
});

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error }
});

export const fetchEmployees = (tableQuery, externalQuery) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/administration/employees/users?`;
  apiUrl += visionCallService.buildQueryString(externalQuery);
  apiUrl += visionCallService.buildTableDataQueryString(tableQuery);

  dispatch(fetchUsersBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchUsersSuccess(response));
    },
    function(error) {
      dispatch(fetchUsersFailure(error));
    }
  );
};
