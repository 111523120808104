export const SHOW_CONTENT_PLAYER_SPINNER = "SHOW_CONTENT_PLAYER_SPINNER";
export const HIDE_CONTENT_PLAYER_SPINNER = "HIDE_CONTENT_PLAYER_SPINNER";

export const showSpinner = () => ({
  type: SHOW_CONTENT_PLAYER_SPINNER
});

export const hideSpinner = () => ({
  type: HIDE_CONTENT_PLAYER_SPINNER
});
