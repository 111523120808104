import {
  FETCH_SETTINGS_BEGIN,
  FETCH_BANNERS_BEGIN,
  FETCH_SETTINGS_SUCCESS,
  FETCH_BANNERS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
  FETCH_BANNERS_FAILURE,
  UPDATE_SETTINGS_BEGIN,
  UPDATE_BANNERS_BEGIN,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAILURE
} from "../actions/learningCenterSettingsActions";

const INITIAL_STATE = {
  logoImage: "",
  policyLogoImage: "",
  learningCenterCaption: "Learning Center",
  logoStyle: "logo_and_text",
  adminConsoleLogoImage: "",
  customColor: null,
  secondaryColor: null,
  noBanner: true,
  bannerImages: [],
  banner_loading: false,
  loading: false,
  error: null
};

function learningCenterSettingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SETTINGS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_BANNERS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        banner_loading: true,
        error: null
      };

    case FETCH_SETTINGS_SUCCESS: {
      console.log("FETCH_SETTINGS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        logoImage: action.payload.settings.logo,
        policyLogoImage: action.payload.settings.policyLogo,
        learningCenterCaption: action.payload.settings.text,
        logoStyle: action.payload.settings.logoStyle,
        customColor: action.payload.settings.primaryColor,
        secondaryColor: action.payload.settings.secondaryColor,
        noBanner: action.payload.settings.noBanner,
        adminConsoleLogoImage: action.payload.settings.acLogo
      };
    }

    case FETCH_BANNERS_SUCCESS: {
      console.log("FETCH_BANNERS_SUCCESS", action.payload);
      return {
        ...state,
        banner_loading: false,
        bannerImages: action.payload.banners
      };
    }

    case FETCH_SETTINGS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log("FETCH_SETTINGS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    case FETCH_BANNERS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log("FETCH_BANNERS_FAILURE", action.payload);
      return {
        ...state,
        banner_loading: false,
        error: action.payload.error,
        items: []
      };

    case UPDATE_SETTINGS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_BANNERS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        banner_loading: true,
        error: null
      };

    case UPDATE_SETTINGS_SUCCESS: {
      console.log("UPDATE_SETTINGS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        [action.payload.key]: action.payload.value
      };
    }

    case UPDATE_SETTINGS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log("UPDATE_SETTINGS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    case UPDATE_BANNER_SUCCESS: {
      console.log("UPDATE_BANNER_SUCCESS", action.payload);
      return {
        ...state,
        banner_loading: false,
        bannerImages: action.payload.banners
      };
    }

    case UPDATE_BANNER_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log("UPDATE_BANNER_FAILURE", action.payload);
      return {
        ...state,
        banner_loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default learningCenterSettingsReducer;
