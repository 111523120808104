import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";
import { deserialize } from "../utils/jsona";
import getToken from "../../services/tokenService";
import JsonApiService from "../../services/jsonapiService";
import normalize from "../../redux/utils/normalize";
import { uploadFile } from "../../services/fileUploadService";
import { enqueueErrorSnackbar } from "./snackbarActions";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export const FETCH_REPOSITORY_BEGIN = "FETCH_REPOSITORY_BEGIN";
export const FETCH_REPOSITORY_SUCCESS = "FETCH_REPOSITORY_SUCCESS";
export const FETCH_REPOSITORY_FAILURE = "FETCH_REPOSITORY_FAILURE";
export const FETCH_REPOSITORIES_BEGIN = "FETCH_REPOSITORIES_BEGIN";
export const FETCH_REPOSITORIES_SUCCESS = "FETCH_REPOSITORIES_SUCCESS";
export const FETCH_REPOSITORIES_FAILURE = "FETCH_REPOSITORIES_FAILURE";
export const FETCH_INTEGRATIONS_BEGIN = "FETCH_INTEGRATIONS_BEGIN";
export const FETCH_INTEGRATIONS_SUCCESS = "FETCH_INTEGRATIONS_SUCCESS";
export const FETCH_INTEGRATIONS_FAILURE = "FETCH_INTEGRATIONS_FAILURE";
export const CREATE_INTEGRATIONS_BEGIN = "CREATE_INTEGRATIONS_BEGIN";
export const CREATE_INTEGRATIONS_SUCCESS = "CREATE_INTEGRATIONS_SUCCESS";
export const CREATE_INTEGRATIONS_FAILURE = "CREATE_INTEGRATIONS_FAILURE";

export const FETCH_ITEMS_BEGIN = "FETCH_ITEMS_BEGIN";
export const FETCH_ITEM_BEGIN = "FETCH_ITEM_BEGIN";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEM_SUCCESS = "FETCH_ITEM_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";
export const UPDATE_ITEMS_BEGIN = "UPDATE_ITEMS_BEGIN";
export const UPDATE_ITEMS_SUCCESS = "UPDATE_ITEMS_SUCCESS";
export const UPDATE_ITEMS_FAILURE = "UPDATE_ITEMS_FAILURE";
export const CREATE_ITEMS_BEGIN = "CREATE_ITEMS_BEGIN";
export const CREATE_ITEMS_SUCCESS = "CREATE_ITEMS_SUCCESS";
export const CREATE_ITEMS_FAILURE = "CREATE_ITEMS_FAILURE";
export const CLEAR_ITEMS = "CLEAR_ITEMS";

export const fetchRepositoryBegin = () => ({
  type: FETCH_REPOSITORY_BEGIN
});

export const fetchRepositorySuccess = repositories => ({
  type: FETCH_REPOSITORY_SUCCESS,
  payload: repositories
});

export const fetchRepositoryFailure = error => ({
  type: FETCH_REPOSITORY_FAILURE,
  payload: { error }
});

export const fetchRepositoriesBegin = () => ({
  type: FETCH_REPOSITORIES_BEGIN
});

export const fetchRepositoriesSuccess = repositories => ({
  type: FETCH_REPOSITORIES_SUCCESS,
  payload: repositories
});

export const fetchRepositoriesFailure = error => ({
  type: FETCH_REPOSITORIES_FAILURE,
  payload: { error }
});

export const fetchIntegrationsBegin = () => ({
  type: FETCH_INTEGRATIONS_BEGIN
});

export const fetchIntegrationsSuccess = integrations => {
  return {
    type: FETCH_INTEGRATIONS_SUCCESS,
    payload: integrations
  };
};

export const fetchIntegrationsFailure = error => ({
  type: FETCH_INTEGRATIONS_FAILURE,
  payload: { error }
});

export const createIntegrationsBegin = () => ({
  type: CREATE_INTEGRATIONS_BEGIN
});

export const createIntegrationsSuccess = integration => ({
  type: CREATE_INTEGRATIONS_SUCCESS,
  payload: integration
});

export const createIntegrationsFailure = error => ({
  type: CREATE_INTEGRATIONS_FAILURE,
  payload: { error }
});

export const fetchItemsBegin = payload => ({
  type: FETCH_ITEMS_BEGIN,
  payload
});

export const fetchItemBegin = payload => ({
  type: FETCH_ITEM_BEGIN,
  payload
});

export const fetchItemsSuccess = payload => ({
  type: FETCH_ITEMS_SUCCESS,
  payload
});

export const fetchItemSuccess = item => ({
  type: FETCH_ITEM_SUCCESS,
  payload: item
});

export const fetchItemsFailure = error => ({
  type: FETCH_ITEMS_FAILURE,
  payload: { error }
});

export const updateItemsBegin = repositoryItemData => ({
  type: UPDATE_ITEMS_BEGIN,
  payload: { repositoryItemData }
});

export const updateItemsSuccess = repositories => ({
  type: UPDATE_ITEMS_SUCCESS,
  payload: repositories
});

export const updateItemsFailure = error => ({
  type: UPDATE_ITEMS_FAILURE,
  payload: { error }
});

export const createItemsBegin = () => ({
  type: CREATE_ITEMS_BEGIN
});

export const createItemsSuccess = repositories => ({
  type: CREATE_ITEMS_SUCCESS,
  payload: repositories
});

export const createItemsFailure = error => ({
  type: CREATE_ITEMS_FAILURE,
  payload: { error }
});

export const clearItems = () => ({
  type: CLEAR_ITEMS,
  payload: { itemList: [] }
});

export const createItem = (
  repositoryItemData,
  successCallback,
  errorCallback
) => async dispatch => {
  const createItemRecord = async data => {
    const service = await repositoryApiService("items");
    const json = await service
      .create({ ...data, type: "items" })
      .then(response => response.json());
    if (json.errors) {
      dispatch(createItemsFailure(json));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback(json.errors);
      }
      json.errors.forEach(x =>
        dispatch(enqueueErrorSnackbar({ message: x.title }))
      );
    } else {
      dispatch(createItemsSuccess(normalize(json)));
      if (successCallback && typeof successCallback === "function") {
        successCallback(json);
      }
    }
    return json;
  };

  dispatch(createItemsBegin());
  if (!repositoryItemData.file) {
    return createItemRecord(repositoryItemData);
  }

  const token = await getToken();
  uploadFile(
    repositoryItemData.file,
    `${visionURL}/repository/api/v1/direct_uploads`,
    token
  )
    .then(response => {
      const dataCopy = {
        ...repositoryItemData,
        file: undefined,
        fileId: response.fileId
      };
      delete dataCopy.file;
      return createItemRecord(dataCopy);
    })
    .catch(reason => dispatch(createItemsFailure(reason)));
};

export const createItemVersion = (
  itemId,
  repositoryItemData,
  successCallback,
  errorCallback
) => async dispatch => {
  const createItemVersionRecord = async data => {
    const service = await repositoryApiService("items");
    const json = await service
      .createRelated(
        itemId,
        "item_versions",
        visionCallService.serializeRequestBody(
          { ...data, type: "items" },
          "items"
        )
      )
      .then(response => response.json());
    if (json.errors) {
      dispatch(createItemsFailure(json));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback(json.errors);
      }
      json.errors.forEach(x =>
        dispatch(enqueueErrorSnackbar({ message: x.title }))
      );
    } else {
      dispatch(createItemsSuccess(normalize(json)));
      if (successCallback && typeof successCallback === "function") {
        successCallback(json);
      }
    }
    return json;
  };

  dispatch(createItemsBegin());
  if (!repositoryItemData.file) {
    return createItemVersionRecord(repositoryItemData);
  }

  const token = await getToken();
  uploadFile(
    repositoryItemData.file,
    `${visionURL}/repository/api/v1/direct_uploads`,
    token
  )
    .then(response => {
      const dataCopy = {
        ...repositoryItemData,
        file: undefined,
        fileId: response.fileId
      };
      delete dataCopy.file;
      return createItemVersionRecord(dataCopy);
    })
    .catch(reason => dispatch(createItemsFailure(reason)));
};

const repositoryApiService = async (resource = "libraries", options = {}) => {
  const baseUrl = `${visionURL}/repository/api/v1`;
  options = { ...options, ...{ baseUrl } };
  const service = new JsonApiService(resource, options);
  const token = await getToken();
  return service.setAuthToken(token);
};

export const updateItem = (repositoryItemId, repositoryItemData) => {
  return async dispatch => {
    const service = await repositoryApiService("items");
    dispatch(updateItemsBegin());
    const json = await service
      .update(
        repositoryItemId,
        visionCallService.serializeRequestBody(
          { ...repositoryItemData, id: repositoryItemId },
          "items"
        )
      )
      .then(async response => {
        const data = await response.json();
        if (response.status !== 200) {
          dispatch(updateItemsFailure(data.errors));
        }
        return deserialize(data);
      })
      .catch(error => dispatch(updateItemsFailure(error)));

    if (json) {
      dispatch(updateItemsSuccess(json));
    }

    return json;
  };
};

export const fetchRepositoriesTableData = queryParams => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch(fetchRepositories(null, queryParams))
      .then(data => {
        const repositoryData = deserialize(data).map(rd => {
          const customer = data.included.filter(
            rel => rel.id === rd.customer.id
          )[0];
          return {
            ...rd,
            totalItems: rd.meta.totalItems,
            customerName: customer.attributes.name
          };
        });

        resolve({ data: repositoryData });
        return repositoryData;
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const fetchRepositories = (repositoryId, queryParams) => {
  return async dispatch => {
    const service = await repositoryApiService();
    dispatch(fetchRepositoriesBegin());
    const json = await service
      .include("customer")
      .all()
      .then(response => {
        return response.json();
      });
    dispatch(fetchRepositoriesSuccess(normalize(json)));
    return json;
  };
};

export const fetchRepository = (id, options) => {
  return async dispatch => {
    const service = await repositoryApiService();
    dispatch(fetchRepositoryBegin());
    const json = await service.find(id).then(response => {
      return response.json();
    });
    dispatch(
      fetchRepositorySuccess({
        ...normalize(json),
        itemCount: json.data.meta.totalItems
      })
    );
    return json;
  };
};

export const fetchIntegrations = () => {
  return async dispatch => {
    const service = await repositoryApiService("integrations");
    dispatch(fetchIntegrationsBegin());
    const json = await service.all().then(response => response.json());
    dispatch(fetchIntegrationsSuccess(normalize(json)));
    return json;
  };
};

export const createIntegration = (data, successCallback, errorCallback) => {
  return async dispatch => {
    const service = await repositoryApiService("integrations");
    dispatch(createIntegrationsBegin());
    const json = await service
      .create({ ...data, type: "integrations" })
      .then(response => response.json());
    if (json.errors) {
      dispatch(createIntegrationsFailure(json));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback(json.errors);
      }
      json.errors.forEach(x =>
        dispatch(enqueueErrorSnackbar({ message: x.title }))
      );
    } else {
      dispatch(createIntegrationsSuccess(normalize(json)));
      if (successCallback && typeof successCallback === "function") {
        successCallback(json);
      }
    }
    return json;
  };
};

export const fetchRepositoryItems = (id, options, searchedValue = "") => {
  return async dispatch => {
    const service = await repositoryApiService("libraries", options);
    dispatch(fetchItemsBegin({ pageNumber: options.page.number }));
    const json = await service
      .include(["course_sections", "creator", "repository"])
      .customParams(searchedValue)
      .fields({ course_sections: ["title"], repository: ["accessType"] })
      .related(id, "items")
      .then(response => {
        return response.json();
      });
    dispatch(
      fetchItemsSuccess({
        ...normalize(json),
        itemList: dataFormatter.deserialize(json),
        ...options.page
      })
    );
    return json;
  };
};

export const fetchRelatedItem = (repositoryId, itemId, options) => {
  return async dispatch => {
    const service = await repositoryApiService();
    dispatch(fetchItemBegin());
    const json = await service
      .include(options.include)
      .relatedResource(repositoryId, "items", itemId)
      .then(response => response.json())
      .catch(error => dispatch(fetchItemsFailure(error)));
    dispatch(fetchItemSuccess(normalize(json)));
    return json;
  };
};

// GET /items/:itemId
// itemId is optional, if not provided, returns all items

export const fetchItems = (itemId, queryParams, filters) => dispatch => {
  let apiUrl = "https://api-mock.ghdev.us/v2/5e83538ddc00001ee1d68901"; // TODO: Replace with correct vision api url
  if (itemId) {
    apiUrl += `/${itemId}`;
  }
  apiUrl += visionCallService.buildQueryString(queryParams);
  apiUrl += visionCallService.buildFilters(filters);
  dispatch(fetchItemsBegin({}));
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchItemsSuccess(response));
    },
    function(error) {
      console.log("request failed", error);
      dispatch(fetchItemsFailure(error));
    }
  );
};

// GET /items/:itemId
export const fetchItem = itemId => async dispatch => {
  const service = await repositoryApiService("items");

  dispatch(fetchItemBegin());
  const json = await service
    .include("creator")
    .find(itemId)
    .then(response => response.json());

  if (json.errors) {
    dispatch(fetchItemsFailure(json));
    json.errors.forEach(x =>
      dispatch(enqueueErrorSnackbar({ message: x.title }))
    );
  } else {
    dispatch(fetchItemSuccess(normalize(json)));
  }
  return json;
};
