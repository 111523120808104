import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";
import normalize from "../utils/normalize";

export const FETCH_QUIZZES_BEGIN = "FETCH_QUIZZES_BEGIN";
export const FETCH_QUIZZES_SUCCESS = "FETCH_QUIZZES_SUCCESS";
export const FETCH_QUIZZES_FAILURE = "FETCH_QUIZZES_FAILURE";
export const CREATE_QUIZ_BEGIN = "CREATE_QUIZ_BEGIN";
export const CREATE_QUIZ_SUCCESS = "CREATE_QUIZ_SUCCESS";
export const CREATE_QUIZ_FAILURE = "CREATE_QUIZ_FAILURE";
export const UPDATE_QUIZ_BEGIN = "UPDATE_QUIZ_BEGIN";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAILURE = "UPDATE_QUIZ_FAILURE";
export const UPDATE_QUIZ_QUESTIONS_BEGIN = "UPDATE_QUIZ_QUESTIONS_BEGIN";
export const UPDATE_QUIZ_QUESTIONS_SUCCESS = "UPDATE_QUIZ_QUESTIONS_SUCCESS";
export const UPDATE_QUIZ_QUESTIONS_FAILURE = "UPDATE_QUIZ_QUESTIONS_FAILURE";
export const UPDATE_QUESTIONS_BEGIN = "UPDATE_QUESTIONS_BEGIN";
export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";
export const UPDATE_QUESTIONS_FAILURE = "UPDATE_QUESTIONS_FAILURE";
export const CREATE_QUESTIONS_BEGIN = "CREATE_QUESTIONS_BEGIN";
export const CREATE_QUESTIONS_SUCCESS = "CREATE_QUESTIONS_SUCCESS";
export const CREATE_QUESTIONS_FAILURE = "CREATE_QUESTIONS_FAILURE";
export const IMPORT_QUIZ_QUESTIONS_BEGIN = "IMPORT_QUIZ_QUESTIONS_BEGIN";
export const IMPORT_QUIZ_QUESTIONS_SUCCESS = "IMPORT_QUIZ_QUESTIONS_SUCCESS";
export const IMPORT_QUIZ_QUESTIONS_FAILURE = "IMPORT_QUIZ_QUESTIONS_FAILURE";
export const UPDATE_QUIZ_QUESTION_BEGIN = "UPDATE_QUIZ_QUESTION_BEGIN";
export const UPDATE_QUIZ_QUESTION_SUCCESS = "UPDATE_QUIZ_QUESTION_SUCCESS";
export const UPDATE_QUIZ_QUESTION_FAILURE = "UPDATE_QUIZ_QUESTION_FAILURE";

export const fetchQuizzesBegin = () => ({
  type: FETCH_QUIZZES_BEGIN
});

export const fetchQuizzesSuccess = quizzesData => ({
  type: FETCH_QUIZZES_SUCCESS,
  payload: quizzesData
});

export const fetchQuizzesFailure = error => ({
  type: FETCH_QUIZZES_FAILURE,
  payload: { error }
});

export const createQuizBegin = () => ({
  type: CREATE_QUIZ_BEGIN
});

export const createQuizzesSuccess = quizData => ({
  type: CREATE_QUIZ_SUCCESS,
  payload: quizData
});

export const createQuizFailure = error => ({
  type: CREATE_QUIZ_FAILURE,
  payload: { error }
});

export const updateQuizBegin = () => ({
  type: UPDATE_QUIZ_BEGIN
});

export const updateQuizzesSuccess = quizData => ({
  type: UPDATE_QUIZ_SUCCESS,
  payload: quizData
});

export const updateQuizFailure = error => ({
  type: UPDATE_QUIZ_FAILURE,
  payload: { error }
});

export const updateQuizQuestionsBegin = () => ({
  type: UPDATE_QUIZ_QUESTIONS_BEGIN
});

export const updateQuizQuestionsSuccess = quizQuestionsData => ({
  type: UPDATE_QUIZ_QUESTIONS_SUCCESS,
  payload: quizQuestionsData
});

export const updateQuizQuestionsFailure = error => ({
  type: UPDATE_QUIZ_QUESTIONS_FAILURE,
  payload: { error }
});

export const createQuestionsBegin = () => ({
  type: CREATE_QUESTIONS_BEGIN
});

export const createQuestionsSuccess = surveyQuestionsData => ({
  type: CREATE_QUESTIONS_SUCCESS,
  payload: surveyQuestionsData
});

export const createQuestionsFailure = error => ({
  type: CREATE_QUESTIONS_FAILURE,
  payload: { error }
});

export const updateQuestionsBegin = () => ({
  type: UPDATE_QUESTIONS_BEGIN
});

export const updateQuestionsSuccess = quizQuestionsData => ({
  type: UPDATE_QUESTIONS_SUCCESS,
  payload: quizQuestionsData
});

export const updateQuestionsFailure = error => ({
  type: UPDATE_QUESTIONS_FAILURE,
  payload: { error }
});

export const updateQuizQuestionBegin = () => ({
  type: UPDATE_QUIZ_QUESTION_BEGIN
});

export const updateQuizQuestionSuccess = surveyQuestionsData => ({
  type: UPDATE_QUIZ_QUESTION_SUCCESS,
  payload: surveyQuestionsData
});

export const updateQuizQuestionFailure = error => ({
  type: UPDATE_QUIZ_QUESTION_FAILURE,
  payload: { error }
});

export const fetchQuizzes = (quizId, queryParams) => dispatch => {
  const quizzesUrl = `${visionURL}/admin_console/api/quizzes/${quizId}?${visionCallService.buildQueryString(
    queryParams
  )}`;
  dispatch(fetchQuizzesBegin());
  return visionCallService.requestWithNormalizer(
    quizzesUrl,
    function(response) {
      dispatch(fetchQuizzesSuccess(response));
    },
    function(error) {
      dispatch(fetchQuizzesFailure(error));
    }
  );
};

export const createQuiz = newQuizData => dispatch => {
  const quizUrl = `${visionURL}/admin_console/api/quizzes`;

  dispatch(createQuizBegin());
  return visionCallService.requestWithNormalizer(
    quizUrl,
    function(response) {
      return dispatch(createQuizzesSuccess(response));
    },
    function(error) {
      dispatch(createQuizFailure(error));
    },
    "POST",
    { ...newQuizData, type: "courseQuiz" }
  );
};

export const updateQuiz = (
  quizId,
  quizData,
  updateCurrentQuizAttributes = false
) => dispatch => {
  const quizUrl = `${visionURL}/admin_console/api/quizzes/${quizId}?updateCurrentQuizAttributes=${updateCurrentQuizAttributes}`;

  dispatch(updateQuizBegin());
  return visionCallService.requestWithNormalizer(
    quizUrl,
    function(response) {
      return dispatch(updateQuizzesSuccess(response));
    },
    function(error) {
      dispatch(updateQuizFailure(error));
    },
    "PATCH",
    { ...quizData, type: "courseQuiz" }
  );
};

// Update the related questions for a quiz
export const updateQuizQuestions = (quizId, questionsData) => dispatch => {
  const quizUrl = `${visionURL}/admin_console/api/quizzes/${quizId}/relationships/questions`;
  dispatch(updateQuizQuestionsBegin());
  let payload = {
    include: ["questions"],
    data: {
      type: "quizzes",
      id: quizId,
      relationships: {
        questions: {
          data: questionsData
        }
      }
    }
  };

  return visionCallService.postJsonDataRequest(
    quizUrl,
    payload,
    function(response) {
      dispatch(updateQuizQuestionsSuccess({ ...response, id: quizId }));
    },
    function(error) {
      dispatch(updateQuizQuestionsFailure(error));
    }
  );
};

// create quiz questions
export const createQuestions = newQuestionsData => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/questions`;

  dispatch(createQuestionsBegin());
  return visionCallService.requestWithNormalizer(
    questionsUrl,
    function(response) {
      dispatch(createQuestionsSuccess(response));
    },
    function(error) {
      dispatch(createQuestionsFailure(error));
    },
    "POST",
    newQuestionsData,
    ["answers", "matchClues", "matchChoices", "fillInTheBlanks"]
  );
};

// update quiz question
export const updateQuizQuestion = (
  newQuestionsData,
  questionId
) => dispatch => {
  console.log("updateQuizQuestion", newQuestionsData);
  const questionsUrl = `${visionURL}/admin_console/api/content/questions/${questionId}`;

  dispatch(updateQuizQuestionBegin());
  return visionCallService.requestWithNormalizer(
    questionsUrl,
    function(response) {
      dispatch(updateQuizQuestionSuccess(response));
    },
    function(error) {
      dispatch(updateQuizQuestionFailure(error));
    },
    "PUT",
    newQuestionsData,
    ["answers", "matchClues", "matchChoices", "fillInTheBlanks"]
  );
};

// Update the attributes for quiz questions
export const updateQuestions = newQuestionsData => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/questions/bulk_update`;

  dispatch(updateQuestionsBegin());
  return visionCallService.requestWithNormalizer(
    questionsUrl,
    function(response) {
      dispatch(updateQuestionsSuccess(response));
    },
    function(error) {
      dispatch(updateQuestionsFailure(error));
    },
    "PATCH",
    newQuestionsData,
    ["answers"]
  );
};

export const importQuestionsBegin = () => ({
  type: IMPORT_QUIZ_QUESTIONS_BEGIN
});

export const importQuestionsSuccess = payload => ({
  type: IMPORT_QUIZ_QUESTIONS_SUCCESS,
  payload
});

export const importQuestionsFailure = payload => ({
  type: IMPORT_QUIZ_QUESTIONS_FAILURE,
  payload
});

export const importQuizQuestions = (uploadedFile, quizId) => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/quizzes/${quizId}/quiz_imports`;
  const data = { file: uploadedFile, quizType: "quiz" };
  const formData = visionCallService.normalizeFormData(data);

  dispatch(importQuestionsBegin());
  return fetch(questionsUrl, {
    method: "POST",
    credentials: "include",
    body: formData
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      if (!response.ok) {
        dispatch(importQuestionsFailure(response));
      }
      return response.json();
    })
    .then(function(response) {
      if (response.errors) {
        dispatch(importQuestionsFailure(response.errors));
      } else {
        dispatch(
          importQuestionsSuccess({
            quizId: `${quizId}`,
            ...normalize(response)
          })
        );
      }
      return response;
    });
};
