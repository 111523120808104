import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { visionURL } from "../config/environment";
import { isIframed } from "../utils/iframe";

let loaderScript;

const apiUrl = `${visionURL}/flipflop_features`;

const useFlipFlopFeatures = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(apiUrl, {
      method: "GET",
      credentials: "include"
    })
      .then(response => {
        const unauthorized = response.status === 401;

        if (isIframed && unauthorized) {
          window.parent.postMessage("sessionTimeout", "*");
        }
        return response.json();
      })
      .then(setData)
      .catch(error => {
        console.error("Fetch error:", error);
      });
  }, []);

  return data;
};

export const usePendoEnabled = () => {
  const { data } = useFlipFlopFeatures();
  return useMemo(
    () =>
      data?.find(({ attributes }) => attributes.key === "pendo_integration")
        ?.attributes.enabled,
    [data]
  );
};

const createAccount = customer => {
  const {
    id: customerId,
    name: customerName,
    packageName: customerTier,
    forceId
  } = customer;
  return {
    id: customerId,
    name: customerName,
    customer_tier: customerTier,
    salesforce_id: forceId
  };
};

const createVisitor = (user, userProfile) => {
  const { id: userId, firstName, lastName, included } = user;

  const roles = included
    ?.filter(
      ({ type, attributes }) => type === "role" && attributes.assignableInPhq
    )
    .map(({ attributes }) => attributes.name);

  return {
    id: userId,
    full_name: `${firstName} ${lastName}`,
    user_firstName: firstName,
    user_lastName: lastName,
    position: userProfile.position,
    roles
  };
};

export const usePendo = () => {
  const [needsInit, setNeedsInit] = useState(false);
  const pendo = window.pendo;
  const initialized = pendo?.isReady?.();

  const enabled = usePendoEnabled();

  useEffect(() => {
    if (!enabled || loaderScript?.isConnected) {
      return;
    }
    loaderScript = document.createElement("script");
    loaderScript.innerHTML = `(function(apiKey){ (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[]; v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){ o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]); y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js'; z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');})('0490c1dd-ebea-4fd9-7a90-eb60535af04a'); `;
    document.body.appendChild(loaderScript);
    setNeedsInit(true);
    return () => loaderScript.remove();
  }, [enabled]);

  const { user, loading: isUserLoading } = useSelector(
    ({ userState }) => userState
  );
  const { customer, loading: isCustomerLoading } = useSelector(
    ({ customerState }) => customerState
  );

  const { userProfile, loading: isProfileLoading } = useSelector(
    ({ userProfileState }) => userProfileState
  );

  useEffect(() => {
    if (
      !initialized &&
      needsInit &&
      user &&
      customer &&
      userProfile &&
      !isUserLoading &&
      !isCustomerLoading &&
      !isProfileLoading
    ) {
      const instance = {
        visitor: createVisitor(user, userProfile),
        account: createAccount(customer)
      };
      pendo.initialize(instance);

      setNeedsInit(false);
    }
  }, [
    initialized,
    customer,
    user,
    isUserLoading,
    isCustomerLoading,
    isProfileLoading,
    userProfile,
    pendo
  ]);

  useEffect(() => {
    if (
      initialized &&
      customer &&
      user &&
      userProfile &&
      !isUserLoading &&
      !isCustomerLoading &&
      !isProfileLoading
    ) {
      const instance = {
        visitor: createVisitor(user, userProfile),
        account: createAccount(customer)
      };
      pendo.updateOptions(instance);
    }
  }, [
    initialized,
    customer,
    user,
    isUserLoading,
    isCustomerLoading,
    isProfileLoading,
    userProfile,
    pendo
  ]);
};
