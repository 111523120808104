import visionCallService from "../../../services/visionCallService";
import { visionURL } from "../../../config/environment";
import { enqueueErrorSnackbar } from "../snackbarActions";

export const UPDATE_QUIZ_ANSWER_BEGIN = "UPDATE_QUIZ_ANSWER_BEGIN";
export const UPDATE_QUIZ_ANSWER_SUCCESS = "UPDATE_QUIZ_ANSWER_SUCCESS";
export const UPDATE_QUIZ_ANSWER_FAILURE = "UPDATE_QUIZ_ANSWER_FAILURE";

export const updateQuizAnswerBegin = () => ({
  type: UPDATE_QUIZ_ANSWER_BEGIN
});

export const updateQuizAnswerSuccess = (answerChoices, questionId) => ({
  type: UPDATE_QUIZ_ANSWER_SUCCESS,
  payload: { answerChoices, questionId }
});

export const updateQuizAnswerFailure = error => ({
  type: UPDATE_QUIZ_ANSWER_FAILURE,
  payload: { error }
});

export function updateQuizAnswer(id, data, needsDelete, serialized) {
  const requestData = data;
  if (needsDelete) {
    requestData["delete"] = true;
  }
  const body = serialized
    ? requestData
    : {
        data: { attributes: requestData }
      };
  return dispatch => {
    dispatch(updateQuizAnswerBegin());
    putQuizAnswer(
      id,
      body,
      data => {
        if (
          data.errors &&
          data.errors.length > 0 &&
          data.errors[0].title === "alreadySubmitted"
        ) {
          dispatch(enqueueErrorSnackbar({ message: data.errors[0].detail }));
          return dispatch(updateQuizAnswerFailure(data.errors));
        }
        return dispatch(updateQuizAnswerSuccess(data, id));
      },
      error => dispatch(updateQuizAnswerFailure(error))
    );
  };
}

function putQuizAnswer(id, data, successCallback, errorCallback) {
  const apiUrl = `${visionURL}/learning_center/api/quiz_answers/${id}`;
  return visionCallService.postData(
    data,
    apiUrl,
    successCallback,
    errorCallback,
    "PUT"
  );
}
