import { visionURL } from "../../config/environment";
import visionCallService from "../../services/visionCallService";
import { fetch } from "whatwg-fetch";
import { groupBy } from "underscore";
import normalizeJsonapiErrors from "../../utils/normalize_jsonapi_errors.js";

export const FETCH_CALENDAR_EVENTS_BEGIN = "FETCH_CALENDAR_EVENTS_BEGIN";
export const FETCH_CALENDAR_EVENTS_SUCCESS = "FETCH_CALENDAR_EVENTS_SUCCESS";
export const FETCH_CALENDAR_EVENTS_FAILURE = "FETCH_CALENDAR_EVENTS_FAILURE";
export const FETCH_CALENDAR_FEED_LINK_BEGIN = "FETCH_CALENDAR_FEED_LINK_BEGIN";
export const FETCH_CALENDAR_FEED_LINK_SUCCESS =
  "FETCH_CALENDAR_FEED_LINK_SUCCESS";
export const FETCH_CALENDAR_FEED_LINK_FAILURE =
  "FETCH_CALENDAR_FEED_LINK_FAILURE";
export const UPDATE_CALENDAR_EVENTS_BEGIN = "UPDATE_CALENDAR_EVENTS_BEGIN";
export const UPDATE_CALENDAR_EVENTS_SUCCESS = "UPDATE_CALENDAR_EVENTS_SUCCESS";
export const UPDATE_CALENDAR_EVENTS_FAILURE = "UPDATE_CALENDAR_EVENTS_FAILURE";
export const UPDATE_CALENDAR_HIDDEN_CALENDARS =
  "UPDATE_CALENDAR_HIDDEN_CALENDARS";
export const CREATE_NEW_CALENDAR_BEGIN = "CREATE_NEW_CALENDAR_BEGIN";
export const CREATE_NEW_CALENDAR_SUCCESS = "CREATE_NEW_CALENDAR_SUCCESS";
export const CREATE_NEW_CALENDAR_FAILURE = "CREATE_NEW_CALENDAR_FAILURE";
export const CREATE_NEW_EVENT_BEGIN = "CREATE_NEW_EVENT_BEGIN";
export const CREATE_NEW_EVENT_SUCCESS = "CREATE_NEW_EVENT_SUCCESS";
export const CREATE_NEW_EVENT_FAILURE = "CREATE_NEW_EVENT_FAILURE";
export const UPDATE_EVENT_BEGIN = "UPDATE_EVENT_BEGIN";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";
export const DELETE_EVENT_BEGIN = "DELETE_EVENT_BEGIN";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";
export const IMPORT_NEW_CALENDAR_BEGIN = "IMPORT_NEW_CALENDAR_BEGIN";
export const IMPORT_NEW_CALENDAR_SUCCESS = "IMPORT_NEW_CALENDAR_SUCCESS";
export const IMPORT_NEW_CALENDAR_FAILURE = "IMPORT_NEW_CALENDAR_FAILURE";
export const UPDATE_CALENDAR_SETTINGS_BEGIN = "UPDATE_CALENDAR_SETTINGS_BEGIN";
export const UPDATE_CALENDAR_SETTINGS_SUCCESS =
  "UPDATE_CALENDAR_SETTINGS_SUCCESS";
export const UPDATE_CALENDAR_SETTINGS_FAILURE =
  "UPDATE_CALENDAR_SETTINGS_FAILURE";

export const fetchCalendarDataBegin = () => ({
  type: FETCH_CALENDAR_EVENTS_BEGIN
});

export const fetchCalendarDataSuccess = calendarData => ({
  type: FETCH_CALENDAR_EVENTS_SUCCESS,
  payload: { calendarData }
});

export const fetchCalendarDataFailure = error => ({
  type: FETCH_CALENDAR_EVENTS_FAILURE,
  payload: { error }
});

export const fetchCalendarFeedLinkBegin = () => ({
  type: FETCH_CALENDAR_FEED_LINK_BEGIN
});

export const fetchCalendarFeedLinkSuccess = feedLink => ({
  type: FETCH_CALENDAR_FEED_LINK_SUCCESS,
  payload: { feedLink }
});

export const fetchCalendarFeedLinkFailure = error => ({
  type: FETCH_CALENDAR_FEED_LINK_FAILURE,
  payload: { error }
});

export const updateCalendarDataBegin = () => ({
  type: UPDATE_CALENDAR_EVENTS_BEGIN
});

export const updateSuccess = (key, value) => ({
  type: UPDATE_CALENDAR_EVENTS_SUCCESS,
  payload: { key: key, value: value }
});

export const updateCalendarDataFailure = error => ({
  type: UPDATE_CALENDAR_EVENTS_FAILURE,
  payload: { error }
});

export function fetchCalendarInfo() {
  return dispatch => {
    dispatch(fetchCalendarDataBegin());
    return fetchCalendarData(
      function(calendarData) {
        dispatch(fetchCalendarDataSuccess(calendarData));
      },
      function(error) {
        dispatch(fetchCalendarDataFailure(error));
      }
    );
  };
}

export const updateCalendarHiddenCalendars = eventNames => ({
  type: UPDATE_CALENDAR_HIDDEN_CALENDARS,
  payload: { eventNames }
});

export const createNewCalendarBegin = () => ({
  type: CREATE_NEW_EVENT_BEGIN
});

export const createNewCalendarFailure = error => ({
  type: CREATE_NEW_CALENDAR_FAILURE,
  payload: { error }
});

export const createNewCalendarSuccess = (key, value) => ({
  type: CREATE_NEW_CALENDAR_SUCCESS,
  payload: { key, value }
});

export const updateCalendarSettingsBegin = () => ({
  type: UPDATE_CALENDAR_SETTINGS_BEGIN
});

export const updateCalendarSettingsFailure = error => ({
  type: UPDATE_CALENDAR_SETTINGS_FAILURE,
  payload: { error }
});

export const updateCalendarSettingsSuccess = (key, value) => ({
  type: UPDATE_CALENDAR_SETTINGS_SUCCESS,
  payload: { key, value }
});

export const createNewEventBegin = () => ({
  type: CREATE_NEW_EVENT_BEGIN
});

export const createNewEventFailure = error => ({
  type: CREATE_NEW_EVENT_FAILURE,
  payload: { error }
});

export const createNewEventSuccess = (key, value) => ({
  type: CREATE_NEW_EVENT_SUCCESS,
  payload: { key, value }
});

export const updateEventBegin = () => ({
  type: UPDATE_EVENT_BEGIN
});

export const updateEventFailure = error => ({
  type: UPDATE_EVENT_FAILURE,
  payload: { error }
});

export const updateEventSuccess = (key, value) => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: { key, value }
});

export const deleteEventBegin = () => ({
  type: DELETE_EVENT_BEGIN
});

export const deleteEventFailure = error => ({
  type: DELETE_EVENT_FAILURE,
  payload: { error }
});

export const deleteEventSuccess = (key, value) => ({
  type: DELETE_EVENT_SUCCESS,
  payload: { key, value }
});

export const importNewCalendarBegin = () => ({
  type: IMPORT_NEW_CALENDAR_BEGIN
});

export const importNewCalendarFailure = error => ({
  type: IMPORT_NEW_CALENDAR_FAILURE,
  payload: { error }
});

export const importNewCalendarSuccess = (key, value) => ({
  type: IMPORT_NEW_CALENDAR_SUCCESS,
  payload: { key, value }
});

function fetchCalendarData(successCallback, errorCallback) {
  fetch(`${visionURL}/learning_center/api/calendars`, {
    credentials: "include"
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      const extracted = visionCallService.extractAttributes(response);
      const grouped = groupBy(extracted, item => item.calendarType);
      return successCallback({
        calendarLists: grouped
      });
    })
    .catch(function(error) {
      console.log("request failed", error);
      errorCallback(error);
    });
}

function postData(
  data,
  apiUrl,
  successCallback,
  errorCallback,
  method = "POST"
) {
  return fetch(apiUrl, {
    method,
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ event: data })
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      if (response.status === 204) return response;
      return response.json();
    })
    .then(function(response) {
      if (response.hasOwnProperty("data") || response.status === 204) {
        return successCallback();
      }

      return errorCallback(response);
    })
    .catch(function(error) {
      console.log("request failed", error);
      errorCallback(error);
    });
}

export function updateCalendarSettings(calendarSettingsData) {
  return dispatch => {
    dispatch(updateCalendarSettingsBegin());
    return postNewCalendarSettingsData(
      {
        calendarSettingsData
      },
      error => dispatch(updateCalendarSettingsFailure(error))
    ).then(
      dispatch(
        updateCalendarSettingsSuccess(
          "calendarSettingsData",
          calendarSettingsData
        )
      )
    );
  };
}

function postNewCalendarSettingsData(calendarSettingsData, errorCallback) {
  const apiUrl = `${visionURL}/learning_center/api/calendar_settings`; //TODO: replace with correct url
  return postData(
    calendarSettingsData.calendarSettingsData,
    apiUrl,
    () => {},
    errorCallback
  );
}

export function createNewCalendar(calendarData) {
  return dispatch => {
    dispatch(createNewCalendarBegin());
    return postNewCalendar(
      {
        calendarData
      },
      error => dispatch(createNewCalendarFailure(error))
    ).then(dispatch(createNewCalendarSuccess("calendarData", calendarData)));
  };
}

function postNewCalendar(calendarData, errorCallback) {
  const apiUrl = `${visionURL}/learning_center/api/create_new_calendar`; //TODO: replace with correct url
  return postData(calendarData.calendarData, apiUrl, () => {}, errorCallback);
}

export function createNewEvent(eventData) {
  return dispatch => {
    dispatch(createNewEventBegin());
    return postNewEvent(
      {
        eventData
      },
      error => {
        dispatch(createNewEventFailure(normalizeJsonapiErrors(error)));
      },
      dispatch
    ).then(dispatch(createNewEventSuccess("eventData", eventData)));
  };
}

export function updateEvent(eventId, eventData) {
  return dispatch => {
    dispatch(updateEventBegin());
    return updateEventCall(
      eventId,
      {
        eventData
      },
      error => {
        dispatch(updateEventFailure(normalizeJsonapiErrors(error)));
      },
      dispatch
    ).then(dispatch(updateEventSuccess("eventData", eventData)));
  };
}

export function deleteEvent(eventData, successCallback) {
  return dispatch => {
    dispatch(deleteEventBegin());
    return deleteEventCall(
      {
        id: eventData.id
      },
      successCallback,
      error => {
        dispatch(deleteEventFailure(normalizeJsonapiErrors(error)));
      },
      dispatch
    ).then(dispatch(deleteEventSuccess("eventData", eventData)));
  };
}

function deleteEventCall(event, successCallback, errorCallback, dispatch) {
  const apiUrl = `${visionURL}/learning_center/api/events/${event.id}`;

  const callback = () => {
    dispatch(fetchCalendarInfo());
    successCallback();
  };
  return postData(event, apiUrl, callback, errorCallback, "DELETE");
}

function postNewEvent(eventData, errorCallback, dispatch) {
  const apiUrl = `${visionURL}/learning_center/api/events`;
  const callback = () => {
    dispatch(fetchCalendarInfo());
  };
  return postData(eventData.eventData, apiUrl, callback, errorCallback);
}

function updateEventCall(eventId, eventData, errorCallback, dispatch) {
  const apiUrl = `${visionURL}/learning_center/api/events/${eventId}`;
  const callback = () => {
    dispatch(fetchCalendarInfo());
  };
  return postData(eventData.eventData, apiUrl, callback, errorCallback, "PUT");
}

export function importNewCalendar(newCalendarData) {
  return dispatch => {
    dispatch(importNewCalendarBegin());
    return postImportedCalendarData(
      {
        newCalendarData
      },
      error => dispatch(createNewEventFailure(error))
    ).then(dispatch(createNewEventSuccess("newCalendarData", newCalendarData)));
  };
}

function postImportedCalendarData(newCalendarData, errorCallback) {
  const apiUrl = `${visionURL}/learning_center/api/import_new_calendar`; //TODO: replace with correct url
  return postData(
    newCalendarData.calendarData,
    apiUrl,
    () => {},
    errorCallback
  );
}

export function fetchCalendarFeedLink() {
  return dispatch => {
    dispatch(fetchCalendarFeedLinkBegin());
    return fetchFeedLink(
      function(feedLink) {
        dispatch(fetchCalendarFeedLinkSuccess(feedLink));
      },
      function(error) {
        dispatch(fetchCalendarFeedLinkFailure(error));
      }
    );
  };
}

function fetchFeedLink(successCallback, errorCallback) {
  return fetch(`${visionURL}/learning_center/api/calendars/feed`, {
    credentials: "include"
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      const data = visionCallService.extractAttributes(response);
      successCallback(data.feedLink);
      return data.feedLink;
    })
    .catch(function(error) {
      console.log("request failed", error);
      errorCallback(error);
    });
}
