import { updateIn, removeIn, fromJS } from "immutable";
import { unionBy, union, merge, map, pick } from "lodash";

export const addRelatedResources = (
  entityState,
  entityId,
  relationshipKey,
  relatedResources
) => {
  if (relatedResources) {
    const newRelationships = Object.values(relatedResources).map(
      ({ id, type }) => ({
        id,
        type
      })
    );
    let newState = fromJS(entityState);
    newState = updateIn(
      newState,
      [entityId, "relationships", relationshipKey, "data"],
      relationships => {
        return relationships
          ? union(relationships, newRelationships)
          : newRelationships;
      }
    ).toJS();
    return newState;
  } else {
    return entityState;
  }
};

/**
 * Remove a single Entity
 *
 * @param  {Object} initialState
 * @param  {String} entityKey
 * @param  {String} entityId
 * @return {Object}
 */
export const removeEntity = (initialState, entityKey, entityId) => {
  let newState = fromJS(initialState);
  return removeIn(newState, [entityKey, entityId]).toJS();
};

/**
 * Remove a relationship an Entity
 *
 * @param  {Object} initialState
 * @param  {String} entityKey
 * @param  {String} entityId
 * @param  {String} relationshipKey
 * @param  {String} relationshipId
 * @return {Object}
 */
export const removeRelationshipFromEntity = (
  initialState,
  entityKey,
  entityId,
  relationshipKey,
  relationshipId
) => {
  let newState = fromJS(initialState);
  return newState
    .updateIn(
      [entityKey, entityId, "relationships", relationshipKey, "data"],
      arr => arr.filter(elem => elem.get("id") !== relationshipId)
    )
    .toJS();
};

/**
 * Update a relationship for an entity for one to one relationships
 *
 * @param  {Object} entityState
 * @param  {String} entityId
 * @param  {String} relationshipKey
 * @param  {Object} relatedResource
 * @return {Object}
 */
export const updateRelatedResource = (
  entityState,
  entityId,
  relationshipKey,
  relatedResource
) => {
  if (relatedResource) {
    const { id, type } = Object.values(relatedResource)[0];
    let newState = fromJS(entityState);
    newState = updateIn(
      newState,
      [entityId, "relationships", relationshipKey, "data"],
      () => ({
        id,
        type
      })
    ).toJS();
    return newState;
  } else {
    return entityState;
  }
};

export const replaceRelatedResources = (
  entityState,
  entityId,
  relationshipKey,
  relatedResources
) => {
  const newRelationships = relatedResources
    ? Object.values(relatedResources).map(({ id, type }) => ({
        id,
        type
      }))
    : [];
  let newState = fromJS(entityState);
  newState = updateIn(
    newState,
    [entityId, "relationships", relationshipKey, "data"],
    () => newRelationships
  ).toJS();
  return newState;
};

/**
 * TODO: This method achieves what addRelatedResources intends to do. Currently, the addRelatedResources method is replacing the relationships
 * rather than merging the new ones with the existing relationships. Eventually we should do some refactoring to use this method in our reducers rather
 * than addRelatedResources
 *
 * @param {Object} entityState initial state for entity
 * @param {*} entityId The ID to add the relationships for
 * @param {*} relationshipKey The relationship key that the related resources will be added to
 * @param {*} relatedResources The related resources that are related to the entity with the provided entity id
 */
export const mergeRelatedResources = (
  entityState,
  entityId,
  relationshipKey,
  relatedResources
) => {
  if (relatedResources) {
    const newRelatedResources = map(relatedResources, relatedResource =>
      pick(relatedResource, ["id", "type"])
    );
    const oldRelatedResources =
      entityState &&
      entityState[entityId] &&
      entityState[entityId].relationships &&
      entityState[entityId].relationships[relationshipKey]
        ? [...entityState[entityId].relationships[relationshipKey].data]
        : [];

    const combinedResources = unionBy(
      oldRelatedResources,
      newRelatedResources,
      "id"
    );

    let newRelationshipState = {
      [entityId]: {
        relationships: { [relationshipKey]: { data: combinedResources } }
      }
    };
    console.log(merge({}, entityState, newRelationshipState));
    return merge({}, entityState, newRelationshipState);
  } else {
    return entityState;
  }
};
