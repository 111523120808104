import visionCallService from "../../../../services/visionCallService";
import { visionURL } from "../../../../config/environment";
import normalize from "../../../utils/normalize";

export const FETCH_ORDERS_BEGIN = "FETCH_ORDERS_BEGIN";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";
export const FETCH_VALIDATE_LOCATIONS_BEGIN = "FETCH_VALIDATE_LOCATIONS_BEGIN";
export const FETCH_VALIDATE_LOCATIONS_SUCCESS =
  "FETCH_VALIDATE_LOCATIONS_SUCCESS";
export const FETCH_VALIDATE_LOCATIONS_FAILURE =
  "FETCH_VALIDATE_LOCATIONS_FAILURE";
export const FETCH_SHOP_TYPES_BEGIN = "FETCH_SHOP_TYPES_BEGIN";
export const FETCH_SHOP_TYPES_SUCCESS = "FETCH_SHOP_TYPES_SUCCESS";
export const FETCH_SHOP_TYPES_FAILURE = "FETCH_SHOP_TYPES_FAILURE";
export const FETCH_ORDERS_LOCATIONS_BEGIN = "FETCH_ORDERS_LOCATIONS_BEGIN";
export const FETCH_ORDERS_LOCATIONS_SUCCESS = "FETCH_ORDERS_LOCATIONS_SUCCESS";
export const FETCH_ORDERS_LOCATIONS_FAILURE = "FETCH_ORDERS_LOCATIONS_FAILURE";
export const FETCH_EMPLOYEES_BY_LOCATION_BEGIN =
  "FETCH_EMPLOYEES_BY_LOCATION_BEGIN";
export const FETCH_EMPLOYEES_BY_LOCATION_SUCCESS =
  "FETCH_EMPLOYEES_BY_LOCATION_SUCCESS";
export const FETCH_EMPLOYEES_BY_LOCATION_FAILURE =
  "FETCH_EMPLOYEES_BY_LOCATION_FAILURE";
export const ADD_ORDER_A_LA_CART_SHOP_BEGIN = "ADD_ORDER_A_LA_CART_SHOP_BEGIN";
export const ADD_ORDER_A_LA_CART_SHOP_SUCCESS =
  "ADD_ORDER_A_LA_CART_SHOP_SUCCESS";
export const ADD_ORDER_A_LA_CART_SHOP_FAILURE =
  "ADD_ORDER_A_LA_CART_SHOP_FAILURE";

export const addOrderALaCartShopBegin = () => ({
  type: ADD_ORDER_A_LA_CART_SHOP_BEGIN
});

export const addOrderALaCartShopSuccess = payload => ({
  type: ADD_ORDER_A_LA_CART_SHOP_SUCCESS,
  payload
});

export const addOrderALaCartShopFailure = error => ({
  type: ADD_ORDER_A_LA_CART_SHOP_FAILURE,
  payload: { error }
});

export const fetchEmployeesByLocationBegin = () => ({
  type: FETCH_EMPLOYEES_BY_LOCATION_BEGIN
});

export const fetchEmployeesByLocationSuccess = payload => ({
  type: FETCH_EMPLOYEES_BY_LOCATION_SUCCESS,
  payload
});

export const fetchEmployeesByLocationFailure = error => ({
  type: FETCH_EMPLOYEES_BY_LOCATION_FAILURE,
  payload: { error }
});

export const fetchOrdersLocationsBegin = () => ({
  type: FETCH_ORDERS_LOCATIONS_BEGIN
});

export const fetchOrdersLocationsSuccess = payload => ({
  type: FETCH_ORDERS_LOCATIONS_SUCCESS,
  payload
});

export const fetchOrdersLocationsFailure = error => ({
  type: FETCH_ORDERS_LOCATIONS_FAILURE,
  payload: { error }
});

export const fetchOrdersBegin = () => ({
  type: FETCH_ORDERS_BEGIN
});

export const fetchOrdersSuccess = payload => ({
  type: FETCH_ORDERS_SUCCESS,
  payload
});

export const fetchOrdersFailure = error => ({
  type: FETCH_ORDERS_FAILURE,
  payload: { error }
});

export const fetchValidateLocationsBegin = () => ({
  type: FETCH_VALIDATE_LOCATIONS_BEGIN
});

export const fetchValidateLocationsSuccess = payload => ({
  type: FETCH_VALIDATE_LOCATIONS_SUCCESS,
  payload
});

export const fetchValidateLocationsFailure = error => ({
  type: FETCH_VALIDATE_LOCATIONS_FAILURE,
  payload: { error }
});

export const fetchShopTypesBegin = () => ({
  type: FETCH_SHOP_TYPES_BEGIN
});

export const fetchShopTypesSuccess = payload => ({
  type: FETCH_SHOP_TYPES_SUCCESS,
  payload
});

export const fetchShopTypesFailure = error => ({
  type: FETCH_SHOP_TYPES_FAILURE,
  payload: { error }
});

export const fetchOrders = (
  orderId,
  ordersFilters,
  tableQueryData
) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/validate/orders`;

  apiUrl += `?${visionCallService.buildQueryString(ordersFilters)}`;
  apiUrl += visionCallService.buildTableDataQueryString(tableQueryData);
  dispatch(fetchOrdersBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchOrdersSuccess(response));
    },
    function(error) {
      dispatch(fetchOrdersFailure(error));
    }
  );
};

export const fetchValidateLocations = () => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/validate/locations`;
  dispatch(fetchValidateLocationsBegin());
  return visionCallService.fetchRequest(
    apiUrl,
    function(response) {
      const validateLocationsFilterData = visionCallService.extractAttributes(
        response
      );
      dispatch(fetchValidateLocationsSuccess(validateLocationsFilterData));
    },
    function(error) {
      dispatch(fetchValidateLocationsFailure(error));
    }
  );
};

export const fetchShopTypes = filters => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/validate/shop_types`;
  apiUrl += `?${visionCallService.buildQueryString(filters)}`;
  dispatch(fetchShopTypesBegin());
  return visionCallService.fetchRequest(
    apiUrl,
    function(response) {
      const shopTypesFilterData = visionCallService.extractAttributes(response);
      dispatch(fetchShopTypesSuccess(shopTypesFilterData));
    },
    function(error) {
      dispatch(fetchShopTypesFailure(error));
    }
  );
};

export const fetchOrderFilterLocations = () => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/validate/order_shop_locations`;
  dispatch(fetchOrdersLocationsBegin());
  return visionCallService.fetchRequest(
    apiUrl,
    function(response) {
      const validateLocationsFilterData = visionCallService.extractAttributes(
        response
      );
      dispatch(fetchOrdersLocationsSuccess(validateLocationsFilterData));
    },
    function(error) {
      dispatch(fetchOrdersLocationsFailure(error));
    }
  );
};

export const fetchEmployeesByLocation = locationId => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/administration/employees/users?filter[location]=${locationId}`;
  dispatch(fetchEmployeesByLocationBegin());
  return visionCallService.fetchRequest(
    apiUrl,
    function(response) {
      dispatch(
        fetchEmployeesByLocationSuccess(
          visionCallService.extractAttributes(response)
        )
      );
    },
    function(error) {
      dispatch(fetchEmployeesByLocationFailure(error));
    }
  );
};

export const addOrderALaCartShop = newOrderData => dispatch => {
  dispatch(addOrderALaCartShopBegin());
  console.log(newOrderData);
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/validate/orders`,
    response => {
      console.log(response);
      dispatch(addOrderALaCartShopSuccess(response));
      return normalize(response);
    },
    error => {
      dispatch(addOrderALaCartShopFailure(error));
    },
    "POST",
    { ...newOrderData, type: "validateOrder" }
  );
};
