import { visionURL } from "../../config/environment";
import visionCallService from "../../services/visionCallService";
import { defaultBannerImages } from "../../constants";
import { deletedBannerImages } from "../../constants";

export const FETCH_SETTINGS_BEGIN = "FETCH_SETTINGS_BEGIN";
export const FETCH_BANNERS_BEGIN = "FETCH_SETTINGS_BEGIN";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const FETCH_BANNERS_FAILURE = "FETCH_BANNERS_FAILURE";
export const UPDATE_SETTINGS_BEGIN = "UPDATE_SETTINGS_BEGIN";
export const UPDATE_BANNERS_BEGIN = "UPDATE_BANNERS_BEGIN";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNERS_SUCCESS";
export const UPDATE_BANNER_FAILURE = "UPDATE_BANNERS_FAILURE";

export const fetchSettingsBegin = () => ({
  type: FETCH_SETTINGS_BEGIN
});

export const fetchBannersBegin = () => ({
  type: FETCH_BANNERS_BEGIN
});

export const fetchSettingsSuccess = settings => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: { settings }
});

export const fetchBannersSuccess = bannersResponse => {
  const banners = zipBannerImagesWithDefaultBanners(bannersResponse);
  return { type: FETCH_BANNERS_SUCCESS, payload: { banners } };
};

export const fetchSettingsFailure = error => ({
  type: FETCH_SETTINGS_FAILURE,
  payload: { error }
});

export const fetchBannersFailure = error => ({
  type: FETCH_BANNERS_FAILURE,
  payload: { error }
});

export const updateSettingsBegin = () => ({
  type: UPDATE_SETTINGS_BEGIN
});

export const updateBannersBegin = () => ({
  type: UPDATE_BANNERS_BEGIN
});

export const updateSuccess = (key, value) => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: { key: key, value: value }
});

export const updateSettingsFailure = error => ({
  type: UPDATE_SETTINGS_FAILURE,
  payload: { error }
});

export const updateBannerSuccess = bannersResponse => {
  const banners = zipBannerImagesWithDefaultBanners(bannersResponse);
  return { type: UPDATE_BANNER_SUCCESS, payload: { banners } };
};

export const updateBannerFailure = error => ({
  type: UPDATE_BANNER_FAILURE,
  payload: { error }
});

export function fetchSettings(params) {
  return dispatch => {
    dispatch(fetchSettingsBegin());
    return fetchSettingsData(
      function(settings) {
        dispatch(fetchSettingsSuccess(settings));
      },
      function(error) {
        dispatch(fetchSettingsFailure(error));
      },
      params
    );
  };
}

export function fetchBanners() {
  return dispatch => {
    dispatch(fetchBannersBegin());
    return fetchBannersData(
      function(banners) {
        dispatch(fetchBannersSuccess(banners));
      },
      function(error) {
        dispatch(fetchBannersFailure(error));
      }
    );
  };
}

export function updateImage(imageName, imageFile) {
  return (dispatch, getState) => {
    dispatch(updateSettingsBegin());
    let settings = getState().learningCenterSettingsState;
    updateSettingsData(
      {
        logo: imageFile,
        logo_style: settings.logoStyle,
        name: settings.learningCenterCaption
      },
      () => dispatch(updateSuccess("logoImage", imageName)),
      error => dispatch(updateSettingsFailure(error))
    );
  };
}

export function updatePolicyLogoImage(imageFile, rawFile) {
  return dispatch => {
    dispatch(updateSettingsBegin());
    updateSettingsData(
      { policyLogo: rawFile },
      () => dispatch(fetchSettings()),
      error => dispatch(updateSettingsFailure(error))
    );
  };
}
export function clearPolicyLogoImage() {
  return dispatch => {
    dispatch(updateSettingsBegin());
    updateSettingsData(
      { deletePolicyLogo: true },
      () => dispatch(updateSuccess("policyLogoImage", null)),
      error => dispatch(updateSettingsFailure(error))
    );
  };
}

export function updateBannerImage(imageFile, index, status) {
  return dispatch => {
    dispatch(updateBannersBegin());
    updateBannersData(
      {
        banner_image: imageFile,
        index: index,
        status: status
      },
      response => {
        dispatch(
          updateBannerSuccess({
            bannerImages: response.banner_images
          })
        );
      },
      error => dispatch(updateBannerFailure(error))
    );
  };
}

export function deleteBannerImage(index) {
  return dispatch => {
    dispatch(updateBannersBegin());
    deleteBannersImage(
      index,
      response => {
        dispatch(
          updateBannerSuccess({
            bannerImages: response.banner_images
          })
        );
      },
      error => dispatch(updateBannerFailure(error))
    );
  };
}

export function changeNoBannerOption(option) {
  return dispatch => {
    dispatch(updateSettingsBegin());
    updateSettingsData(
      {
        no_banner: option
      },
      () => dispatch(updateSuccess("noBanner", option)),
      error => dispatch(updateSettingsFailure(error))
    );
  };
}

export function updateText(key, value, upload = true) {
  return (dispatch, getState) => {
    dispatch(updateSettingsBegin());
    let settings = getState().learningCenterSettingsState;
    settings[key] = value;
    if (upload) {
      updateSettingsData(
        {
          logo_style: settings.logoStyle,
          name: settings.learningCenterCaption
        },
        () => dispatch(updateSuccess(key, value)),
        error => dispatch(updateSettingsFailure(error))
      );
    } else {
      dispatch(updateSuccess(key, value));
    }
  };
}

function fetchSettingsData(successCallback, errorCallback, params) {
  visionCallService.fetchRequest(
    `${visionURL}/learning_center/api/settings`,
    successCallback,
    errorCallback,
    params
  );
}

function fetchBannersData(successCallback, errorCallback) {
  visionCallService.fetchRequest(
    `${visionURL}/learning_center/api/banners`,
    function(response) {
      successCallback({
        caption: response.text,
        bannerImages: response.banner_images
      });
    },
    errorCallback
  );
}

const updateSettingsData = (settingsData, successCallback, errorCallback) => {
  let data = new FormData();
  Object.keys(settingsData).forEach(x =>
    data.append("setting[" + x + "]", settingsData[x])
  );
  return visionCallService.putFormDataRequest(
    `${visionURL}/learning_center/api/settings`,
    data,
    successCallback,
    errorCallback
  );
};

const updateBannersData = (bannersData, successCallback, errorCallback) => {
  let data = new FormData();
  Object.keys(bannersData).forEach(x =>
    data.append("banners[" + x + "]", bannersData[x])
  );
  return visionCallService.putFormDataRequest(
    `${visionURL}/learning_center/api/banners`,
    data,
    successCallback,
    errorCallback
  );
};

const deleteBannersImage = (index, successCallback, errorCallback) => {
  return visionCallService.deleteRequest(
    `${visionURL}/learning_center/api/banners?banners[index]=${index}`,
    successCallback,
    errorCallback
  );
};

const zipBannerImagesWithDefaultBanners = bannersResponse => {
  let banners = bannersResponse.bannerImages;
  if (banners && banners.length > 0 && banners.imgPath) {
    banners = banners.map((image, index) =>
      image ? image : defaultBannerImages[index]
    );
  } else if (banners && banners.length > 0) {
    banners = banners.map((image, index) =>
      image ? image : deletedBannerImages[index]
    );
  }
  return banners;
};
