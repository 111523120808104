const checkIsPhqIframe = () => {
  if (location.ancestorOrigins instanceof DOMStringList) {
    return Array.from(location.ancestorOrigins).some(origin =>
      origin.includes("gracehill.com")
    );
  }

  if (location.href.includes("isPhq=true")) {
    return true;
  }

  return false;
};

export const isIframed = window.self !== window.top && checkIsPhqIframe();

export const isIframeAdmin = (user, iframeAdminRoles) =>
  isIframed &&
  user.included?.some(
    item =>
      item.type === "role" && iframeAdminRoles.includes(item.attributes.name)
  );
