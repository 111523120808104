import {
  FETCH_ORDERS_BEGIN,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_SHOP_TYPES_BEGIN,
  FETCH_SHOP_TYPES_SUCCESS,
  FETCH_SHOP_TYPES_FAILURE,
  FETCH_VALIDATE_LOCATIONS_BEGIN,
  FETCH_VALIDATE_LOCATIONS_FAILURE,
  FETCH_VALIDATE_LOCATIONS_SUCCESS,
  FETCH_ORDERS_LOCATIONS_BEGIN,
  FETCH_ORDERS_LOCATIONS_SUCCESS,
  FETCH_ORDERS_LOCATIONS_FAILURE,
  FETCH_EMPLOYEES_BY_LOCATION_BEGIN,
  FETCH_EMPLOYEES_BY_LOCATION_SUCCESS,
  FETCH_EMPLOYEES_BY_LOCATION_FAILURE,
  ADD_ORDER_A_LA_CART_SHOP_BEGIN,
  ADD_ORDER_A_LA_CART_SHOP_SUCCESS,
  ADD_ORDER_A_LA_CART_SHOP_FAILURE
} from "../../../actions/adminConsole/validate/validateActions";

const INITIAL_STATE = {
  orders: {},
  validateLocations: null,
  orderCartLocations: null,
  employeesByLocation: null,
  shopTypes: null,
  loading: false,
  orderCartLocationsLoading: false,
  employeesLoading: false,
  error: null,
  fetched: false
};

export default function ValidateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SHOP_TYPES_BEGIN:
    case ADD_ORDER_A_LA_CART_SHOP_BEGIN:
    case FETCH_VALIDATE_LOCATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_EMPLOYEES_BY_LOCATION_BEGIN:
      return {
        ...state,
        employeesLoading: true,
        error: null
      };
    case FETCH_ORDERS_BEGIN:
      return {
        ...state,
        fetched: false,
        loading: true,
        error: null
      };
    case FETCH_ORDERS_LOCATIONS_BEGIN:
      return {
        ...state,
        orderCartLocationsLoading: true,
        error: null,
        employeesByLocation: null
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        orders: action.payload
      };

    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        fetched: false,
        error: action.payload.error,
        orders: {}
      };
    case FETCH_SHOP_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        shopTypes: action.payload
      };
    case FETCH_SHOP_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shopTypes: []
      };

    case FETCH_VALIDATE_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        validateLocations: action.payload
      };
    case ADD_ORDER_A_LA_CART_SHOP_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ADD_ORDER_A_LA_CART_SHOP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_VALIDATE_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        validateLocations: []
      };
    case FETCH_ORDERS_LOCATIONS_SUCCESS:
      return {
        ...state,
        orderCartLocationsLoading: false,
        orderCartLocations: action.payload
      };
    case FETCH_ORDERS_LOCATIONS_FAILURE:
      return {
        ...state,
        orderCartLocationsLoading: false,
        error: action.payload.error,
        orderCartLocations: []
      };
    case FETCH_EMPLOYEES_BY_LOCATION_SUCCESS:
      return {
        ...state,
        employeesLoading: false,
        employeesByLocation: action.payload
      };
    case FETCH_EMPLOYEES_BY_LOCATION_FAILURE:
      return {
        ...state,
        employeesLoading: false,
        error: action.payload.error,
        employeesByLocation: []
      };
    default:
      return state;
  }
}
