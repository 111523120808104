import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./services/translation/en/translation.json";
import translationES from "./services/translation/es/translation.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  nsSeparator: false,
  keySeparator: ".",

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
