import nativeNormalize from "json-api-normalizer";

const normalize = (json, opts = {}) => {
  const entities = nativeNormalize(json, opts);
  const meta = json.meta;

  return { ...entities, meta };
};

export default normalize;
