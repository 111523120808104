import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";
import normalize from "../utils/normalize";

export const FETCH_SURVEYS_BEGIN = "FETCH_SURVEYS_BEGIN";
export const FETCH_SURVEYS_SUCCESS = "FETCH_SURVEYS_SUCCESS";
export const FETCH_SURVEYS_FAILURE = "FETCH_SURVEYS_FAILURE";
export const CREATE_SURVEY_BEGIN = "CREATE_SURVEY_BEGIN";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_FAILURE = "CREATE_SURVEY_FAILURE";
export const UPDATE_SURVEY_BEGIN = "UPDATE_SURVEY_BEGIN";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAILURE = "UPDATE_SURVEY_FAILURE";
export const UPDATE_SURVEY_QUESTIONS_BEGIN = "UPDATE_SURVEY_QUESTIONS_BEGIN";
export const UPDATE_SURVEY_QUESTIONS_SUCCESS =
  "UPDATE_SURVEY_QUESTIONS_SUCCESS";
export const UPDATE_SURVEY_QUESTIONS_FAILURE =
  "UPDATE_SURVEY_QUESTIONS_FAILURE";
export const SURVEY_UPDATE_QUESTIONS_BEGIN = "SURVEY_UPDATE_QUESTIONS_BEGIN";
export const SURVEY_UPDATE_QUESTIONS_SUCCESS =
  "SURVEY_UPDATE_QUESTIONS_SUCCESS";
export const SURVEY_UPDATE_QUESTIONS_FAILURE =
  "SURVEY_UPDATE_QUESTIONS_FAILURE";
export const SURVEY_CREATE_QUESTIONS_BEGIN = "SURVEY_CREATE_QUESTIONS_BEGIN";
export const SURVEY_CREATE_QUESTIONS_SUCCESS =
  "SURVEY_CREATE_QUESTIONS_SUCCESS";
export const SURVEY_CREATE_QUESTIONS_FAILURE =
  "SURVEY_CREATE_QUESTIONS_FAILURE";
export const IMPORT_SURVEY_QUESTIONS_BEGIN = "IMPORT_SURVEY_QUESTIONS_BEGIN";
export const IMPORT_SURVEY_QUESTIONS_SUCCESS =
  "IMPORT_SURVEY_QUESTIONS_SUCCESS";
export const IMPORT_SURVEY_QUESTIONS_FAILURE =
  "IMPORT_SURVEY_QUESTIONS_FAILURE";
export const UPDATE_QUESTION_BEGIN = "UPDATE_QUESTION_BEGIN";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAILURE = "UPDATE_QUESTION_FAILURE";

export const fetchSurveysBegin = () => ({
  type: FETCH_SURVEYS_BEGIN
});

export const fetchSurveysSuccess = surveysData => ({
  type: FETCH_SURVEYS_SUCCESS,
  payload: surveysData
});

export const fetchSurveysFailure = error => ({
  type: FETCH_SURVEYS_FAILURE,
  payload: { error }
});

export const createSurveyBegin = () => ({
  type: CREATE_SURVEY_BEGIN
});

export const createSurveySuccess = surveyData => ({
  type: CREATE_SURVEY_SUCCESS,
  payload: surveyData
});

export const createSurveyFailure = error => ({
  type: CREATE_SURVEY_FAILURE,
  payload: { error }
});

export const updateSurveyBegin = () => ({
  type: UPDATE_SURVEY_BEGIN
});

export const updateSurveySuccess = surveyData => ({
  type: UPDATE_SURVEY_SUCCESS,
  payload: surveyData
});

export const updateSurveyFailure = error => ({
  type: UPDATE_SURVEY_FAILURE,
  payload: { error }
});

export const updateSurveyQuestionsBegin = () => ({
  type: UPDATE_SURVEY_QUESTIONS_BEGIN
});

export const updateSurveyQuestionsSuccess = surveyQuestionsData => ({
  type: UPDATE_SURVEY_QUESTIONS_SUCCESS,
  payload: surveyQuestionsData
});

export const updateSurveyQuestionsFailure = error => ({
  type: UPDATE_SURVEY_QUESTIONS_FAILURE,
  payload: { error }
});

export const createQuestionsBegin = () => ({
  type: SURVEY_CREATE_QUESTIONS_BEGIN
});

export const createQuestionsSuccess = surveyQuestionsData => ({
  type: SURVEY_CREATE_QUESTIONS_SUCCESS,
  payload: surveyQuestionsData
});

export const createQuestionsFailure = error => ({
  type: SURVEY_CREATE_QUESTIONS_FAILURE,
  payload: { error }
});

export const updateQuestionBegin = () => ({
  type: UPDATE_QUESTION_BEGIN
});

export const updateQuestionSuccess = surveyQuestionsData => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload: surveyQuestionsData
});

export const updateQuestionFailure = error => ({
  type: UPDATE_QUESTION_FAILURE,
  payload: { error }
});

export const updateQuestionsBegin = () => ({
  type: SURVEY_UPDATE_QUESTIONS_BEGIN
});

export const updateQuestionsSuccess = surveyQuestionsData => ({
  type: SURVEY_UPDATE_QUESTIONS_SUCCESS,
  payload: surveyQuestionsData
});

export const updateQuestionsFailure = error => ({
  type: SURVEY_UPDATE_QUESTIONS_FAILURE,
  payload: { error }
});

export const fetchSurveys = (surveyId, queryParams) => dispatch => {
  const surveysUrl = `${visionURL}/admin_console/api/quizzes/${surveyId}?${visionCallService.buildQueryString(
    queryParams
  )}`;
  dispatch(fetchSurveysBegin());
  return visionCallService.requestWithNormalizer(
    surveysUrl,
    function(response) {
      dispatch(fetchSurveysSuccess(response));
    },
    function(error) {
      dispatch(fetchSurveysFailure(error));
    }
  );
};

export const createSurvey = newSurveyData => dispatch => {
  const surveyUrl = `${visionURL}/admin_console/api/quizzes/`;
  dispatch(createSurveyBegin());
  return visionCallService.requestWithNormalizer(
    surveyUrl,
    function(response) {
      dispatch(createSurveySuccess(response));
    },
    function(error) {
      dispatch(createSurveyFailure(error));
    },
    "POST",
    { ...newSurveyData, type: "courseSurvey" }
  );
};

export const updateSurvey = (
  surveyId,
  surveyData,
  updateCurrentSurveyAttributes = false
) => dispatch => {
  const surveyUrl = `${visionURL}/admin_console/api/quizzes/${surveyId}?updateCurrentQuizAttributes=${updateCurrentSurveyAttributes}`;

  dispatch(updateSurveyBegin());
  return visionCallService.requestWithNormalizer(
    surveyUrl,
    function(response) {
      return dispatch(updateSurveySuccess(response));
    },
    function(error) {
      dispatch(updateSurveyFailure(error));
    },
    "PATCH",
    { ...surveyData, type: "courseSurvey" }
  );
};

// Update the related questions for a survey
export const updateSurveyQuestions = (surveyId, questionsData) => dispatch => {
  const surveyUrl = `${visionURL}/admin_console/api/quizzes/${surveyId}/relationships/questions`;
  dispatch(updateSurveyQuestionsBegin());
  let payload = {
    include: ["questions"],
    data: {
      type: "survey",
      id: surveyId,
      relationships: {
        questions: {
          data: questionsData
        }
      }
    }
  };
  return visionCallService.postJsonDataRequest(
    surveyUrl,
    payload,
    function(response) {
      dispatch(updateSurveyQuestionsSuccess({ ...response, id: surveyId }));
    },
    function(error) {
      dispatch(updateSurveyQuestionsFailure(error));
    }
  );
};

// create survey questions
export const createQuestions = newQuestionsData => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/questions`;

  dispatch(createQuestionsBegin());
  return visionCallService.requestWithNormalizer(
    questionsUrl,
    function(response) {
      return dispatch(createQuestionsSuccess(response));
    },
    function(error) {
      dispatch(createQuestionsFailure(error));
    },
    "POST",
    newQuestionsData,
    ["answers", "matchClues", "matchChoices", "fillInTheBlanks"]
  );
};

// update survey question
export const updateQuestion = (newQuestionsData, questionId) => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/questions/${questionId}`;

  dispatch(updateQuestionBegin());
  return visionCallService.requestWithNormalizer(
    questionsUrl,
    function(response) {
      dispatch(updateQuestionSuccess(response));
    },
    function(error) {
      dispatch(updateQuestionFailure(error));
    },
    "PUT",
    newQuestionsData,
    ["answers", "matchClues", "matchChoices", "fillInTheBlanks"]
  );
};

// Update the attributes for survey questions
export const updateQuestions = newQuestionsData => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/questions/bulk_update`;
  dispatch(updateQuestionsBegin());
  return visionCallService.requestWithNormalizer(
    questionsUrl,
    function(response) {
      dispatch(updateQuestionsSuccess(response));
    },
    function(error) {
      dispatch(updateQuestionsFailure(error));
    },
    "PATCH",
    newQuestionsData
  );
};

export const importQuestionsBegin = () => ({
  type: IMPORT_SURVEY_QUESTIONS_BEGIN
});

export const importQuestionsSuccess = payload => ({
  type: IMPORT_SURVEY_QUESTIONS_SUCCESS,
  payload
});

export const importQuestionsFailure = payload => ({
  type: IMPORT_SURVEY_QUESTIONS_FAILURE,
  payload
});

export const importSurveyQuestions = (uploadedFile, surveyId) => dispatch => {
  const questionsUrl = `${visionURL}/admin_console/api/content/quizzes/${surveyId}/quiz_imports`;
  const data = { file: uploadedFile, quizType: "survey" };
  const formData = visionCallService.normalizeFormData(data);

  dispatch(importQuestionsBegin());
  return fetch(questionsUrl, {
    method: "POST",
    credentials: "include",
    body: formData
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      if (!response.ok) {
        dispatch(importQuestionsFailure(response));
      }
      return response.json();
    })
    .then(function(response) {
      if (response.errors) {
        dispatch(importQuestionsFailure(response.errors));
      } else {
        dispatch(
          importQuestionsSuccess({
            surveyId: `${surveyId}`,
            ...normalize(response)
          })
        );
      }
      return response;
    });
};
