export const learningCenterRoot = "/learning-center";
export const adminConsoleRoot = "/admin";
export const visionRoot = "/vision";
export const updatePassword = "/update-password";
export const coursePreview = "/course-preview/:versionId";
export const activityPreview = `${coursePreview}/activity-preview/:courseSectionId/:activityId`;

export const termsAndConditions = `${visionRoot}/terms-and-conditions`;
export const errorPage = `${visionRoot}/error`;
export const loggedOut = `${visionRoot}/logged-out`;
export const syncAccount = `${visionRoot}/sync-account`;
export const unauthorized = `${visionRoot}/unauthorized`;

export const termsAndConditionsEnglish = `${termsAndConditions}/en`;
export const termsAndConditionsSpanish = `${termsAndConditions}/es`;

export const buildCoursePreviewRoute = versionId =>
  `/course-preview/${versionId}`;

export const buildActivityPreviewRoute = (
  versionId,
  courseSectionId,
  activityId
) =>
  `${buildCoursePreviewRoute(
    versionId
  )}/activity-preview/${courseSectionId}/${activityId}`;

export const buildResourceViewerRoute = (currentApp, sectionId, itemId) =>
  `/${currentApp}/resource-sections/${sectionId}/items/${itemId}`;
