import React, { createContext, useState } from "react";
import { useLocation } from "react-router";
import { root, dashboard } from "../routes/LearningCenterRoutes";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [welcomeSlideOutOpen, setWelcomeSlideOutOpen] = useState(
    pathname === root || pathname === dashboard
  );

  return (
    <AppContext.Provider
      value={{
        mobileNavbarOpen,
        setMobileNavbarOpen,
        welcomeSlideOutOpen,
        setWelcomeSlideOutOpen
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;

export { AppProvider };
