import camelize from "camelize";

function getAttrFromPointer(pointer) {
  const arr = pointer.split("/");
  return arr[arr.length - 1];
}

function pushOrInit(obj, key, value) {
  if (typeof obj[key] === "undefined") {
    obj[key] = [];
  }
  obj[key].push(value);
  return camelize(obj);
}

function reducer(errors, e) {
  const attr = getAttrFromPointer(e.source.pointer);
  return pushOrInit(errors, attr, e.title);
}

export default function normalizeJsonapiErrors(errors) {
  if (typeof errors.errors === "undefined") {
    return {};
  }
  return errors.errors.reduce(reducer, {});
}
