import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_CUSTOMER_BEGIN = "FETCH_CUSTOMER_BEGIN";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";
export const UPDATE_CUSTOMER_BEGIN = "UPDATE_CUSTOMER_BEGIN";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const fetchCustomerBegin = () => ({
  type: FETCH_CUSTOMER_BEGIN
});

export const fetchCustomerSuccess = customer => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload: { customer }
});

export const fetchCustomerFailure = error => ({
  type: FETCH_CUSTOMER_FAILURE,
  payload: { error }
});

export const updateCustomerBegin = () => ({
  type: UPDATE_CUSTOMER_BEGIN
});

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: { customer }
});

export const updateCustomerFailure = error => ({
  type: UPDATE_CUSTOMER_FAILURE,
  payload: { error }
});

export function fetchCustomer(params) {
  return dispatch => {
    dispatch(fetchCustomerBegin());
    return fetchCurrentCustomer(
      function(customer) {
        dispatch(fetchCustomerSuccess(customer));
      },
      function(error) {
        dispatch(fetchCustomerFailure(error));
      },
      params
    );
  };
}

export function updateCustomer(params = {}) {
  return dispatch => {
    dispatch(updateCustomerBegin());
    return updateCurrentCustomer(
      params,
      function(customer) {
        dispatch(updateCustomerSuccess(customer));
      },
      function(error) {
        dispatch(updateCustomerFailure(error));
      }
    );
  };
}

const fetchCurrentCustomer = (successCallback, errorCallback, params) => {
  visionCallService.fetchRequest(
    `${visionURL}/learning_center/api/customer`,
    successCallback,
    errorCallback,
    params
  );
};

const updateCurrentCustomer = (params, successCallback, errorCallback) => {
  const data = {
    customer: { social_url: params.url, social_platform: params.platform }
  };
  visionCallService.putJsonDataRequest(
    `${visionURL}/learning_center/api/customer`,
    data,
    successCallback,
    errorCallback
  );
};
