import {
  FETCH_CALENDAR_EVENTS_BEGIN,
  FETCH_CALENDAR_EVENTS_SUCCESS,
  FETCH_CALENDAR_EVENTS_FAILURE,
  FETCH_CALENDAR_FEED_LINK_BEGIN,
  FETCH_CALENDAR_FEED_LINK_SUCCESS,
  FETCH_CALENDAR_FEED_LINK_FAILURE,
  UPDATE_CALENDAR_EVENTS_BEGIN,
  UPDATE_CALENDAR_EVENTS_SUCCESS,
  UPDATE_CALENDAR_EVENTS_FAILURE,
  UPDATE_CALENDAR_HIDDEN_CALENDARS,
  CREATE_NEW_EVENT_FAILURE
} from "../actions/calendarActions";

const INITIAL_STATE = {
  calendarLists: {
    training: { type: "Training", calendars: [] },
    other: { type: "Other", calendars: [] }
  },
  hiddenCalendars: [],
  feedLink: "",
  loading: false,
  error: null
};

function calendarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CALENDAR_EVENTS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CALENDAR_EVENTS_SUCCESS:
      console.log("FETCH_CALENDAR_EVENTS_SUCCESS", action.payload);
      const payloadLists = action.payload.calendarData.calendarLists;
      const calendarLists = Object.assign({}, state.calendarLists);
      calendarLists.training.calendars = payloadLists.training;
      calendarLists.other.calendars = payloadLists.other;
      return Object.assign({}, state, { calendarLists, loading: false });

    case FETCH_CALENDAR_EVENTS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log("FETCH_CALENDAR_EVENTS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    case FETCH_CALENDAR_FEED_LINK_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CALENDAR_FEED_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedLink: action.payload.feedLink
      };

    case FETCH_CALENDAR_FEED_LINK_FAILURE:
      console.log("FETCH_CALENDAR_FEED_LINK_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case UPDATE_CALENDAR_EVENTS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_CALENDAR_EVENTS_SUCCESS: {
      console.log("UPDATE_CALENDAR_EVENTS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        [action.payload.key]: action.payload.value
      };
    }

    case UPDATE_CALENDAR_EVENTS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      console.log("UPDATE_CALENDAR_EVENTS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    case UPDATE_CALENDAR_HIDDEN_CALENDARS: {
      return {
        ...state,
        hiddenCalendars: action.payload.eventNames
      };
    }

    case CREATE_NEW_EVENT_FAILURE:
      console.log("CREATE_NEW_EVENT_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default calendarReducer;
