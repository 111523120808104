import { visionURL } from "../config/environment";
import normalize from "../redux/utils/normalize";
import { serialize } from "../redux/utils/jsona";
import { toSnakeCase } from "../utils/string";
import Jsona from "jsona";
import { isIframed } from "../utils/iframe";

const visionCallService = {};
const UNAUTHORIZED_CODE = 401;

const dataFormatter = new Jsona();

visionCallService.redirectForAuth = response => {
  const unauthorized = response.status === UNAUTHORIZED_CODE;
  if (isIframed && unauthorized) {
    window.parent.postMessage("sessionTimeout", "*");
  } else if (unauthorized) {
    let url = `${visionURL}/learning_center/redirects/new`;
    const folder = new URL(window.location.href).searchParams.get("folder");
    const pathname = window.location.pathname;
    const params = { folder, pathname };

    const searchParamsString = Object.keys(params)
      .filter(key => params[key])
      .map(key => `${toSnakeCase(key)}=${params[key]}`)
      .join("&");

    if (searchParamsString) {
      url += `?${searchParamsString}`;
    }
    window.location = url;
  }
};

visionCallService.checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    console.error("error", response.status, response.statusText);
    return response;
  }
};

visionCallService.jsonApiSerialize = (type, data) => {
  let inner = { type, attributes: data };
  if (data.id) {
    inner.id = data.id;
  }
  return { data: inner };
};

// This function is for flattening the response from JSON API spec responses
// where each serializer includes `attributes`` nested underneath a `data`` field.
// https://jsonapi.org/
visionCallService.extractAttributes = obj => {
  const extractJsonAPIResponse = obj => {
    let objCopy = {};
    Object.assign(objCopy, obj);
    Object.assign(objCopy, obj["data"]["attributes"]);
    delete objCopy.data;
    Object.keys(objCopy).forEach(key => {
      objCopy[key] = extractAttributes(objCopy[key]);
    });
    return objCopy;
  };

  const extractJsonAPIResponseArray = obj => {
    let objCopy = [];
    obj["data"].forEach(item => {
      let itemCopy = {};
      Object.assign(itemCopy, item);
      Object.assign(itemCopy, item["attributes"]);
      delete itemCopy["attributes"];
      Object.keys(itemCopy).forEach(key => {
        itemCopy[key] = extractAttributes(itemCopy[key]);
      });
      objCopy.push(itemCopy);
    });
    return objCopy;
  };

  const isObject = obj => obj !== null && typeof obj === "object";
  const isJsonAPIResponse = obj =>
    obj.hasOwnProperty("data") &&
    obj["data"] &&
    obj["data"].hasOwnProperty("attributes");
  const isEmptyJsonAPIResponse = obj =>
    obj.hasOwnProperty("data") && obj["data"] === null;
  const isJsonAPIResponseArray = obj =>
    obj.hasOwnProperty("data") &&
    Array.isArray(obj["data"]) &&
    obj["data"].every(item => item.hasOwnProperty("attributes"));
  const isExtractable = obj => isObject(obj) && isJsonAPIResponse(obj);
  const isArrayOfExtractables = obj =>
    isObject(obj) && isJsonAPIResponseArray(obj);

  const extractAttributes = obj => {
    if (isExtractable(obj)) {
      return extractJsonAPIResponse(obj);
    } else if (isArrayOfExtractables(obj)) {
      return extractJsonAPIResponseArray(obj);
    } else if (Array.isArray(obj)) {
      return obj.map(item => extractAttributes(item));
    } else if (isObject(obj) && isEmptyJsonAPIResponse(obj)) {
      return null;
    } else {
      return obj;
    }
  };

  let result = extractAttributes(obj);
  if (isObject(result) && isObject(obj) && obj.meta) {
    result.meta = obj.meta;
  }
  return result;
};

visionCallService.postSerializedData = (
  type,
  data,
  apiUrl,
  successCallback,
  errorCallback,
  method = "POST"
) => {
  visionCallService.postData(
    visionCallService.jsonApiSerialize(type, data),
    apiUrl,
    successCallback,
    errorCallback,
    method
  );
};

visionCallService.putData = (data, apiUrl, method = "PUT") => {
  return fetch(apiUrl, {
    method,
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data || {})
  });
};

visionCallService.postData = (
  data,
  apiUrl,
  successCallback,
  errorCallback,
  method = "POST"
) => {
  return fetch(apiUrl, {
    method,
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data || {})
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      const parsedResponse = visionCallService.extractAttributes(response);
      console.log(`${apiUrl} request succeeded`, parsedResponse);
      return successCallback(parsedResponse);
    })
    .catch(function(error) {
      console.log(`${apiUrl} request failed`, error);
      errorCallback(error);
    });
};

visionCallService.putJsonDataRequest = (
  apiUrl,
  data,
  successCallback,
  errorCallback
) => {
  return sendJsonBodyRequest(
    apiUrl,
    data,
    "PUT",
    successCallback,
    errorCallback
  );
};

visionCallService.postJsonDataRequest = (
  apiUrl,
  data,
  successCallback,
  errorCallback
) => {
  return sendJsonBodyRequest(
    apiUrl,
    data,
    "POST",
    successCallback,
    errorCallback
  );
};

visionCallService.putFormDataRequest = (
  apiUrl,
  data,
  successCallback,
  errorCallback
) => {
  return sendFormRequest(apiUrl, data, "PUT", successCallback, errorCallback);
};

visionCallService.postFormDataRequest = (
  apiUrl,
  data,
  successCallback,
  errorCallback
) => {
  return sendFormRequest(apiUrl, data, "POST", successCallback, errorCallback);
};

const sendJsonBodyRequest = (
  apiUrl,
  data,
  method,
  successCallback,
  errorCallback
) => {
  fetch(apiUrl, {
    method: method,
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(data || {})
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      return successCallback(visionCallService.extractAttributes(response));
    })
    .catch(function(error) {
      console.log("request failed", error);
      errorCallback(error);
    });
};

const sendFormRequest = (
  apiUrl,
  data,
  method,
  successCallback,
  errorCallback
) => {
  return fetch(apiUrl, {
    method: method,
    credentials: "include",
    body: data
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      return successCallback(visionCallService.extractAttributes(response));
    })
    .catch(function(error) {
      console.log("request failed", error);
      errorCallback(error);
    });
};

visionCallService.deleteRequest = (apiUrl, successCallback, errorCallback) => {
  return fetch(apiUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.status === 204 ? {} : response.json();
    })
    .then(function(response) {
      return successCallback(visionCallService.extractAttributes(response));
    })
    .catch(function(error) {
      console.log("request failed", error);
      errorCallback(error);
    });
};

visionCallService.fetchRequest = (
  url,
  successCallback,
  errorCallback,
  params,
  extract = true
) => {
  if (params && typeof params === "object") {
    const searchParamsString = Object.keys(params)
      .map(key => `${toSnakeCase(key)}=${params[key]}`)
      .join("&");
    url += `?${searchParamsString}`;
  }

  return fetch(url, {
    credentials: "include"
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      if (extract) {
        successCallback(visionCallService.extractAttributes(response));
      } else {
        successCallback(response);
      }
      return response;
    })
    .catch(function(error) {
      console.error("fetch failed", error);
      errorCallback(error);
    });
};

visionCallService.requestWithNormalizer = (
  url,
  successCallback,
  errorCallback,
  method = "GET",
  body,
  includeNames,
  options
) => {
  const requestInfo =
    method === "GET"
      ? Object.assign(
          {
            credentials: "include"
          },
          options
        )
      : Object.assign(
          {
            method,
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body ? serialize(body, includeNames) : {})
          },
          options
        );
  return fetch(url, requestInfo)
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.status === 204 ? {} : response.json();
    })
    .then(function(response) {
      if (response.errors) {
        errorCallback(response);
      } else {
        const normalizedResponse = normalize(response);
        successCallback(normalizedResponse);
      }
      return response;
    })
    .catch(function(error) {
      console.error("fetch failed", error);
      errorCallback(error);
      return error;
    });
};

/**
 * Uses the filter strategy from the JSON API Spec
 * example path: /comments?filter[post]=12&filter[author]=12
 */
visionCallService.buildFilters = filterValues => {
  let filterString = "";
  if (filterValues) {
    let filters = Object.keys(filterValues).filter(
      filter => filterValues[filter]
    );
    filters.forEach((filter, index) => {
      if (!!filterValues[filter]) {
        filterString += `filter[${filter}]=${filterValues[filter]}`;
        if (index !== filters.length - 1) {
          filterString += "&";
        }
      }
    });
  }
  return filterString;
};

visionCallService.buildQueryString = queryParams => {
  let queryString = "";
  if (queryParams) {
    let params = Object.keys(queryParams).filter(param => queryParams[param]);
    params.forEach((param, index) => {
      if (!!queryParams[param]) {
        queryString += `${param}=${queryParams[param]}`;
        if (index !== params.length - 1) {
          queryString += "&";
        }
      }
    });
  }
  return queryString;
};
visionCallService.buildTableDataQueryString = queryData => {
  let queryString = "";
  if (queryData) {
    queryData.filters &&
      queryData.filters.forEach(filter => {
        queryString += `&${filter.column.field}=${filter.value}`;
      });
    if (queryData.orderBy) {
      queryString += `&orderBy=${queryData.orderBy.field}&direction=${queryData.orderDirection}`;
    }
    queryString += `&page=${queryData.page}&pageSize=${queryData.pageSize}`;
  }
  return queryString;
};

visionCallService.sendEmailRequest = (
  userEmail,
  trainingRecordId,
  emailUrl
) => {
  let route = `${visionURL}/certificates/email/${trainingRecordId}`;
  if (emailUrl) {
    route = emailUrl + `&email=${userEmail}`;
  } else {
    route += `?email=${userEmail}`;
  }
  return fetch(route, {
    method: "POST",
    credentials: "include"
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      console.log("sendEmailRequest success: ", response);
      return response;
    })
    .catch(error => {
      console.log("SendEmailRequest failed", error);
      return error;
    });
};

visionCallService.normalizeFormData = (data, additions) => {
  const formData = new FormData();
  Object.keys(data).forEach(x =>
    formData.append(`data[attributes][${x}]`, data[x])
  );
  if (additions && typeof additions === "object") {
    Object.keys(additions).forEach(x =>
      formData.append(`data[attributes][${x}]`, data[x])
    );
  }
  return formData;
};
/**
 * @param  {Object} body The request body
 * @param  {string} type The type of the primary resource in the request body
 * @param  {string[]} includeNames The relationship key names for the related resources to include with the request
 */
visionCallService.serializeRequestBody = (body, type, includeNames) =>
  JSON.stringify(
    body
      ? serialize(
          {
            type,
            ...(includeNames && { relationshipNames: includeNames }),
            ...body
          },
          includeNames
        )
      : {}
  );

visionCallService.serializeAtomicOperation = (
  opData,
  type,
  operation,
  relationshipNames = []
) => {
  return opData
    ? { op: operation, ...serialize({ type, ...opData, relationshipNames }) }
    : {};
};

visionCallService.deserializeAtomicResults = response => {
  return response["atomic:results"].map(result =>
    dataFormatter.deserialize(result)
  );
};

export default visionCallService;
