export const UPDATE_TABLE_STATUS = "UPDATE_TABLE_STATUS";
export const RESET_TABLE_STATUS = "RESET_TABLE_STATUS";

export const updateTableStatus = (table, tableQueryData, filters) => ({
  type: UPDATE_TABLE_STATUS,
  payload: { table, tableQueryData, filters }
});

export const resetTableStatus = table => ({
  type: RESET_TABLE_STATUS,
  payload: { table }
});
