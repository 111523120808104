import visionCallService from "../../../services/visionCallService";
import { visionURL } from "../../../config/environment";

/**
 * Resources that have been selected using the checkbox in the table in the Add Items modal that
 * will be added to the current resource section if they hit the "Add Items" button
 */
export const UPDATE_SELECTED_RESOURCES = "UPDATE_SELECTED_RESOURCES";
export const UPDATE_RESOURCE_SECTION_BEGIN = "UPDATE_RESOURCE_SECTION_BEGIN";
export const UPDATE_RESOURCE_SECTION_SUCCESS =
  "UPDATE_RESOURCE_SECTION_SUCCESS";
export const UPDATE_RESOURCE_SECTION_FAILURE =
  "UPDATE_RESOURCE_SECTION_FAILURE";
export const UPDATE_RESOURCE_ITEM_BEGIN = "UPDATE_RESOURCE_ITEM_BEGIN";
export const UPDATE_RESOURCE_ITEM_SUCCESS = "UPDATE_RESOURCE_ITEM_SUCCESS";
export const UPDATE_RESOURCE_ITEM_FAILURE = "UPDATE_RESOURCE_ITEM_FAILURE";
export const ADD_RESOURCE_SECTION_BEGIN = "ADD_RESOURCE_SECTION_BEGIN";
export const ADD_RESOURCE_SECTION_SUCCESS = "ADD_RESOURCE_SECTION_SUCCESS";
export const ADD_RESOURCE_SECTION_FAILURE = "ADD_RESOURCE_SECTION_FAILURE";
export const FETCH_RESOURCES_BEGIN = "FETCH_RESOURCES_BEGIN";
export const FETCH_RESOURCES_SUCCESS = "FETCH_RESOURCES_SUCCESS";
export const FETCH_RESOURCES_FAILURE = "FETCH_RESOURCES_FAILURE";
export const FETCH_RESOURCE_ITEMS_BEGIN = "FETCH_RESOURCE_ITEMS_BEGIN";
export const FETCH_RESOURCE_ITEMS_SUCCESS = "FETCH_RESOURCE_ITEMS_SUCCESS";
export const FETCH_RESOURCE_ITEMS_FAILURE = "FETCH_RESOURCE_ITEMS_FAILURE";
export const FETCH_RESOURCE_ITEM_BEGIN = "FETCH_RESOURCE_ITEM_BEGIN";
export const FETCH_RESOURCE_ITEM_SUCCESS = "FETCH_RESOURCE_ITEM_SUCCESS";
export const FETCH_RESOURCE_ITEM_FAILURE = "FETCH_RESOURCE_ITEM_FAILURE";
export const DELETE_RESOURCE_SECTION_BEGIN = "DELETE_RESOURCE_SECTION_BEGIN";
export const DELETE_RESOURCE_SECTION_SUCCESS =
  "DELETE_RESOURCE_SECTION_SUCCESS";
export const DELETE_RESOURCE_SECTION_FAILURE =
  "DELETE_RESOURCE_SECTION_FAILURE";
export const DELETE_RESOURCE_ITEM_BEGIN = "DELETE_RESOURCE_ITEM_BEGIN";
export const DELETE_RESOURCE_ITEM_SUCCESS = "DELETE_RESOURCE_ITEM_SUCCESS";
export const DELETE_RESOURCE_ITEM_FAILURE = "DELETE_RESOURCE_ITEM_FAILURE";
export const MOVE_RESOURCE_ITEM_BEGIN = "MOVE_RESOURCE_ITEM_BEGIN";
export const MOVE_RESOURCE_ITEM_SUCCESS = "MOVE_RESOURCE_ITEM_SUCCESS";
export const MOVE_RESOURCE_ITEM_FAILURE = "MOVE_RESOURCE_ITEM_FAILURE";
export const MOVE_RESOURCE_SECTION_SUCCESS = "MOVE_RESOURCE_SECTION_SUCCESS";

export const updateSelectedResources = selectedResources => ({
  type: UPDATE_SELECTED_RESOURCES,
  payload: { selectedResources }
});

export const updateSectionBegin = () => ({
  type: UPDATE_RESOURCE_SECTION_BEGIN
});

export const updateSectionFailure = error => ({
  type: UPDATE_RESOURCE_SECTION_FAILURE,
  payload: { error }
});

export const updateSectionSuccess = section => ({
  type: UPDATE_RESOURCE_SECTION_SUCCESS,
  payload: { section }
});

export const moveSectionSuccess = sections => ({
  type: MOVE_RESOURCE_SECTION_SUCCESS,
  payload: { sections }
});

export const updateItemBegin = () => ({
  type: UPDATE_RESOURCE_ITEM_BEGIN
});

export const updateItemFailure = error => ({
  type: UPDATE_RESOURCE_ITEM_FAILURE,
  payload: { error }
});

export const updateItemSuccess = (item, sectionId) => ({
  type: UPDATE_RESOURCE_ITEM_SUCCESS,
  payload: { item, sectionId }
});

export const moveItemBegin = () => ({
  type: MOVE_RESOURCE_ITEM_BEGIN
});

export const moveItemFailure = error => ({
  type: MOVE_RESOURCE_ITEM_FAILURE,
  payload: { error }
});

export const moveItemSuccess = (itemId, fromSectionId, destinatedSection) => ({
  type: MOVE_RESOURCE_ITEM_SUCCESS,
  payload: { itemId, fromSectionId, destinatedSection }
});

export const addResourceSectionBegin = () => ({
  type: ADD_RESOURCE_SECTION_BEGIN
});

export const addResourceSectionFailure = error => ({
  type: ADD_RESOURCE_SECTION_FAILURE,
  payload: { error }
});

export const addResourceSectionSuccess = (key, value) => ({
  type: ADD_RESOURCE_SECTION_SUCCESS,
  payload: { key, value }
});

export const fetchResourcesSuccess = resourceSections => ({
  type: FETCH_RESOURCES_SUCCESS,
  payload: { resourceSections }
});

export const fetchResourcesFailure = error => ({
  type: FETCH_RESOURCES_FAILURE,
  payload: { error }
});

export const fetchResourcesBegin = () => ({
  type: FETCH_RESOURCES_BEGIN
});

export const fetchResourceItemsBegin = () => ({
  type: FETCH_RESOURCE_ITEMS_BEGIN
});

export const fetchResourceItemsSuccess = items => ({
  type: FETCH_RESOURCE_ITEMS_SUCCESS,
  payload: { items }
});

export const fetchResourceItemsFailure = error => ({
  type: FETCH_RESOURCE_ITEMS_FAILURE,
  payload: { error }
});
export const fetchResourceItemBegin = () => ({
  type: FETCH_RESOURCE_ITEM_BEGIN
});

export const fetchResourceItemSuccess = item => ({
  type: FETCH_RESOURCE_ITEM_SUCCESS,
  payload: { item }
});

export const fetchResourceItemFailure = error => ({
  type: FETCH_RESOURCE_ITEM_FAILURE,
  payload: { error }
});

export const deleteSectionBegin = () => ({
  type: DELETE_RESOURCE_SECTION_BEGIN
});

export const deleteSectionFailure = error => ({
  type: DELETE_RESOURCE_SECTION_FAILURE,
  payload: { error }
});

export const deleteSectionSuccess = resourceSections => ({
  type: DELETE_RESOURCE_SECTION_SUCCESS,
  payload: { resourceSections }
});

export const deleteItemBegin = () => ({
  type: DELETE_RESOURCE_ITEM_BEGIN
});

export const deleteItemFailure = error => ({
  type: DELETE_RESOURCE_ITEM_FAILURE,
  payload: { error }
});

export const deleteItemSuccess = section => ({
  type: DELETE_RESOURCE_ITEM_SUCCESS,
  payload: { section }
});

export function fetchResources() {
  return dispatch => {
    dispatch(fetchResourcesBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/content_repository/sections`,
      function(resources) {
        dispatch(fetchResourcesSuccess(resources));
      },
      function(error) {
        dispatch(fetchResourcesFailure(error));
      }
    );
  };
}

export function fetchResourceItems() {
  return dispatch => {
    dispatch(fetchResourceItemsBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/content_repository/items`,
      function(items) {
        dispatch(fetchResourceItemsSuccess(items));
      },
      function(error) {
        dispatch(fetchResourceItemsFailure(error));
      }
    );
  };
}

export function fetchResourceItem(sectionId, itemId) {
  return dispatch => {
    dispatch(fetchResourceItemBegin());
    let resourceItemUrl = `${visionURL}/learning_center/api/content_repository/items/${itemId}?`;
    if (sectionId) {
      resourceItemUrl += `section_id=${sectionId}`;
    }
    return visionCallService.fetchRequest(
      resourceItemUrl,
      function(item) {
        dispatch(fetchResourceItemSuccess(item));
      },
      function(error) {
        dispatch(fetchResourceItemFailure(error));
      }
    );
  };
}

export function updateSection(sectionId, name) {
  return dispatch => {
    dispatch(updateSectionBegin());
    return visionCallService.putJsonDataRequest(
      `${visionURL}/learning_center/api/content_repository/sections/${sectionId}`,
      { data: { type: "section", attributes: { name: name } } },
      function(item) {
        dispatch(updateSectionSuccess(item));
      },
      function(error) {
        dispatch(updateSectionFailure(error));
      }
    );
  };
}

export function moveSection(sectionId, placeAfter = 0) {
  return dispatch => {
    dispatch(updateSectionBegin());
    return visionCallService.putJsonDataRequest(
      `${visionURL}/learning_center/api/content_repository/sections/${sectionId}`,
      { data: { type: "section", attributes: { place_after: placeAfter } } },
      function(sections) {
        dispatch(moveSectionSuccess(sections));
      },
      function(error) {
        dispatch(updateSectionFailure(error));
      }
    );
  };
}

export function deleteSection(sectionId) {
  return dispatch => {
    dispatch(deleteSectionBegin());
    return visionCallService.deleteRequest(
      `${visionURL}/learning_center/api/content_repository/sections/${sectionId}`,
      function(sections) {
        dispatch(deleteSectionSuccess(sections));
      },
      function(error) {
        dispatch(deleteSectionFailure(error));
      }
    );
  };
}

export function deleteItem(sectionId, itemId) {
  return dispatch => {
    dispatch(deleteItemBegin());
    return visionCallService.deleteRequest(
      `${visionURL}/learning_center/api/content_repository/sections/${sectionId}/items/${itemId}/section_items`,
      function(section) {
        dispatch(deleteItemSuccess(section));
      },
      function(error) {
        dispatch(deleteItemFailure(error));
      }
    );
  };
}

export function updateItem(itemId, name, sectionId = null) {
  return dispatch => {
    dispatch(updateItemBegin());
    return visionCallService.putJsonDataRequest(
      `${visionURL}/learning_center/api/content_repository/items/${itemId}`,
      { data: { type: "item", attributes: { name: name } } },
      function(item) {
        dispatch(updateItemSuccess(item, sectionId));
      },
      function(error) {
        dispatch(updateItemFailure(error));
      }
    );
  };
}

export function moveItem(
  itemId,
  fromSectionId,
  toSectionId,
  placeAfterItemId = 0
) {
  return dispatch => {
    dispatch(moveItemBegin());
    return visionCallService.putJsonDataRequest(
      `${visionURL}/learning_center/api/content_repository/sections/${fromSectionId}/items/${itemId}/section_items`,
      {
        data: {
          type: "section_item",
          attributes: {
            destination_item_id: placeAfterItemId,
            destination_section_id: toSectionId
          }
        }
      },
      function(destinatedSection) {
        dispatch(
          moveItemSuccess(
            parseInt(itemId),
            parseInt(fromSectionId),
            destinatedSection
          )
        );
      },
      function(error) {
        dispatch(moveItemFailure(error));
      }
    );
  };
}

export function addResourceSection(sectionName) {
  return dispatch => {
    dispatch(addResourceSectionBegin());
    return visionCallService.postJsonDataRequest(
      `${visionURL}/learning_center/api/content_repository/sections`,
      {
        data: {
          type: "section",
          attributes: { name: sectionName }
        }
      },
      function(section) {
        dispatch(addResourceSectionSuccess("resourceSection", section));
      },
      function(error) {
        dispatch(addResourceSectionFailure(error));
      }
    );
  };
}
