import { SET_EXPANDED_LIST } from "../../actions/learningCenter/expandedAssignmentsActions";

const INITIAL_STATE = {
  expandedList: {}
};

function ExpandedAssignmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_EXPANDED_LIST:
      return {
        ...state,
        expandedList: {
          ...state.expandedList,
          [action.payload.key]: action.payload.value
        }
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default ExpandedAssignmentsReducer;
