import visionCallService from "../../../services/visionCallService";
import { visionURL } from "../../../config/environment";
import normalize from "../../utils/normalize";
import JsonApiService from "../../../services/jsonapiService";
import { enqueueSuccessSnackbar } from "../snackbarActions";
import { fetchUserAssignmentsListSuccess } from "../userAssignmentsListActions";

export const FETCH_CONTENTABLE_BEGIN = "FETCH_CONTENTABLE_BEGIN";
export const FETCH_CONTENTABLE_SUCCESS = "FETCH_CONTENTABLE_SUCCESS";
export const FETCH_CONTENTABLE_FAILURE = "FETCH_CONTENTABLE_FAILURE";
export const CREATE_HOMEWORK_SUBMISSION_BEGIN =
  "CREATE_HOMEWORK_SUBMISSION_BEGIN";
export const CREATE_HOMEWORK_SUBMISSION_SUCCESS =
  "CREATE_HOMEWORK_SUBMISSION_SUCCESS";
export const CREATE_HOMEWORK_SUBMISSION_FAILURE =
  "CREATE_HOMEWORK_SUBMISSION_FAILURE";
export const FETCH_HOMEWORK_SUBMISSIONS_BEGIN =
  "FETCH_HOMEWORK_SUBMISSIONS_BEGIN";
export const FETCH_HOMEWORK_SUBMISSIONS_SUCCESS =
  "FETCH_HOMEWORK_SUBMISSIONS_SUCCESS";
export const FETCH_HOMEWORK_SUBMISSIONS_FAILURE =
  "FETCH_HOMEWORK_SUBMISSIONS_FAILURE";

const contentableApiService = async () =>
  new JsonApiService("course_contentables", {
    baseUrl: `${visionURL}/learning_center/api`,
    credentials: "include"
  });

export const fetchContentableBegin = () => ({
  type: FETCH_CONTENTABLE_BEGIN
});

export const fetchContentableSuccess = payload => ({
  type: FETCH_CONTENTABLE_SUCCESS,
  payload
});

export const fetchContentableFailure = error => ({
  type: FETCH_CONTENTABLE_FAILURE,
  payload: { error }
});

export const fetchContentable = id => {
  return async dispatch => {
    const service = await contentableApiService();
    dispatch(fetchContentableBegin());
    const json = await service
      .include(["homework.fileFormats", "homeworkSubmission"])
      .find(id)
      .then(response => response.json());
    if (json.errors) {
      dispatch(fetchContentableFailure(json.errors));
    } else {
      // This is a hacky temp solution, but currently when we call the course contentable endpoint for quizzes
      // the type is being returned as "quizContentable" and it's not in the proper json API format so we can't
      // use the normalize function. When we refactor the course contentable controller to return the quizzes with
      // the type "contentable" and it's in proper JSON API format, we can remove the if statement below to use the
      // extractAttributes method for quizzes
      const normalizedResponse = normalize(json);

      if (json.data && json.data.type === "contentable") {
        dispatch(fetchUserAssignmentsListSuccess(normalizedResponse));
        dispatch(fetchContentableSuccess(normalizedResponse));
      } else {
        // once the course quiz response follows the json api spec, we can remove this
        dispatch(
          fetchContentableSuccess({
            quiz: visionCallService.extractAttributes(json),
            ...normalizedResponse
          })
        );
      }
    }
    return json;
  };
};
/*
const homeworkSubmissionApiService = async () =>
  new JsonApiService("homework_submissions", {
    baseUrl: `${visionURL}/learning_center/api`,
    credentials: "include"
  });*/

const createHomeworkSubmissionBegin = () => ({
  type: CREATE_HOMEWORK_SUBMISSION_BEGIN
});

const createHomeworkSubmissionSuccess = payload => ({
  type: CREATE_HOMEWORK_SUBMISSION_SUCCESS,
  payload
});

const createHomeworkSubmissionFailure = payload => ({
  type: CREATE_HOMEWORK_SUBMISSION_FAILURE,
  payload
});

export const createHomeworkSubmission = (
  newHomeworkData,
  successMessage,
  contentableId,
  userAssignmentId
) => dispatch => {
  dispatch(createHomeworkSubmissionBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/learning_center/api/course_contentables/${contentableId}/homework_submissions?filter[userAssignmentId]=${userAssignmentId}`,
    response => {
      dispatch(
        createHomeworkSubmissionSuccess({
          contentableId,
          ...response
        })
      );
      dispatch(enqueueSuccessSnackbar({ message: successMessage }));
    },
    error => dispatch(createHomeworkSubmissionFailure(error)),
    "POST",
    { ...newHomeworkData, type: "homeworkSubmission" }
  );
};

const fetchHomeworkSubmissionsBegin = () => ({
  type: FETCH_HOMEWORK_SUBMISSIONS_BEGIN
});

const fetchHomeworkSubmissionsSuccess = payload => ({
  type: FETCH_HOMEWORK_SUBMISSIONS_SUCCESS,
  payload
});

const fetchHomeworkSubmissionsFailure = payload => ({
  type: FETCH_HOMEWORK_SUBMISSIONS_FAILURE,
  payload
});

export const fetchHomeworkSubmissions = (
  courseContentableId,
  userAssignmentId
) => {
  return async dispatch => {
    const service = await contentableApiService();
    dispatch(fetchHomeworkSubmissionsBegin());
    const json = await service
      .where({ userAssignmentId })
      .related(courseContentableId, "homework_submissions")
      .then(response => response.json())
      .catch(error => dispatch(fetchHomeworkSubmissionsFailure(error)));
    if (json && json.errors) {
      dispatch(fetchHomeworkSubmissionsFailure(json.errors));
    } else if (json) {
      dispatch(fetchHomeworkSubmissionsSuccess(normalize(json)));
    }
    return json;
  };
};
