import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";
import JsonApiService from "../../services/jsonapiService";
import normalize from "../utils/normalize";

export const FETCH_SHORT_COURSE_PAGE_TEMPLATES_SUCCESS =
  "FETCH_SHORT_COURSE_PAGE_TEMPLATES_SUCCESS";
export const FETCH_SHORT_COURSE_PAGE_TEMPLATES_FAILURE =
  "FETCH_SHORT_COURSE_PAGE_TEMPLATES_FAILURE";
export const FETCH_SHORT_COURSE_PAGE_TEMPLATES_BEGIN =
  "FETCH_SHORT_COURSE_PAGE_TEMPLATES_BEGIN";

export const fetchShortCoursePageTemplatesBegin = () => ({
  type: FETCH_SHORT_COURSE_PAGE_TEMPLATES_BEGIN
});

export const fetchShortCoursePageTemplatesSuccess = payload => ({
  type: FETCH_SHORT_COURSE_PAGE_TEMPLATES_SUCCESS,
  payload
});

export const fetchShortCoursePageTemplatesFailure = error => ({
  type: FETCH_SHORT_COURSE_PAGE_TEMPLATES_FAILURE,
  payload: { error }
});

export function fetchShortCoursePageTemplates() {
  const apiUrl = `${visionURL}/admin_console/api/course_builder/short_course_page_templates`;

  return dispatch => {
    dispatch(fetchShortCoursePageTemplatesBegin);
    visionCallService.fetchRequest(
      apiUrl,
      function(response) {
        const templates = visionCallService.extractAttributes(response);
        dispatch(fetchShortCoursePageTemplatesSuccess(templates));
      },
      function(error) {
        dispatch(fetchShortCoursePageTemplatesFailure(error));
      }
    );
  };
}

export const FETCH_FILE_FORMATS_BEGIN = "FETCH_FILE_FORMATS_BEGIN";
export const FETCH_FILE_FORMATS_SUCCESS = "FETCH_FILE_FORMATS_SUCCESS";
export const FETCH_FILE_FORMATS_FAILURE = "FETCH_FILE_FORMATS_FAILURE";

const fileFormatsApiService = async () =>
  new JsonApiService("file_formats", {
    baseUrl: `${visionURL}/admin_console/api/course_builder`,
    credentials: "include"
  });

const fetchFileFormatsBegin = () => ({
  type: FETCH_FILE_FORMATS_BEGIN
});

const fetchFileFormatsSuccess = payload => ({
  type: FETCH_FILE_FORMATS_SUCCESS,
  payload
});

const fetchFileFormatsFailure = payload => ({
  type: FETCH_FILE_FORMATS_FAILURE,
  payload
});

export const fetchFileFormats = () => {
  return async dispatch => {
    const service = await fileFormatsApiService();
    dispatch(fetchFileFormatsBegin());
    const json = await service.all().then(response => response.json());
    if (json.errors) {
      dispatch(fetchFileFormatsFailure(json.errors));
    } else {
      dispatch(fetchFileFormatsSuccess(normalize(json)));
    }
    return json;
  };
};

export const FETCH_QUIZ_IMPORT_TEMPLATE_BEGIN =
  "FETCH_QUIZ_IMPORT_TEMPLATE_BEGIN";
export const FETCH_QUIZ_IMPORT_TEMPLATE_SUCCESS =
  "FETCH_QUIZ_IMPORT_TEMPLATE_SUCCESS";
export const FETCH_QUIZ_IMPORT_TEMPLATE_FAILURE =
  "FETCH_QUIZ_IMPORT_TEMPLATE_FAILURE";

const quizImportTemplateApiService = async () =>
  new JsonApiService("quiz_import_templates", {
    baseUrl: `${visionURL}/admin_console/api/course_builder`,
    credentials: "include"
  });

const fetchQuizImportTemplateBegin = () => ({
  type: FETCH_QUIZ_IMPORT_TEMPLATE_BEGIN
});

const fetchQuizImportTemplateSuccess = payload => ({
  type: FETCH_QUIZ_IMPORT_TEMPLATE_SUCCESS,
  payload
});

const fetchQuizImportTemplateFailure = payload => ({
  type: FETCH_QUIZ_IMPORT_TEMPLATE_FAILURE,
  payload
});

export const fetchQuizImportTemplate = () => {
  return async dispatch => {
    const service = await quizImportTemplateApiService();
    dispatch(fetchQuizImportTemplateBegin());
    const json = await service.all().then(response => response.json());
    if (json.errors) {
      dispatch(fetchQuizImportTemplateFailure(json.errors));
    } else {
      dispatch(fetchQuizImportTemplateSuccess(normalize(json)));
    }
    return json;
  };
};
