import {
  FETCH_WIDGETS_BEGIN,
  FETCH_WIDGETS_SUCCESS,
  FETCH_WIDGETS_FAILURE,
  UPDATE_WIDGETS_BEGIN,
  UPDATE_WIDGETS_FAILURE,
  UPDATE_WIDGETS_SUCCESS,
  UPDATE_WIDGETS_LAYOUT_BEGIN,
  UPDATE_WIDGETS_LAYOUT_SUCCESS,
  UPDATE_WIDGETS_LAYOUT_FAILURE,
  UPDATE_WIDGET_HEIGHT,
  CREATE_CUSTOM_WIDGET_BEGIN,
  CREATE_CUSTOM_WIDGET_SUCCESS,
  CREATE_CUSTOM_WIDGET_FAILURE,
  UPDATE_CUSTOM_WIDGET_BEGIN,
  UPDATE_CUSTOM_WIDGET_SUCCESS,
  UPDATE_CUSTOM_WIDGET_FAILURE,
  SET_EDIT_WIDGET_MODE
} from "../actions/widgetsActions";

const INITIAL_STATE = {
  widgets: [],
  widgetContentHeights: {},
  editWidgetMode: false
};

function widgetsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_WIDGETS_BEGIN:
      console.log("UPDATE_WIDGETS_BEGIN", action.payload);
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_WIDGETS_SUCCESS:
      console.log("UPDATE_WIDGETS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        widgets: action.payload.widgets
      };
    case UPDATE_WIDGETS_FAILURE:
      console.log("UPDATE_WIDGETS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case UPDATE_WIDGETS_LAYOUT_BEGIN:
      console.log("UPDATE_WIDGETS_BEGIN", action.payload);
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_WIDGETS_LAYOUT_SUCCESS:
      console.log("UPDATE_WIDGETS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        widgets: action.payload.widgets
      };
    case UPDATE_WIDGETS_LAYOUT_FAILURE:
      console.log("UPDATE_WIDGETS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case CREATE_CUSTOM_WIDGET_BEGIN:
      console.log("CREATE_CUSTOM_WIDGET_BEGIN", action.payload);
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_CUSTOM_WIDGET_SUCCESS: {
      console.log("CREATE_CUSTOM_WIDGET_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        widgets: [...state.widgets, action.payload.widget]
      };
    }
    case CREATE_CUSTOM_WIDGET_FAILURE:
      console.log("CREATE_CUSTOM_WIDGET_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case UPDATE_CUSTOM_WIDGET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_CUSTOM_WIDGET_SUCCESS: {
      let id = action.payload.widget.id;
      const newWidgets = state.widgets.map(widget => {
        if (widget.id === id) {
          return action.payload.widget;
        }
        return widget;
      });

      return {
        ...state,
        loading: false,
        widgets: [...newWidgets]
      };
    }
    case UPDATE_CUSTOM_WIDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_WIDGETS_BEGIN:
      console.log("FETCH_WIDGETS_BEGIN", action.payload);
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_WIDGETS_FAILURE:
      console.log("FETCH_WIDGETS_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_WIDGETS_SUCCESS:
      console.log("FETCH_WIDGETS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        widgets: action.payload.widgets
      };
    case SET_EDIT_WIDGET_MODE:
      console.log("SET_EDIT_WIDGET_MODE", action.payload);
      return {
        ...state,
        editWidgetMode: action.payload
      };
    case UPDATE_WIDGET_HEIGHT:
      if (
        state.widgetContentHeights[action.payload.widgetName] !==
        action.payload.widgetHeight
      ) {
        let currentWidgetHeights = Object.assign(
          {},
          state.widgetContentHeights
        );
        currentWidgetHeights[action.payload.widgetName] =
          action.payload.widgetHeight;
        return {
          ...state,
          widgetContentHeights: currentWidgetHeights
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}

export default widgetsReducer;
