import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";
import blankAvatar from "../../assets/images/blank_avatar.png";
import { toSnakeCase } from "../../utils/string";

export const FETCH_MESSAGES_BEGIN = "FETCH_MESSAGES_BEGIN";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILURE = "FETCH_MESSAGES_FAILURE";
export const FETCH_UNREAD_MESSAGES_BEGIN = "FETCH_UNREAD_MESSAGES_BEGIN";
export const FETCH_UNREAD_MESSAGES_SUCCESS = "FETCH_UNREAD_MESSAGES_SUCCESS";
export const FETCH_UNREAD_MESSAGES_FAILURE = "FETCH_UNREAD_MESSAGES_FAILURE";
export const DISMISS_MESSAGES_BEGIN = "DISMISS_MESSAGES_BEGIN";
export const DISMISS_MESSAGES_SUCCESS = "DISMISS_MESSAGES_SUCCESS";
export const DISMISS_MESSAGES_FAILURE = "DISMISS_MESSAGES_FAILURE";
export const VIEW_MESSAGE_BEGIN = "VIEW_MESSAGE_BEGIN";
export const VIEW_MESSAGE_SUCCESS = "VIEW_MESSAGE_SUCCESS";
export const VIEW_MESSAGE_FAILURE = "VIEW_MESSAGE_FAILURE";

export const fetchMessagesBegin = () => ({
  type: FETCH_MESSAGES_BEGIN
});

export const fetchMessagesSuccess = messages => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages }
});

export const fetchMessagesFailure = error => ({
  type: FETCH_MESSAGES_FAILURE,
  payload: { error }
});

export const fetchUnreadMessagesBegin = () => ({
  type: FETCH_UNREAD_MESSAGES_BEGIN
});

export const fetchUnreadMessagesSuccess = messages => ({
  type: FETCH_UNREAD_MESSAGES_SUCCESS,
  payload: { messages }
});

export const fetchUnreadMessagesFailure = error => ({
  type: FETCH_UNREAD_MESSAGES_FAILURE,
  payload: { error }
});

export const dismissMessagesBegin = () => ({
  type: DISMISS_MESSAGES_BEGIN
});

export const dismissMessagesSuccess = messageIds => ({
  type: DISMISS_MESSAGES_SUCCESS,
  payload: { messageIds }
});

export const dismissMessagesFailure = error => ({
  type: DISMISS_MESSAGES_FAILURE,
  payload: { error }
});

export const viewMessageBegin = () => ({
  type: VIEW_MESSAGE_BEGIN
});

export const viewMessageSuccess = message => ({
  type: VIEW_MESSAGE_SUCCESS,
  payload: { message }
});

export const viewMessageFailure = error => ({
  type: VIEW_MESSAGE_FAILURE,
  payload: { error }
});

export function fetchMessages(params) {
  return dispatch => {
    dispatch(fetchMessagesBegin());
    return fetchMessagesRequest(
      "all",
      function(messages) {
        dispatch(fetchMessagesSuccess(messages));
      },
      function(error) {
        dispatch(fetchMessagesFailure(error));
      },
      params
    );
  };
}

export function fetchUnreadMessages(params) {
  return dispatch => {
    dispatch(fetchUnreadMessagesBegin());
    return fetchMessagesRequest(
      "unread",
      function(messages) {
        dispatch(fetchUnreadMessagesSuccess(mapUnread(messages)));
      },
      function(error) {
        dispatch(fetchUnreadMessagesFailure(error));
      },
      params
    );
  };
}

export function dismissMessages(messageIds, successCallback) {
  return dispatch => {
    dispatch(dismissMessagesBegin());
    return dismissMessagesRequest(
      messageIds,
      function() {
        if (successCallback && typeof successCallback === "function") {
          successCallback();
        }
        dispatch(dismissMessagesSuccess(messageIds));
      },
      function(error) {
        dispatch(dismissMessagesFailure(error));
      }
    );
  };
}

export function viewMessage(messageId, successCallback) {
  return dispatch => {
    dispatch(viewMessageBegin());
    return viewMessageRequest(
      messageId,
      function(message) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(message);
        }
        dispatch(viewMessageSuccess(message));
      },
      function(error) {
        dispatch(viewMessageFailure(error));
      }
    );
  };
}

function fetchMessagesRequest(type, successCallback, errorCallback, params) {
  let url = `${visionURL}/learning_center/api/users/messages?type=${type}`;
  if (params && typeof params === "object") {
    Object.keys(params).forEach(key => {
      url += `&${toSnakeCase(key)}=${params[key]}`;
    });
  }
  visionCallService.fetchRequest(url, successCallback, errorCallback);
}

function dismissMessagesRequest(messageIds, successCallback, errorCallback) {
  visionCallService.postJsonDataRequest(
    `${visionURL}/learning_center/api/users/messages/dismissal`,
    { message: { ids: messageIds } },
    successCallback,
    errorCallback
  );
}

function viewMessageRequest(messageId, successCallback, errorCallback) {
  visionCallService.putJsonDataRequest(
    `${visionURL}/learning_center/api/users/messages/${messageId}`,
    { message: { viewed_at: new Date() } },
    successCallback,
    errorCallback
  );
}

function mapUnread(messages) {
  return messages.map(msg => {
    return {
      id: msg.id,
      name: msg.message.fromFirstName + " " + msg.message.fromLastName,
      img: msg.message.avatarUrl || blankAvatar,
      subject: msg.message.subject,
      content: msg.message.text
    };
  });
}
