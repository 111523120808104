import {
  FETCH_ACHIEVEMENTS_SUCCESS,
  FETCH_ACHIEVEMENTS_FAILURE,
  FETCH_ACHIEVEMENTS_IN_PROGRESS
} from "../actions/achievementsActions";

const INITIAL_STATE = {
  items: [],
  loading: false,
  error: null
};

function achievementsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ACHIEVEMENTS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ACHIEVEMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        items: action.payload.achievements
      };
    }

    case FETCH_ACHIEVEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    default:
      return state;
  }
}

export default achievementsReducer;
