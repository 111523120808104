import {
  FETCH_ANNOUNCEMENTS_BEGIN,
  FETCH_ANNOUNCEMENTS_SUCCESS,
  FETCH_ANNOUNCEMENTS_FAILURE,
  UPSERT_ANNOUNCEMENT_BEGIN,
  CREATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPSERT_ANNOUNCEMENT_FAILURE,
  DELETE_ANNOUNCEMENT_BEGIN,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILURE,
  SET_ATTRIBUTE,
  SET_CURRENT,
  SHOW_ANNOUNCEMENT_MODAL,
  HIDE_ANNOUNCEMENT_MODAL
} from "../actions/announcementsActions";

const INITIAL_STATE = {
  announcements: [],
  announcement: {},
  showModal: false,
  loading: false,
  error: null
};

function announcementsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_ANNOUNCEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        announcements: state.announcements.filter((item, index) => {
          if (parseInt(item.id) === parseInt(action.payload.id)) {
            return false;
          }
          return true;
        }),
        error: null
      };
    case DELETE_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case FETCH_ANNOUNCEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        announcements: action.payload.announcements
      };
    }

    case FETCH_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        announcements: []
      };
    case UPSERT_ANNOUNCEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CREATE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        announcement: {
          ...state.announcement,
          id: action.payload.id
        }
      };
    }

    case UPDATE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case UPSERT_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    case SET_ATTRIBUTE:
      return {
        ...state,
        announcement: {
          ...state.announcement,
          [action.payload.key]: action.payload.value
        }
      };
    case SET_CURRENT:
      return {
        ...state,
        announcement: action.payload.announcement || {}
      };
    case SHOW_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        announcement: action.payload.announcement,
        showModal: true
      };
    case HIDE_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        showModal: false
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default announcementsReducer;
