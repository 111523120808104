import { visionURL } from "../../config/environment";
import visionCallService from "../../services/visionCallService";

export const FETCH_CUSTOMER_APPS_BEGIN = "FETCH_CUSTOMER_APPS_BEGIN";
export const FETCH_CUSTOMER_APPS_SUCCESS = "FETCH_CUSTOMER_APPS_SUCCESS";
export const FETCH_CUSTOMER_APPS_FAILURE = "FETCH_CUSTOMER_APPS_FAILURE";
export const CREATE_CUSTOMER_APP_BEGIN = "UPDATE_CUSTOMER_APP_BEGIN";
export const CREATE_CUSTOMER_APP_SUCCESS = "UPDATE_CUSTOMER_APP_SUCCESS";
export const CREATE_CUSTOMER_APP_FAILURE = "UPDATE_CUSTOMER_APP_FAILURE";
export const UPDATE_CUSTOMER_APP_BEGIN = "UPDATE_CUSTOMER_APP_BEGIN";
export const UPDATE_CUSTOMER_APP_SUCCESS = "UPDATE_CUSTOMER_APP_SUCCESS";
export const UPDATE_CUSTOMER_APP_FAILURE = "UPDATE_CUSTOMER_APP_FAILURE";
export const DELETE_CUSTOMER_APP_BEGIN = "DELETE_CUSTOMER_APP_BEGIN";
export const DELETE_CUSTOMER_APP_SUCCESS = "DELETE_CUSTOMER_APP_SUCCESS";
export const DELETE_CUSTOMER_APP_FAILURE = "DELETE_CUSTOMER_APP_FAILURE";

export const fetchCustomerAppsBegin = () => ({
  type: FETCH_CUSTOMER_APPS_BEGIN
});

export const fetchCustomerAppsSuccess = customerApps => ({
  type: FETCH_CUSTOMER_APPS_SUCCESS,
  payload: { customerApps }
});

export const fetchCustomerAppsFailure = error => ({
  type: FETCH_CUSTOMER_APPS_FAILURE,
  payload: { error }
});

export const createCustomerAppBegin = () => ({
  type: CREATE_CUSTOMER_APP_BEGIN
});

export const createCustomerAppSuccess = customerApps => ({
  type: CREATE_CUSTOMER_APP_SUCCESS,
  payload: { customerApps }
});

export const createCustomerAppFailure = error => ({
  type: CREATE_CUSTOMER_APP_FAILURE,
  payload: { error }
});

export const updateCustomerAppBegin = () => ({
  type: UPDATE_CUSTOMER_APP_BEGIN
});

export const updateCustomerAppSuccess = customerApps => ({
  type: UPDATE_CUSTOMER_APP_SUCCESS,
  payload: { customerApps }
});

export const updateCustomerAppFailure = error => ({
  type: UPDATE_CUSTOMER_APP_FAILURE,
  payload: { error }
});

export const deleteCustomerAppBegin = () => ({
  type: DELETE_CUSTOMER_APP_BEGIN
});

export const deleteCustomerAppSuccess = customerApps => ({
  type: DELETE_CUSTOMER_APP_SUCCESS,
  payload: { customerApps }
});

export const deleteCustomerAppFailure = error => ({
  type: DELETE_CUSTOMER_APP_FAILURE,
  payload: { error }
});

export function fetchCustomerApps() {
  return dispatch => {
    dispatch(fetchCustomerAppsBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/apps`,
      function(apps) {
        dispatch(fetchCustomerAppsSuccess(apps));
        return apps;
      },
      function(error) {
        console.log("request failed", error);
        dispatch(fetchCustomerAppsFailure(error));
      }
    );
  };
}

export function createCustomerApp(appData, successCallback) {
  let data = new FormData();
  Object.keys(appData).forEach(x =>
    data.append("app_params[" + x + "]", appData[x])
  );

  return dispatch => {
    dispatch(updateCustomerAppBegin());
    return visionCallService.postFormDataRequest(
      `${visionURL}/learning_center/api/apps`,
      data,
      function(apps) {
        dispatch(updateCustomerAppSuccess(apps));
        successCallback();
        return apps;
      },
      function(error) {
        console.log("request failed", error);
        alert("Something went wrong during save. Please try again later.");
        dispatch(updateCustomerAppFailure(error));
      }
    );
  };
}

export function updateCustomerApp(appData, appId, successCallback) {
  let data = new FormData();
  Object.keys(appData).forEach(x =>
    data.append("app_params[" + x + "]", appData[x])
  );

  return dispatch => {
    dispatch(updateCustomerAppBegin());
    return visionCallService.putFormDataRequest(
      `${visionURL}/learning_center/api/apps/${appId}`,
      data,
      function(apps) {
        dispatch(updateCustomerAppSuccess(apps));
        successCallback();
        return apps;
      },
      function(error) {
        console.log("request failed", error);
        alert("Something went wrong during save. Please try again later.");
        dispatch(updateCustomerAppFailure(error));
      }
    );
  };
}

export function deleteCustomerApp(appId, successCallback) {
  return dispatch => {
    dispatch(updateCustomerAppBegin());
    return visionCallService.deleteRequest(
      `${visionURL}/learning_center/api/apps/${appId}`,
      function(apps) {
        dispatch(updateCustomerAppSuccess(apps));
        successCallback();
        return apps;
      },
      function(error) {
        console.log("request failed", error);
        alert("Something went wrong during save. Please try again later.");
        dispatch(updateCustomerAppFailure(error));
      }
    );
  };
}
