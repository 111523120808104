import {
  ADD_UPLOAD_AC_COURSE_BUILDER_BEGIN,
  ADD_UPLOAD_AC_COURSE_BUILDER_SUCCESS,
  ADD_UPLOAD_AC_COURSE_BUILDER_FAILURE,
  MARK_JOB_DONE_AC_COURSE_BUILDER_SUCCESS,
  FETCH_ITEM_AC_COURSE_BUILDER_BEGIN,
  FETCH_ITEM_AC_COURSE_BUILDER_SUCCESS,
  FETCH_ITEM_AC_COURSE_BUILDER_FAILURE,
  UPDATE_ITEM_AC_COURSE_BUILDER_BEGIN,
  UPDATE_ITEM_AC_COURSE_BUILDER_SUCCESS,
  UPDATE_ITEM_AC_COURSE_BUILDER_FAILURE
} from "../../../actions/adminConsole/content/courseBuilderActions";

const INITIAL_STATE = {
  currentlyEditedActivity: null,
  jobId: null,
  itemId: null,
  loading: false,
  error: null
};

function courseBuilderReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_UPLOAD_AC_COURSE_BUILDER_BEGIN:
      return {
        ...state,
        jobId: null,
        itemId: null,
        loading: true,
        error: null
      };
    case ADD_UPLOAD_AC_COURSE_BUILDER_SUCCESS:
      return {
        ...state,
        jobId: payload.result.jobId,
        itemId: payload.result.itemId,
        loading: payload.result.jobId ? true : false,
        error: null
      };
    case ADD_UPLOAD_AC_COURSE_BUILDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case MARK_JOB_DONE_AC_COURSE_BUILDER_SUCCESS:
      return {
        ...state,
        jobId: null,
        loading: false,
        error: null
      };
    case FETCH_ITEM_AC_COURSE_BUILDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ITEM_AC_COURSE_BUILDER_SUCCESS:
      return {
        ...state,
        currentlyEditedActivity: payload.item,
        loading: false,
        error: null
      };
    case FETCH_ITEM_AC_COURSE_BUILDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case UPDATE_ITEM_AC_COURSE_BUILDER_BEGIN:
      return {
        ...state,
        jobId: null,
        itemId: null,
        loading: true,
        error: null
      };
    case UPDATE_ITEM_AC_COURSE_BUILDER_SUCCESS:
      return {
        ...state,
        jobId: payload.result.jobId,
        itemId: payload.result.id,
        loading: payload.result.jobId ? true : false,
        error: null
      };
    case UPDATE_ITEM_AC_COURSE_BUILDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    default:
      return { ...state };
  }
}

export default courseBuilderReducer;
