import {
  FETCH_CONTENTABLE_BEGIN,
  FETCH_CONTENTABLE_SUCCESS,
  FETCH_CONTENTABLE_FAILURE,
  CREATE_HOMEWORK_SUBMISSION_SUCCESS,
  FETCH_HOMEWORK_SUBMISSIONS_SUCCESS
} from "../../actions/learningCenter/contentableActions";
import {
  UPDATE_QUIZ_ANSWER_BEGIN,
  UPDATE_QUIZ_ANSWER_SUCCESS,
  UPDATE_QUIZ_ANSWER_FAILURE
} from "../../actions/learningCenter/quizActions";
import { merge } from "lodash";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: null
};

function ContentableReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CONTENTABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_QUIZ_ANSWER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_CONTENTABLE_SUCCESS:
      console.log("FETCH_CONTENTABLE_SUCCESS", action.payload.contentable);
      return {
        ...merge({}, state, action.payload),
        quiz: action.payload.quiz,
        loading: false,
        error: null
      };
    case FETCH_CONTENTABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        item: {}
      };
    case UPDATE_QUIZ_ANSWER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case UPDATE_QUIZ_ANSWER_SUCCESS: {
      const answerChoices = action.payload.answerChoices;
      const questionId = action.payload.questionId;
      return {
        ...state,
        loading: false,
        item: {
          ...state.item,
          learnerAnswers: {
            ...state.item.learnerAnswers,
            [questionId]: answerChoices
          }
        }
      };
    }
    case CREATE_HOMEWORK_SUBMISSION_SUCCESS:
    case FETCH_HOMEWORK_SUBMISSIONS_SUCCESS:
      return {
        ...merge({}, state, action.payload)
      };
    default:
      return state;
  }
}

export default ContentableReducer;
