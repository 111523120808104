import React, { useEffect } from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import "./scss/styles.scss";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "./redux/actions/userActions";
import { fetchCustomer } from "./redux/actions/customerActions";
import { fetchSettings } from "./redux/actions/learningCenterSettingsActions";
import GoogleMapWrapper from "./components/GoogleMap/GoogleMapWrapper";
import useCustomerColors from "./hooks/useCustomerColors";
import { fetchUserProfile } from "./redux/actions/userProfileActions";
import { usePendo } from "./hooks/usePendo";
import { phqUrl } from "./config/environment";
import { useLocation } from "react-router-dom";
import { isIframed } from "./utils/iframe";

// Hacky temporary fix until we can get redirect for bad routes working in PHQ
const iframedPhqRoutes = [
  "/learning-center/learn",
  "/learning-center/courses",
  "/learning-center/resources",
  "/learning-center/calendar",
  "/learning-center/library-search",
  "/admin/content/courses",
  "/admin/content/repositories",
  "/vision/unauthorized",
  "/course-preview"
];

const initializeGoogleAnalytics = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT &&
    process.env.REACT_APP_ENVIRONMENT === "production"
  ) {
    ReactGA.initialize([
      {
        trackingId: "G-SLNB2V93F1"
      }
    ]);
    ReactGA.send({
      hitType: "pageview",
      page: `/${window.location.pathname + window.location.search}`
    });
    console.log(window.location.pathname + window.location.search);
  } else if (process.env.REACT_APP_ENVIRONMENT?.includes("dev")) {
    ReactGA.initialize([
      {
        trackingId: "G-VXM00WMGSK"
      }
    ]);
    ReactGA.send({
      hitType: "pageview",
      page: `/${window.location.pathname + window.location.search}`,
      title: "Testing Title"
    });
    console.log(window.location.pathname + window.location.search);
  } else {
    console.log("Environment", process.env.REACT_APP_ENVIRONMENT);
  }
};

const App = ({ hideLoader, children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { primaryColor, secondaryColor } = useCustomerColors();

  useEffect(() => {
    initializeGoogleAnalytics();
    dispatch(fetchUser());
    dispatch(fetchCustomer());
    dispatch(fetchSettings());
    dispatch(fetchUserProfile());
  }, []);

  const { customer } = useSelector(state => ({
    customer: state.customerState.customer
  }));

  if (customer?.visionLoginDisabled && !isIframed) {
    let phqFrontendUrl = phqUrl(customer.phqSubdomain);
    if (iframedPhqRoutes.some(route => pathname.includes(route))) {
      phqFrontendUrl =
        phqFrontendUrl + window.location.pathname + window.location.search;
    }
    // Navigate user to PHQ when vision login is disabled
    window.location = phqFrontendUrl;
  }

  usePendo();

  return (
    <GoogleMapWrapper>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme(primaryColor, secondaryColor)}>
          <DndProvider backend={HTML5Backend}>{children}</DndProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleMapWrapper>
  );
};

export default App;
