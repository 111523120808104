import {
  UPDATE_SELECTED_RESOURCES,
  UPDATE_RESOURCE_SECTION_BEGIN,
  UPDATE_RESOURCE_SECTION_SUCCESS,
  MOVE_RESOURCE_SECTION_SUCCESS,
  UPDATE_RESOURCE_SECTION_FAILURE,
  FETCH_RESOURCES_BEGIN,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  FETCH_RESOURCE_ITEM_SUCCESS,
  FETCH_RESOURCE_ITEM_BEGIN,
  FETCH_RESOURCE_ITEM_FAILURE,
  ADD_RESOURCE_SECTION_BEGIN,
  ADD_RESOURCE_SECTION_SUCCESS,
  ADD_RESOURCE_SECTION_FAILURE,
  UPDATE_RESOURCE_ITEM_BEGIN,
  UPDATE_RESOURCE_ITEM_SUCCESS,
  UPDATE_RESOURCE_ITEM_FAILURE,
  MOVE_RESOURCE_ITEM_BEGIN,
  MOVE_RESOURCE_ITEM_SUCCESS,
  MOVE_RESOURCE_ITEM_FAILURE,
  DELETE_RESOURCE_SECTION_BEGIN,
  DELETE_RESOURCE_SECTION_SUCCESS,
  DELETE_RESOURCE_SECTION_FAILURE,
  DELETE_RESOURCE_ITEM_BEGIN,
  DELETE_RESOURCE_ITEM_FAILURE,
  DELETE_RESOURCE_ITEM_SUCCESS
} from "../../actions/learningCenter/resourcesActions";

const INITIAL_STATE = {
  resourceSections: null,
  selectedResources: [],
  currentResourceItem: {}
};

const updateItemInSection = (section, updatedItem) => {
  const updatedItems = section.items.map(item => {
    if (parseInt(item.id) === parseInt(updatedItem.id)) {
      return updatedItem;
    }
    return item;
  });
  return updatedItems;
};

function resourcesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_SELECTED_RESOURCES:
      return {
        ...state,
        selectedResources: action.payload.selectedResources
      };
    case FETCH_RESOURCES_BEGIN:
      console.log("FETCH_RESOURCES_BEGIN");
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_RESOURCES_SUCCESS: {
      console.log("FETCH_RESOURCES_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        resourceSections: action.payload.resourceSections
      };
    }
    case FETCH_RESOURCES_FAILURE:
      console.log("FETCH_RESOURCES_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_RESOURCE_ITEM_SUCCESS:
      return {
        ...state,
        currentResourceItem: action.payload.item,
        loading: false,
        error: null
      };
    case FETCH_RESOURCE_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_RESOURCE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case ADD_RESOURCE_SECTION_BEGIN:
      console.log("ADD_RESOURCE_SECTION_BEGIN", action.payload);
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_RESOURCE_SECTION_SUCCESS: {
      console.log("ADD_RESOURCE_SECTION_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        resourceSections: [...state.resourceSections, action.payload.value]
      };
    }
    case ADD_RESOURCE_SECTION_FAILURE:
      console.log("ADD_RESOURCE_SECTION_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case UPDATE_RESOURCE_SECTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_RESOURCE_SECTION_SUCCESS: {
      return {
        ...state,
        resourceSections: state.resourceSections.map(function(section) {
          if (section.id === action.payload.section.id) {
            section.name = action.payload.section.name;
          }
          return section;
        }),
        loading: false,
        error: null
      };
    }
    case UPDATE_RESOURCE_SECTION_FAILURE:
      console.log("ADD_RESOURCE_SECTION_FAILURE", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_RESOURCE_SECTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DELETE_RESOURCE_SECTION_SUCCESS:
      return {
        ...state,
        resourceSections: action.payload.resourceSections,
        loading: false,
        error: null
      };
    case DELETE_RESOURCE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_RESOURCE_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DELETE_RESOURCE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_RESOURCE_ITEM_SUCCESS:
      return {
        ...state,
        resourceSections: state.resourceSections.map(function(section) {
          if (section.id === action.payload.section.id) {
            return action.payload.section;
          }
          return section;
        }),
        loading: false,
        error: null
      };
    case UPDATE_RESOURCE_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_RESOURCE_ITEM_SUCCESS:
      return {
        ...state,
        resourceSections: state.resourceSections.map(section => {
          if (section.id === action.payload.sectionId) {
            return {
              ...section,
              items: updateItemInSection(section, action.payload.item)
            };
          }
          return section;
        }),
        loading: false,
        error: null
      };
    case UPDATE_RESOURCE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case MOVE_RESOURCE_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MOVE_RESOURCE_ITEM_SUCCESS:
      const { itemId, fromSectionId, destinatedSection } = action.payload;
      let sections = state.resourceSections;
      if (fromSectionId === destinatedSection.id) {
        sections = sections.map(section => {
          if (section.id === destinatedSection.id) {
            return destinatedSection;
          }
          return section;
        });
      } else {
        sections = sections.map(section => {
          if (section.id === destinatedSection.id) {
            return destinatedSection;
          } else if (section.id === fromSectionId) {
            return {
              ...section,
              items: section.items.filter(item => item.id !== itemId)
            };
          }
          return section;
        });
      }
      return {
        ...state,
        resourceSections: sections,
        loading: false,
        error: null
      };
    case MOVE_RESOURCE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case MOVE_RESOURCE_SECTION_SUCCESS: {
      return {
        ...state,
        resourceSections: action.payload.sections,
        loading: false,
        error: null
      };
    }
    default:
      return state;
  }
}

export default resourcesReducer;
