import { root, dashboard } from "../../../../routes/LearningCenterRoutes";
import {
  FETCH_TODO_BEGIN,
  FETCH_TODO_SUCCESS,
  FETCH_TODO_FAILURE
} from "../../../actions/learningCenter/dashboard/todoActions";

const locationPath = window.location.pathname;
const INITIAL_STATE = {
  toDo: {},
  loading: false,
  open: locationPath === dashboard || locationPath === root ? true : false,
  error: null
};

function todoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TODO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_TODO_SUCCESS: {
      return {
        ...state,
        loading: false,
        toDo: action.payload.toDo
      };
    }

    case FETCH_TODO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        todoList: []
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default todoReducer;
