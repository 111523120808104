import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";
import { transformParamsToSnakeCase } from "../../utils/string";
import normalize from "../utils/normalize";
import JsonApiService from "../../services/jsonapiService";
import { uploadFile } from "../../services/fileUploadService";
import Jsona from "jsona";
import { serialize } from "../utils/jsona";

const dataFormatter = new Jsona();

export const ADD_COURSE_BEGIN = "ADD_COURSE_BEGIN";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILURE = "ADD_COURSE_FAILURE";
export const UPDATE_COURSE_BEGIN = "UPDATE_COURSE_BEGIN";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILURE = "UPDATE_COURSE_FAILURE";
export const FETCH_COURSES_BEGIN = "FETCH_COURSES_BEGIN";
export const FETCH_COURSES_SUCCESS = "FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_FAILURE = "FETCH_COURSES_FAILURE";
export const FETCH_COURSE_VERSIONS_BEGIN = "FETCH_COURSE_VERSIONS_BEGIN";
export const FETCH_COURSE_VERSIONS_SUCCESS = "FETCH_COURSE_VERSIONS_SUCCESS";
export const FETCH_COURSE_VERSIONS_FAILURE = "FETCH_COURSE_VERSIONS_FAILURE";
export const ADD_COURSE_VERSION_BEGIN = "ADD_COURSE_VERSION_BEGIN";
export const ADD_COURSE_VERSION_SUCCESS = "ADD_COURSE_VERSION_SUCCESS";
export const ADD_COURSE_VERSION_FAILURE = "ADD_COURSE_VERSION_FAILURE";
export const UPDATE_COURSE_VERSION_BEGIN = "UPDATE_COURSE_VERSION_BEGIN";
export const UPDATE_COURSE_VERSION_SUCCESS = "UPDATE_COURSE_VERSION_SUCCESS";
export const UPDATE_COURSE_VERSION_FAILURE = "UPDATE_COURSE_VERSION_FAILURE";
export const FETCH_COURSE_SESSION_BEGIN = "FETCH_COURSE_SESSION_BEGIN";
export const FETCH_COURSE_SESSION_SUCCESS = "FETCH_COURSE_SESSION_SUCCESS";
export const FETCH_COURSE_SESSION_FAILURE = "FETCH_COURSE_SESSION_FAILURE";
export const ADD_COURSE_SESSION_BEGIN = "ADD_COURSE_SESSION_BEGIN";
export const ADD_COURSE_SESSION_SUCCESS = "ADD_COURSE_SESSION_SUCCESS";
export const ADD_COURSE_SESSION_FAILURE = "ADD_COURSE_SESSION_FAILURE";
export const FETCH_COURSE_PACKAGES_BEGIN = "FETCH_COURSE_PACKAGES_BEGIN";
export const FETCH_COURSE_PACKAGES_SUCCESS = "FETCH_COURSE_PACKAGES_SUCCESS";
export const FETCH_COURSE_PACKAGES_FAILURE = "FETCH_COURSE_PACKAGES_FAILURE";
export const FETCH_COURSES_FILTER_DATA_BEGIN =
  "FETCH_COURSES_FILTER_DATA_BEGIN";
export const FETCH_COURSES_FILTER_DATA_SUCCESS =
  "FETCH_COURSES_FILTER_DATA_SUCCESS";
export const FETCH_COURSES_FILTER_DATA_FAILURE =
  "FETCH_COURSES_FILTER_DATA_FAILURE";
export const ADD_ITEM_INTO_SHORT_COURSE_BEGIN =
  "ADD_ITEM_INTO_SHORT_COURSE_BEGIN";
export const ADD_ITEM_INTO_SHORT_COURSE_SUCCESS =
  "ADD_ITEM_INTO_SHORT_COURSE_SUCCESS";
export const ADD_ITEM_INTO_SHORT_COURSE_FAILURE =
  "ADD_ITEM_INTO_SHORT_COURSE_FAILURE";
export const ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_BEGIN =
  "ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_BEGIN";
export const ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_SUCCESS =
  "ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_SUCCESS";
export const ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_FAILURE =
  "ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_FAILURE";
export const UPDATE_COURSE_SETTINGS_BEGIN = "UPDATE_COURSE_SETTINGS_BEGIN";
export const UPDATE_COURSE_SETTINGS_SUCCESS = "UPDATE_COURSE_SETTINGS_SUCCESS";
export const UPDATE_COURSE_SETTINGS_FAILURE = "UPDATE_COURSE_SETTINGS_FAILURE";
export const UPDATE_COURSE_PREREQS_BEGIN = "UPDATE_COURSE_PREREQS_BEGIN";
export const UPDATE_COURSE_PREREQS_SUCCESS = "UPDATE_COURSE_PREREQS_SUCCESS";
export const UPDATE_COURSE_PREREQS_FAILURE = "UPDATE_COURSE_PREREQS_FAILURE";
export const FETCH_COURSE_EXCLUSIONS_BEGIN = "FETCH_COURSE_EXCLUSIONS_BEGIN";
export const FETCH_COURSE_EXCLUSIONS_SUCCESS =
  "FETCH_COURSE_EXCLUSIONS_SUCCESS";
export const FETCH_COURSE_EXCLUSIONS_FAILURE =
  "FETCH_COURSE_EXCLUSIONS_FAILURE";
export const FETCH_COURSE_PREREQS_BEGIN = "FETCH_COURSE_PREREQS_BEGIN";
export const FETCH_COURSE_PREREQS_SUCCESS = "FETCH_COURSE_PREREQS_SUCCESS";
export const FETCH_COURSE_PREREQS_FAILURE = "FETCH_COURSE_PREREQS_FAILURE";
export const FETCH_COURSE_SECTIONS_BEGIN = "FETCH_COURSE_SECTIONS_BEGIN";
export const FETCH_COURSE_SECTIONS_SUCCESS = "FETCH_COURSE_SECTIONS_SUCCESS";
export const FETCH_COURSE_SECTIONS_FAILURE = "FETCH_COURSE_SECTIONS_FAILURE";
export const ADD_COURSE_SECTION_BEGIN = "ADD_COURSE_SECTION_BEGIN";
export const ADD_COURSE_SECTION_SUCCESS = "ADD_COURSE_SECTION_SUCCESS";
export const ADD_COURSE_SECTION_FAILURE = "ADD_COURSE_SECTION_FAILURE";
export const UPDATE_COURSE_SECTION_BEGIN = "UPDATE_COURSE_SECTION_BEGIN";
export const UPDATE_COURSE_SECTION_SUCCESS = "UPDATE_COURSE_SECTION_SUCCESS";
export const UPDATE_COURSE_SECTION_FAILURE = "UPDATE_COURSE_SECTION_FAILURE";
export const DELETE_COURSE_SECTION_BEGIN = "DELETE_COURSE_SECTION_BEGIN";
export const DELETE_COURSE_SECTION_SUCCESS = "DELETE_COURSE_SECTION_SUCCESS";
export const DELETE_COURSE_SECTION_FAILURE = "DELETE_COURSE_SECTION_FAILURE";
export const FETCH_CUSTOMER_ILT_DETAILS_BEGIN =
  "FETCH_CUSTOMER_ILT_DETAILS_BEGIN";
export const FETCH_CUSTOMER_ILT_DETAILS_SUCCESS =
  "FETCH_CUSTOMER_ILT_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_ILT_DETAILS_FAILURE =
  "FETCH_CUSTOMER_ILT_DETAILS_FAILURE";
export const FETCH_COURSE_ENROLLMENTS_BEGIN = "FETCH_COURSE_ENROLLMENTS_BEGIN";
export const FETCH_COURSE_ENROLLMENTS_SUCCESS =
  "FETCH_COURSE_ENROLLMENTS_SUCCESS";
export const FETCH_COURSE_ENROLLMENTS_FAILURE =
  "FETCH_COURSE_ENROLLMENTS_FAILURE";
export const UPDATE_COURSE_ENROLLMENTS_BEGIN =
  "UPDATE_COURSE_ENROLLMENTS_BEGIN";
export const UPDATE_COURSE_ENROLLMENTS_SUCCESS =
  "UPDATE_COURSE_ENROLLMENTS_SUCCESS";
export const UPDATE_COURSE_ENROLLMENTS_FAILURE =
  "UPDATE_COURSE_ENROLLMENTS_FAILURE";
export const UPDATE_COURSE_EXCLUSIONS_BEGIN = "UPDATE_COURSE_EXCLUSIONS_BEGIN";
export const UPDATE_COURSE_EXCLUSIONS_SUCCESS =
  "UPDATE_COURSE_EXCLUSIONS_SUCCESS";
export const UPDATE_COURSE_EXCLUSIONS_FAILURE =
  "UPDATE_COURSE_EXCLUSIONS_FAILURE";
export const FETCH_VC_ATTENDEES_BEGIN = "FETCH_VC_ATTENDEES_BEGIN";
export const FETCH_VC_ATTENDEES_SUCCESS = "FETCH_VC_ATTENDEES_SUCCESS";
export const FETCH_VC_ATTENDEES_FAILURE = "FETCH_VC_ATTENDEES_FAILURE";
export const FETCH_SESSION_ROSTER_BEGIN = "FETCH_SESSION_ROSTER_BEGIN";
export const FETCH_SESSION_ROSTER_SUCCESS = "FETCH_SESSION_ROSTER_SUCCESS";
export const FETCH_SESSION_ROSTER_FAILURE = "FETCH_SESSION_ROSTER_FAILURE";
export const UPDATE_SESSION_ROSTER_BEGIN = "UPDATE_SESSION_ROSTER_BEGIN";
export const UPDATE_SESSION_ROSTER_SUCCESS = "UPDATE_SESSION_ROSTER_SUCCESS";
export const UPDATE_SESSION_ROSTER_FAILURE = "UPDATE_SESSION_ROSTER_FAILURE";

const coursesApiService = async () =>
  new JsonApiService("courses", {
    baseUrl: `${visionURL}/admin_console/api/content`,
    credentials: "include"
  });

export const addCourseBegin = payload => ({
  type: ADD_COURSE_BEGIN,
  payload
});

export const addCourseSuccess = course => ({
  type: ADD_COURSE_SUCCESS,
  payload: { course }
});

export const addCourseFailure = error => ({
  type: ADD_COURSE_FAILURE,
  payload: { error }
});

export const fetchCoursesBegin = () => ({
  type: FETCH_COURSES_BEGIN
});

export const fetchCoursesSuccess = payload => ({
  type: FETCH_COURSES_SUCCESS,
  payload
});

export const fetchCoursesFailure = error => ({
  type: FETCH_COURSES_FAILURE,
  payload: { error }
});

export const fetchCourseVersionsDataBegin = () => ({
  type: FETCH_COURSE_VERSIONS_BEGIN
});

export const fetchCourseVersionsDataSuccess = payload => ({
  type: FETCH_COURSE_VERSIONS_SUCCESS,
  payload
});

export const fetchCourseVersionsDataFailure = error => ({
  type: FETCH_COURSE_VERSIONS_FAILURE,
  payload: { error }
});

export const fetchCoursesFilterDataBegin = () => ({
  type: FETCH_COURSES_FILTER_DATA_BEGIN
});

export const fetchCoursesFilterDataSuccess = contentPackages => ({
  type: FETCH_COURSES_FILTER_DATA_SUCCESS,
  payload: contentPackages
});

export const fetchCoursesFilterDataFailure = error => ({
  type: FETCH_COURSES_FILTER_DATA_FAILURE,
  payload: { error }
});

export const addCourseVersionBegin = () => ({
  type: ADD_COURSE_VERSION_BEGIN
});

export const addCourseVersionSuccess = payload => ({
  type: ADD_COURSE_VERSION_SUCCESS,
  payload
});

export const addCourseVersionFailure = error => ({
  type: ADD_COURSE_VERSION_FAILURE,
  payload: { error }
});

export const addItemIntoShortCourseBegin = () => ({
  type: ADD_ITEM_INTO_SHORT_COURSE_BEGIN
});

export const addItemIntoShortCourseSuccess = courseVersionsData => ({
  type: ADD_ITEM_INTO_SHORT_COURSE_SUCCESS,
  payload: courseVersionsData
});

export const addItemIntoShortCourseFailure = error => ({
  type: ADD_ITEM_INTO_SHORT_COURSE_FAILURE,
  payload: { error }
});

export const addRepositoryItemsIntoShortCourseBegin = () => ({
  type: ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_BEGIN
});

export const addRepositoryItemsIntoShortCourseSuccess = courseVersionsData => ({
  type: ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_SUCCESS,
  payload: courseVersionsData
});

export const addRepositoryItemsIntoShortCourseFailure = error => ({
  type: ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_FAILURE,
  payload: { error }
});

export const fetchCoursePackagesBegin = () => ({
  type: FETCH_COURSE_PACKAGES_BEGIN
});

export const fetchCoursePackagesSuccess = coursePackages => ({
  type: FETCH_COURSE_PACKAGES_SUCCESS,
  payload: coursePackages
});

export const fetchCoursePackagesFailure = error => ({
  type: FETCH_COURSE_PACKAGES_FAILURE,
  payload: { error }
});

export const updateCourseSettingsBegin = () => ({
  type: UPDATE_COURSE_SETTINGS_BEGIN
});

export const updateCourseSettingsSuccess = payload => ({
  type: UPDATE_COURSE_SETTINGS_SUCCESS,
  payload
});

export const updateCourseSettingsFailure = error => ({
  type: UPDATE_COURSE_SETTINGS_FAILURE,
  payload: { error }
});

export const updateCoursePrereqsBegin = () => ({
  type: UPDATE_COURSE_PREREQS_BEGIN
});

export const updateCoursePrereqsSuccess = (coursePrereqs, courseName) => ({
  type: UPDATE_COURSE_PREREQS_SUCCESS,
  payload: { coursePrereqs, courseName }
});

export const updateCoursePrereqsFailure = error => ({
  type: UPDATE_COURSE_PREREQS_FAILURE,
  payload: { error }
});

export const fetchCourseExclusionsBegin = () => ({
  type: FETCH_COURSE_EXCLUSIONS_BEGIN
});

export const fetchCourseExclusionsSuccess = exclusionsResponse => ({
  type: FETCH_COURSE_EXCLUSIONS_SUCCESS,
  payload: exclusionsResponse
});

export const fetchCourseExclusionsFailure = error => ({
  type: FETCH_COURSE_EXCLUSIONS_FAILURE,
  payload: { error }
});

export const updateCourseExclusionsBegin = () => ({
  type: UPDATE_COURSE_EXCLUSIONS_BEGIN
});

export const updateCourseExclusionsSuccess = exclusionsResponse => ({
  type: UPDATE_COURSE_EXCLUSIONS_SUCCESS,
  payload: exclusionsResponse
});

export const updateCourseExclusionsFailure = error => ({
  type: UPDATE_COURSE_EXCLUSIONS_FAILURE,
  payload: { error }
});

// TODO: Request body and response needs to be in JSON API spec format
export function addCourse(courseData) {
  return dispatch => {
    const apiUrl = `${visionURL}/admin_console/api/content/courses`;
    dispatch(addCourseBegin(courseData));
    const transformedCourseData = transformParamsToSnakeCase(courseData);
    return fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(transformedCourseData || {})
    })
      .then(response => {
        visionCallService.redirectForAuth(response);
        return response.json();
      })
      .then(function(response) {
        const parsedResponse = visionCallService.extractAttributes(response);
        dispatch(addCourseSuccess(parsedResponse));
        return parsedResponse;
      })
      .catch(function(error) {
        dispatch(addCourseFailure(error));
      });
  };
}

export function addOnlineCourse() {
  return dispatch => {
    const apiUrl = `${visionURL}/admin_console/api/content/courses`;
    dispatch(addCourseBegin());
    return fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ default: true })
    })
      .then(response => {
        visionCallService.redirectForAuth(response);
        return response.json();
      })
      .then(function(response) {
        const parsedResponse = dataFormatter.deserialize(response);
        dispatch(addCourseSuccess(parsedResponse));
        return parsedResponse;
      })
      .catch(function(error) {
        dispatch(addCourseFailure(error));
      });
  };
}

export const fetchCourses = (
  courseId,
  coursesFilters,
  tableQueryData
) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses`;
  if (courseId) {
    apiUrl = `${apiUrl}/${courseId}`;
  }
  apiUrl += `?${visionCallService.buildQueryString(coursesFilters)}`;
  apiUrl += visionCallService.buildTableDataQueryString(tableQueryData);

  dispatch(fetchCoursesBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchCoursesSuccess(response));
    },
    function(error) {
      dispatch(fetchCoursesFailure(error));
    }
  );
};

const updateCourseBegin = () => ({
  type: UPDATE_COURSE_BEGIN
});

const updateCourseSuccess = payload => ({
  type: UPDATE_COURSE_SUCCESS,
  payload
});

const updateCourseFailure = error => ({
  type: UPDATE_COURSE_FAILURE,
  payload: { error }
});

export const updateCourse = (
  courseId,
  updatedCourseData,
  successCallback,
  errorCallback
) => dispatch => {
  const apiUrl = `${visionURL}/admin_console/api/content/courses/${courseId}`;
  dispatch(updateCourseBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(updateCourseSuccess(response));
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
    },
    error => {
      dispatch(updateCourseFailure(error));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback(error);
      }
    },
    "PATCH",
    { ...updatedCourseData, type: "trainingObject" }
  );
};

export const fetchCourseVersionsData = (
  parentTrainingObjectId,
  queryData,
  coursesFilters
) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${parentTrainingObjectId}/versions?`;
  if (coursesFilters) {
    Object.keys(coursesFilters).forEach(key => {
      apiUrl += coursesFilters[key] ? `&${key}=${coursesFilters[key]}` : "";
    });
  }
  apiUrl += visionCallService.buildTableDataQueryString(queryData);

  dispatch(fetchCourseVersionsDataBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(
        fetchCourseVersionsDataSuccess({
          parentTrainingObjectId,
          ...response
        })
      );
    },
    function(error) {
      dispatch(fetchCourseVersionsDataFailure(error));
    }
  );
};

// TODO: Update this to use the /admin_console/api/courses endpoint
export const addCourseVersion = (
  parentTrainingObjectId,
  newVersionData,
  successCallback,
  errorCallback
) => dispatch => {
  dispatch(addCourseVersionBegin());

  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses/${parentTrainingObjectId}/versions`,
    function(response) {
      dispatch(
        addCourseVersionSuccess({
          parentTrainingObjectId,
          ...response
        })
      );
      successCallback(response.version);
    },
    function(error) {
      dispatch(addCourseVersionFailure(error));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback(error);
      }
    },
    "POST",
    { type: "version", ...newVersionData }
  );
};

export const fetchCourseSessionBegin = () => ({
  type: FETCH_COURSE_SESSION_BEGIN
});

export const fetchCourseSessionSuccess = payload => ({
  type: FETCH_COURSE_SESSION_SUCCESS,
  payload
});

export const fetchCourseSessionFailure = error => ({
  type: FETCH_COURSE_SESSION_FAILURE,
  payload: { error }
});

/**
 * TODO: since we decided to return the session type back as "trainingObject",
 * at some point we should probably rename this to fetchCourseVersion and then use this
 * method for both fetching a course "session" as well as a "version" since the type for both
 * should be "trainingObject" to keep things consistent.
 **/
export const fetchCourseSession = trainingObjectId => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${trainingObjectId}?include=courseSections.activities`;
  dispatch(fetchCourseSessionBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchCourseSessionSuccess(response));
    },
    function(error) {
      dispatch(fetchCourseVersionsDataFailure(error));
    }
  );
};

export const addCourseSessionBegin = () => ({
  type: ADD_COURSE_SESSION_BEGIN
});

export const addCourseSessionSuccess = courseVersionsData => ({
  type: ADD_COURSE_SESSION_SUCCESS,
  payload: courseVersionsData
});

export const addCourseSessionFailure = error => ({
  type: ADD_COURSE_SESSION_FAILURE,
  payload: { error }
});

/**
 * TODO: After addCourseVersion is updated to use the /admin_console/api/courses endpoint,
 * use the addCourseVersion instead of addCourseSession
 **/
export const addCourseSession = (
  parentId,
  newVersionData,
  successCallback,
  errorCallback
) => dispatch => {
  dispatch(addCourseSessionBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses`,
    response => {
      successCallback(response.trainingObject);
      dispatch(addCourseSessionSuccess(response));
    },
    error => {
      dispatch(addCourseSessionFailure(error));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback(error);
      }
    },
    "POST",
    { ...newVersionData, parentId, type: "trainingObject" }
  );
};

const fetchCourseSectionsBegin = () => ({
  type: FETCH_COURSE_SECTIONS_BEGIN
});

const fetchCourseSectionsSuccess = payload => ({
  type: FETCH_COURSE_SECTIONS_SUCCESS,
  payload
});

const fetchCourseSectionsFailure = error => ({
  type: FETCH_COURSE_SECTIONS_FAILURE,
  payload: { error }
});

export const fetchCourseSections = versionId => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${versionId}/course_sections`;
  dispatch(fetchCourseSectionsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(
        fetchCourseSectionsSuccess({
          versionId,
          ...response
        })
      );
    },
    function(error) {
      dispatch(fetchCourseSectionsFailure(error));
    }
  );
};

const updateCourseSectionBegin = () => ({
  type: UPDATE_COURSE_SECTION_BEGIN
});

const updateCourseSectionSuccess = payload => ({
  type: UPDATE_COURSE_SECTION_SUCCESS,
  payload
});

const updateCourseSectionFailure = error => ({
  type: UPDATE_COURSE_SECTION_FAILURE,
  payload: { error }
});

export const updateCourseSection = (
  versionId,
  shortCourseId,
  shortCourseData
) => dispatch => {
  dispatch(updateCourseSectionBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses/${versionId}/course_sections/${shortCourseId}`,
    function(response) {
      dispatch(updateCourseSectionSuccess(response));
    },
    function(error) {
      dispatch(updateCourseSectionFailure(error));
    },
    "PATCH",
    shortCourseData
  );
};

export const addCourseSectionBegin = () => ({
  type: ADD_COURSE_SECTION_BEGIN
});

export const addCourseSectionSuccess = payload => ({
  type: ADD_COURSE_SECTION_SUCCESS,
  payload
});

export const addCourseSectionFailure = error => ({
  type: ADD_COURSE_SECTION_FAILURE,
  payload: { error }
});

// TODO: request body should follow JSON API spec
export const addCourseSection = (versionId, shortCourseData) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${versionId}/course_sections`;
  dispatch(addCourseSectionBegin());
  fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(shortCourseData)
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      const data = normalize(response);
      dispatch(addCourseSectionSuccess({ versionId, ...data }));
      return data;
    })
    .catch(
      error => {
        dispatch(addCourseSectionFailure(error));
      },
      "POST",
      { type: "courseSection", ...shortCourseData }
    );
};

const deleteCourseSectionBegin = () => ({
  type: DELETE_COURSE_SECTION_BEGIN
});

const deleteCourseSectionSuccess = payload => ({
  type: DELETE_COURSE_SECTION_SUCCESS,
  payload
});

const deleteCourseSectionFailure = error => ({
  type: DELETE_COURSE_SECTION_FAILURE,
  payload: { error }
});

/**
 * TODO: Backend needs to be updated to not include a response body per the JSON API spec.
 * Once it's updated, we should update the action below to not expect a response.
 **/
export const deleteCourseSection = (versionId, courseSectionId) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${versionId}/course_sections/${courseSectionId}`;
  dispatch(deleteCourseSectionBegin());
  return fetch(apiUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      const shortCourses = visionCallService.extractAttributes(response);
      dispatch(deleteCourseSectionSuccess({ courseSectionId, versionId }));
      return shortCourses;
    })
    .catch(error => {
      dispatch(deleteCourseSectionFailure(error));
    });
};

// TODO: request body should follow JSON API spec
export const addItemIntoShortCourse = (
  versionId,
  shortCourseId,
  type,
  itemData
) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${versionId}/course_sections/${shortCourseId}/activities?type=${type}&effect=create`;
  dispatch(addItemIntoShortCourseBegin());
  fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(itemData)
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      const data = normalize(response);
      dispatch(addItemIntoShortCourseSuccess({ versionId, ...data }));
      return data;
    })
    .catch(error => {
      dispatch(addItemIntoShortCourseFailure(error));
    });
};

// TODO: request body should follow JSON API spec
export const addRepositoryItemsIntoShortCourse = (
  versionId,
  shortCourseId,
  repositoryItemsData
) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${versionId}/course_sections/${shortCourseId}/activities?type=repository_items&effect=add`;
  dispatch(addRepositoryItemsIntoShortCourseBegin());
  fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(repositoryItemsData)
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      const data = normalize(response);
      dispatch(
        addRepositoryItemsIntoShortCourseSuccess({ versionId, ...data })
      );
      return data;
    })
    .catch(error => {
      dispatch(addRepositoryItemsIntoShortCourseFailure(error));
    });
};

export const fetchCoursePackages = (type = "course") => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/course_packages?type=${type}`;
  dispatch(fetchCoursePackagesBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchCoursePackagesSuccess(response));
    },
    function(error) {
      dispatch(fetchCoursePackagesFailure(error));
    }
  );
};

export function fetchCoursesFilterData(type = "course") {
  return dispatch => {
    dispatch(fetchCoursesFilterDataBegin());
    const url = `${visionURL}/admin_console/api/content/courses_filter_data?type=${type}`;
    //const mockUrl = `https://api-mock.ghdev.us/v2/5ded53bfdc0000ce85d688a4`;
    return fetch(url, {
      method: "GET",
      credentials: "include"
    })
      .then(response => {
        visionCallService.redirectForAuth(response);
        return response.json();
      })
      .then(function(response) {
        if (response.error) {
          return dispatch(fetchCoursesFilterDataFailure(response));
        } else {
          const coursesFilterData = visionCallService.extractAttributes(
            response
          );
          return dispatch(fetchCoursesFilterDataSuccess(coursesFilterData));
        }
      });
  };
}

export const FETCH_COURSE_SETTINGS_DATA_BEGIN = "FETCH_COURSE_SETTINGS_BEGIN";
export const FETCH_COURSE_SETTINGS_DATA_SUCCESS =
  "FETCH_COURSE_SETTINGS_SUCCESS";
export const FETCH_COURSE_SETTINGS_DATA_FAILURE =
  "FETCH_COURSE_SETTINGS_FAILURE";

export const fetchCourseSettingsDataBegin = () => ({
  type: FETCH_COURSE_SETTINGS_DATA_BEGIN
});

export const fetchCourseSettingsDataSuccess = courseSettings => ({
  type: FETCH_COURSE_SETTINGS_DATA_SUCCESS,
  payload: courseSettings
});

export const fetchCourseSettingsDataFailure = error => ({
  type: FETCH_COURSE_SETTINGS_DATA_FAILURE,
  payload: { error }
});

export const fetchCourseSettings = courseId => dispatch => {
  const apiUrl = `${visionURL}/admin_console/api/content/courses/${courseId}/settings`;
  dispatch(fetchCourseSettingsDataBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchCourseSettingsDataSuccess(response));
    },
    function(error) {
      dispatch(fetchCourseSettingsDataFailure(error));
    }
  );
};

export const updateCourseSettings = (
  courseId,
  courseSettingsData,
  successCallback,
  errorCallback
) => dispatch => {
  dispatch(updateCourseSettingsBegin());
  const apiUrl = `${visionURL}/admin_console/api/content/courses/${courseId}/settings`;
  if (
    courseSettingsData &&
    courseSettingsData.tags &&
    Array.isArray(courseSettingsData.tags)
  ) {
    courseSettingsData.tags = JSON.stringify(courseSettingsData.tags);
  }
  let data = new FormData();
  Object.keys(courseSettingsData).forEach(x =>
    data.append("data[attributes][" + x + "]", courseSettingsData[x])
  );
  return fetch(apiUrl, {
    method: "PUT",
    credentials: "include",
    body: data
  })
    .then(visionCallService.checkStatus)
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(function(response) {
      if (response.errors) throw response;
      const normalizedResponse = normalize(response);
      dispatch(updateCourseSettingsSuccess(normalizedResponse));
      if (successCallback && typeof successCallback === "function")
        successCallback();
      return response;
    })
    .catch(function(error) {
      dispatch(updateCourseSettingsFailure(error));
      if (errorCallback && typeof errorCallback === "function")
        errorCallback(error);
      console.log(error);
      return error;
    });
};

export const fetchCoursePrereqsBegin = () => ({
  type: FETCH_COURSE_PREREQS_BEGIN
});

export const fetchCoursePrereqsSuccess = (prereqs, courseName) => ({
  type: FETCH_COURSE_PREREQS_SUCCESS,
  payload: { prereqs, courseName }
});

export const fetchCoursePrereqsFailure = error => ({
  type: FETCH_COURSE_PREREQS_FAILURE,
  payload: { error }
});

export const fetchCoursePrereqs = (courseId, filters) => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/content/courses/${courseId}/prerequisites?`;
  if (filters) {
    Object.keys(filters).forEach(key => {
      apiUrl += filters[key] ? `&${key}=${filters[key]}` : "";
    });
  }
  dispatch(fetchCoursePrereqsBegin());
  fetch(apiUrl, {
    method: "GET",
    credentials: "include"
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      const courseName = response.meta && response.meta.course_name;
      const prereqs = visionCallService.extractAttributes(response);
      dispatch(fetchCoursePrereqsSuccess(prereqs, courseName));
      return prereqs;
    })
    .catch(error => {
      dispatch(fetchCoursePrereqsFailure(error));
      console.log(error);
    });
};

export const updateCoursePrereqs = (
  courseId,
  coursePrereqsData,
  successCallback,
  errorCallback
) => dispatch => {
  const apiUrl = `${visionURL}/admin_console/api/content/courses/${courseId}/prerequisites`;
  dispatch(updateCoursePrereqsBegin());
  fetch(apiUrl, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(coursePrereqsData)
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(response => {
      const courseName = response.meta && response.meta.course_name;
      const coursePrereqs = visionCallService.extractAttributes(response);
      dispatch(updateCoursePrereqsSuccess(coursePrereqs, courseName));
      if (successCallback && typeof successCallback === "function")
        successCallback();
      return coursePrereqs;
    })
    .catch(error => {
      dispatch(updateCoursePrereqsFailure(error));
      if (errorCallback && typeof errorCallback === "function") errorCallback();
      console.log(error);
    });
};

const updateCourseVersionDataBegin = () => ({
  type: UPDATE_COURSE_VERSION_BEGIN
});

const updateCourseVersionDataSuccess = payload => ({
  type: UPDATE_COURSE_VERSION_SUCCESS,
  payload
});

const updateCourseVersionDataFailure = error => ({
  type: UPDATE_COURSE_VERSION_FAILURE,
  payload: { error }
});

export const updateCourseVersionData = (
  courseId,
  versionId,
  courseVersionData,
  successCallback,
  errorCallback
) => dispatch => {
  dispatch(updateCourseVersionDataBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses/${courseId}/versions/${versionId}`,
    function(response) {
      dispatch(updateCourseVersionDataSuccess(response));
      if (typeof successCallback === "function") {
        successCallback(response);
      }
    },
    function(error) {
      dispatch(updateCourseVersionDataFailure(error));
      if (typeof errorCallback === "function") {
        errorCallback(error.errors ? error.errors : error);
      }
    },
    "PUT",
    { type: "version", ...courseVersionData }
  );
};

const transformExclusionsResponse = (response, included) => ({
  exclusions: response,
  locations: included
    .filter(obj => obj.type === "location")
    .map(({ id, type, attributes: { name } }) => ({
      id,
      type,
      value: name
    })),
  groups: included
    .filter(obj => obj.type === "group")
    .map(({ id, type, attributes: { name } }) => ({
      id,
      type,
      value: name
    })),
  positions: included
    .filter(obj => obj.type === "position")
    .map(({ id, type, attributes: { name } }) => ({
      id,
      type,
      value: name
    }))
});

export const updateExclusions = (
  courseId,
  courseExclusionsData,
  successCallback,
  errorCallback
) => {
  return async dispatch => {
    const service = await coursesApiService();
    dispatch(updateCourseExclusionsBegin);
    const json = await service
      .createRelated(
        courseId,
        "exclusions",
        JSON.stringify(
          serialize({ ...courseExclusionsData, type: "courseExclusions" })
        )
      )
      .then(response => response.json());
    if (json.errors) {
      dispatch(fetchCourseExclusionsFailure(json.errors));
      if (errorCallback && typeof errorCallback === "function") {
        errorCallback();
      }
    } else {
      const transformedWithJsona = dataFormatter.deserialize(json);
      dispatch(
        updateCourseExclusionsSuccess(
          transformExclusionsResponse(transformedWithJsona, json.included)
        )
      );
      if (successCallback && typeof successCallback === "function") {
        successCallback(json);
      }
    }
  };
};

export const fetchCourseExclusions = courseId => {
  return async dispatch => {
    const service = await coursesApiService();
    dispatch(fetchCourseExclusionsBegin());
    const json = await service
      .related(courseId, "exclusions")
      .then(response => response.json());
    if (json.errors) {
      dispatch(fetchCourseExclusionsFailure(json.errors));
    } else {
      const transformedWithJsona = dataFormatter.deserialize(json);
      dispatch(
        fetchCourseExclusionsSuccess(
          transformExclusionsResponse(transformedWithJsona, json.included)
        )
      );
    }
  };
};

export const fetchCustomerIltDetailsBegin = () => ({
  type: FETCH_CUSTOMER_ILT_DETAILS_BEGIN
});

export const fetchCustomerIltDetailsSuccess = customerIltDetails => ({
  type: FETCH_CUSTOMER_ILT_DETAILS_SUCCESS,
  payload: customerIltDetails
});

export const fetchCustomerIltDetailsFailure = error => ({
  type: FETCH_CUSTOMER_ILT_DETAILS_FAILURE,
  payload: { error }
});

export const fetchCustomerIltDetails = () => dispatch => {
  const apiUrl = `${visionURL}/admin_console/api/content/customer_ilt_details`;
  dispatch(fetchCustomerIltDetailsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchCustomerIltDetailsSuccess(response));
    },
    function(error) {
      dispatch(fetchCustomerIltDetailsFailure(error));
    }
  );
};

export const fetchCourseEnrollmentsBegin = () => ({
  type: FETCH_COURSE_ENROLLMENTS_BEGIN
});

export const fetchCourseEnrollmentsSuccess = payload => ({
  type: FETCH_COURSE_ENROLLMENTS_SUCCESS,
  payload
});

export const fetchCourseEnrollmentsFailure = error => ({
  type: FETCH_COURSE_ENROLLMENTS_FAILURE,
  payload: { error }
});

export const fetchCourseEnrollments = courseId => dispatch => {
  const apiUrl = `${visionURL}/admin_console/api/content/courses/${courseId}/enrollments`;
  dispatch(fetchCourseEnrollmentsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(
        fetchCourseEnrollmentsSuccess({
          enrollments: response.enrollments,
          courseId
        })
      );
    },
    function(error) {
      dispatch(fetchCourseEnrollmentsFailure(error));
    }
  );
};

export const updateCourseEnrollmentsBegin = () => ({
  type: UPDATE_COURSE_ENROLLMENTS_BEGIN
});

export const updateCourseEnrollmentsSuccess = payload => ({
  type: UPDATE_COURSE_ENROLLMENTS_SUCCESS,
  payload
});

export const updateCourseEnrollmentsFailure = error => ({
  type: UPDATE_COURSE_ENROLLMENTS_FAILURE,
  payload: { error }
});

export const updateCourseEnrollments = (
  courseId,
  newEnrollmentsData
) => dispatch => {
  const apiUrl = `${visionURL}/admin_console/api/content/batch_enrollments/${courseId}`;
  dispatch(updateCourseEnrollmentsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      return dispatch(
        updateCourseEnrollmentsSuccess({
          enrollments: response.enrollments,
          courseId
        })
      );
    },
    function(error) {
      dispatch(updateCourseEnrollmentsFailure(error));
    },
    "PATCH",
    newEnrollmentsData
  );
};

export const CREATE_NEW_RELEASE_BEGIN = "CREATE_NEW_RELEASE_BEGIN";
export const CREATE_NEW_RELEASE_SUCCESS = "CREATE_NEW_RELEASE_SUCCESS";
export const CREATE_NEW_RELEASE_FAILURE = "CREATE_NEW_RELEASE_FAILURE";

const createReleaseBegin = () => ({
  type: CREATE_NEW_RELEASE_BEGIN
});

const createReleaseSuccess = payload => ({
  type: CREATE_NEW_RELEASE_SUCCESS,
  payload
});

const createReleaseFailure = error => ({
  type: CREATE_NEW_RELEASE_FAILURE,
  payload: { error }
});

export const createRelease = (courseId, versionId) => dispatch => {
  dispatch(createReleaseBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses/${courseId}/versions/${versionId}/pending_release`,
    response => dispatch(createReleaseSuccess(response)),
    error => dispatch(createReleaseFailure(error)),
    "POST"
  );
};

export const DELETE_ITEM_FROM_COURSE_BEGIN = "DELETE_ITEM_FROM_COURSE_BEGIN";
export const DELETE_ITEM_FROM_COURSE_SUCCESS =
  "DELETE_ITEM_FROM_COURSE_SUCCESS";
export const DELETE_ITEM_FROM_COURSE_FAILURE =
  "DELETE_ITEM_FROM_COURSE_FAILURE";

const deleteCourseActivityBegin = () => ({
  type: DELETE_ITEM_FROM_COURSE_BEGIN
});

const deleteCourseActivitySuccess = payload => ({
  type: DELETE_ITEM_FROM_COURSE_SUCCESS,
  payload
});

const deleteCourseActivityFailure = error => ({
  type: DELETE_ITEM_FROM_COURSE_FAILURE,
  payload: { error }
});

export const deleteCourseActivity = (
  courseId,
  activityId,
  courseSectionId
) => dispatch => {
  dispatch(deleteCourseActivityBegin());
  return visionCallService.deleteRequest(
    `${visionURL}/admin_console/api/content/courses/${courseId}/course_contentables/${activityId}`,
    response =>
      dispatch(deleteCourseActivitySuccess({ activityId, courseSectionId })),
    error => dispatch(deleteCourseActivityFailure(error))
  );
};

export const FETCH_COURSE_VERSION_DATA_BEGIN =
  "FETCH_COURSE_VERSION_DATA_BEGIN";
export const FETCH_COURSE_VERSION_DATA_SUCCESS =
  "FETCH_COURSE_VERSION_DATA_SUCCESS";
export const FETCH_COURSE_VERSION_DATA_FAILURE =
  "FETCH_COURSE_VERSION_DATA_FAILURE";

const fetchCourseVersionDataBegin = () => ({
  type: FETCH_COURSE_VERSION_DATA_BEGIN
});

const fetchCourseVersionDataSuccess = payload => ({
  type: FETCH_COURSE_VERSION_DATA_SUCCESS,
  payload
});

const fetchCourseVersionDataFailure = error => ({
  type: FETCH_COURSE_VERSION_DATA_FAILURE,
  payload: { error }
});

export const fetchCourseVersionData = (courseId, versionId) => dispatch => {
  dispatch(fetchCourseVersionDataBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses/${courseId}/versions/${versionId}`,
    response => dispatch(fetchCourseVersionDataSuccess(response)),
    error => dispatch(fetchCourseVersionDataFailure(error))
  );
};

export const fetchVirtualClassroomAttendeesBegin = () => ({
  type: FETCH_VC_ATTENDEES_BEGIN
});

export const fetchVirtualClassroomAttendeesSuccess = payload => ({
  type: FETCH_VC_ATTENDEES_SUCCESS,
  payload
});

export const fetchVirtualClassroomAttendeesFailure = payload => ({
  type: FETCH_VC_ATTENDEES_FAILURE,
  payload
});

export const fetchVirtualClassroomAttendees = trainingObjectId => {
  return async dispatch => {
    const service = await coursesApiService();
    dispatch(fetchVirtualClassroomAttendeesBegin());
    const json = await service
      .related(trainingObjectId, "virtual_classroom_attendees")
      .then(response => response.json());
    if (json.errors) {
      dispatch(fetchVirtualClassroomAttendeesFailure(json.errors));
    } else {
      dispatch(
        fetchVirtualClassroomAttendeesSuccess({
          trainingObjectId: `${trainingObjectId}`,
          virtualClassroomAttendee: normalize(json).virtualClassroomAttendee
        })
      );
    }
    return json;
  };
};

export const fetchSessionRosterBegin = () => ({
  type: FETCH_SESSION_ROSTER_BEGIN
});

export const fetchSessionRosterSuccess = payload => ({
  type: FETCH_SESSION_ROSTER_SUCCESS,
  payload
});

export const fetchSessionRosterFailure = payload => ({
  type: FETCH_SESSION_ROSTER_FAILURE,
  payload
});

export const fetchSessionRoster = trainingObjectId => {
  return async dispatch => {
    const service = await coursesApiService();
    dispatch(fetchSessionRosterBegin());
    const json = await service
      .related(trainingObjectId, "roster")
      .then(response => response.json());
    if (json.errors) {
      dispatch(fetchSessionRosterFailure(json.errors));
    } else {
      dispatch(
        fetchSessionRosterSuccess({
          courseId: `${trainingObjectId}`,
          roster: normalize(json).roster
        })
      );
    }
    return json;
  };
};

export const updateSessionRosterBegin = () => ({
  type: UPDATE_SESSION_ROSTER_BEGIN
});

export const updateSessionRosterSuccess = payload => ({
  type: UPDATE_SESSION_ROSTER_SUCCESS,
  payload
});

export const updateSessionRosterFailure = payload => ({
  type: UPDATE_SESSION_ROSTER_FAILURE,
  payload
});

/**
 * Uploads the roster file directly to AWS and then sends the file information to
 * @param {string} trainingObjectId - The training object ID that the roster is related to
 * @param {Object} roster - A file with the roster uploaded by the user
 * @param {string} roster.fileName - The file name for the roster
 * @param {string} roster.path - The path where the roster file is located in AWS
 * @param {string} roster.contentType - The roster file's content type
 */
export const updateSessionRoster = (courseId, roster) => {
  return async dispatch => {
    const service = await coursesApiService();
    dispatch(updateSessionRosterBegin());
    uploadFile(roster)
      .then(async uploadedRosterFile => {
        const json = await service
          .createRelated(
            courseId,
            "roster",
            visionCallService.serializeRequestBody(
              {
                ...uploadedRosterFile,
                courseId
              },
              "roster"
            )
          )
          .then(response => response.json());
        if (json.errors) {
          dispatch(updateSessionRosterFailure(json.errors));
        } else {
          dispatch(
            updateSessionRosterSuccess({
              courseId: `${courseId}`,
              roster: normalize(json).roster
            })
          );
        }
        return json;
      })
      .catch(error => {
        dispatch(updateSessionRosterFailure(error));
      });
  };
};

export const DELETE_RELEASE_BEGIN = "DELETE_RELEASE_BEGIN";
export const DELETE_RELEASE_SUCCESS = "DELETE_RELEASE_SUCCESS";
export const DELETE_RELEASE_FAILURE = "DELETE_RELEASE_FAILURE";

const deleteReleaseBegin = () => ({
  type: DELETE_RELEASE_BEGIN
});

const deleteReleaseSuccess = courseVersionData => ({
  type: DELETE_RELEASE_SUCCESS,
  payload: { courseVersionData }
});

const deleteReleaseFailure = error => ({
  type: DELETE_RELEASE_FAILURE,
  payload: { error }
});

export const deleteRelease = (
  courseId,
  versionId,
  successCallback
) => dispatch => {
  dispatch(deleteReleaseBegin());
  return visionCallService.requestWithNormalizer(
    `${visionURL}/admin_console/api/content/courses/${courseId}/versions/${versionId}/pending_release`,
    response => {
      dispatch(deleteReleaseSuccess(response));
      if (successCallback && typeof successCallback === "function") {
        successCallback();
      }
    },
    error => dispatch(deleteReleaseFailure(error)),
    "DELETE"
  );
};

export const VALIDATE_RELEASE_BEGIN = "VALIDATE_RELEASE_BEGIN";
export const VALIDATE_RELEASE_SUCCESS = "VALIDATE_RELEASE_SUCCESS";
export const VALIDATE_RELEASE_FAILURE = "VALIDATE_RELEASE_FAILURE";

const validateReleaseBegin = () => ({
  type: VALIDATE_RELEASE_BEGIN
});

const validateReleaseSuccess = courseVersionData => ({
  type: VALIDATE_RELEASE_SUCCESS,
  payload: { courseVersionData }
});

const validateReleaseFailure = error => ({
  type: VALIDATE_RELEASE_FAILURE,
  payload: { error }
});

export const validateRelease = (
  courseId,
  versionId,
  successCallback
) => dispatch => {
  dispatch(validateReleaseBegin());
  return visionCallService.fetchRequest(
    `${visionURL}/admin_console/api/content/courses/${courseId}/versions/${versionId}/pending_release`,
    response => {
      dispatch(validateReleaseSuccess(response));
      if (successCallback && typeof successCallback === "function") {
        successCallback(response);
      }
    },
    error => dispatch(validateReleaseFailure(error))
  );
};
