import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";

import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";
import { ActionCableProvider } from "react-actioncable-provider";
import App from "./App";
import "./i18n";
import "./index.scss";
import {
  adminConsoleRoot,
  learningCenterRoot,
  visionRoot,
  updatePassword,
  coursePreview,
  activityPreview
} from "./routes/Routes";
import { visionWSRootURL } from "./config/environment";
import ScrollToTop from "./utils/ScrollToTop";
import { AppProvider } from "./context/AppContext";
import { Box } from "@mui/material";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import "video.js/dist/video-js.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const store = configureStore();

// Lazy load components
const AdminContainer = lazy(() => import("./containers/Admin/Admin"));
const LearningCenterContainer = lazy(() =>
  import("./containers/LearningCenter/LearningCenter")
);
const MiscPagesContainer = lazy(() =>
  import("./containers/MiscPages/MiscPagesContainer")
);
const UpdatePassword = lazy(() =>
  import("./containers/UpdatePassword/UpdatePassword")
);
const App404Component = lazy(() =>
  import("./components/App404Component/App404Component")
);
const PreviewContainer = lazy(() =>
  import("./views/CoursePreview/PreviewContainer")
);

const authenticatedRoutes = () => {
  console.log(`----- USER_AUTHENTICATED -----`);
  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <ActionCableProvider url={visionWSRootURL}>
        <ConnectedRouter history={history}>
          <AppProvider>
            <App>
              <ScrollToTop />
              <Suspense
                fallback={
                  <Box
                    sx={{
                      top: "calc(50vh - 90px)",
                      left: "calc(50vw - 40px)",
                      position: "absolute"
                    }}
                  >
                    <LoadingSpinner visible />
                  </Box>
                }
              >
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={() => <Redirect to={learningCenterRoot} />}
                    key="default-route"
                  />
                  <Route path={adminConsoleRoot} component={AdminContainer} />
                  <Route
                    path={learningCenterRoot}
                    component={LearningCenterContainer}
                  />
                  <Route path={updatePassword} component={UpdatePassword} />
                  <Route
                    path={[activityPreview, coursePreview]}
                    component={PreviewContainer}
                  />
                  <Route path={visionRoot} component={MiscPagesContainer} />
                  <Route component={App404Component} />
                </Switch>
              </Suspense>
            </App>
          </AppProvider>
        </ConnectedRouter>
      </ActionCableProvider>
    </Provider>
  );
};

authenticatedRoutes();
