import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_WIDGETS_BEGIN = "FETCH_WIDGETS_BEGIN";
export const FETCH_WIDGETS_SUCCESS = "FETCH_WIDGETS_SUCCESS";
export const FETCH_WIDGETS_FAILURE = "FETCH_WIDGETS_FAILURE";

export const UPDATE_WIDGETS_BEGIN = "UPDATE_WIDGETS_BEGIN";
export const UPDATE_WIDGETS_SUCCESS = "UPDATE_WIDGETS_SUCCESS";
export const UPDATE_WIDGETS_FAILURE = "UPDATE_WIDGETS_FAILURE";

export const UPDATE_WIDGETS_LAYOUT_BEGIN = "UPDATE_WIDGETS_LAYOUT_BEGIN";
export const UPDATE_WIDGETS_LAYOUT_SUCCESS = "UPDATE_WIDGETS_LAYOUT_SUCCESS";
export const UPDATE_WIDGETS_LAYOUT_FAILURE = "UPDATE_WIDGETS_LAYOUT_FAILURE";

export const CREATE_CUSTOM_WIDGET_BEGIN = "CREATE_CUSTOM_WIDGET_BEGIN";
export const CREATE_CUSTOM_WIDGET_SUCCESS = "CREATE_CUSTOM_WIDGET_SUCCESS";
export const CREATE_CUSTOM_WIDGET_FAILURE = "CREATE_CUSTOM_WIDGET_FAILURE";

export const UPDATE_CUSTOM_WIDGET_BEGIN = "UPDATE_CUSTOM_WIDGET_BEGIN";
export const UPDATE_CUSTOM_WIDGET_SUCCESS = "UPDATE_CUSTOM_WIDGET_SUCCESS";
export const UPDATE_CUSTOM_WIDGET_FAILURE = "UPDATE_CUSTOM_WIDGET_FAILURE";

export const UPDATE_WIDGET_HEIGHT = "UPDATE_WIDGET_HEIGHT";
export const SET_EDIT_WIDGET_MODE = "SET_EDIT_WIDGET_MODE";

export const updateWidgetHeight = (widgetHeight, widgetName) => ({
  type: UPDATE_WIDGET_HEIGHT,
  payload: { widgetHeight, widgetName }
});

export const updateWidgetsLayoutBegin = widgets => ({
  type: UPDATE_WIDGETS_LAYOUT_BEGIN,
  payload: { widgets }
});

export const updateWidgetsLayoutSuccess = widgets => ({
  type: UPDATE_WIDGETS_LAYOUT_SUCCESS,
  payload: { widgets }
});

export const updateWidgetsLayoutFailure = error => ({
  type: UPDATE_WIDGETS_LAYOUT_FAILURE,
  payload: { error }
});

export const updateWidgetsBegin = widgets => ({
  type: UPDATE_WIDGETS_BEGIN,
  payload: { widgets }
});

export const updateWidgetsSuccess = widgets => ({
  type: UPDATE_WIDGETS_SUCCESS,
  payload: { widgets }
});

export const updateWidgetsFailure = error => ({
  type: UPDATE_WIDGETS_FAILURE,
  payload: { error }
});

export const fetchWidgetsBegin = () => ({
  type: FETCH_WIDGETS_BEGIN
});

export const fetchWidgetsSuccess = widgets => ({
  type: FETCH_WIDGETS_SUCCESS,
  payload: { widgets }
});

export const fetchWidgetsFailure = error => ({
  type: FETCH_WIDGETS_FAILURE,
  payload: { error }
});

export const fetchWidgetsDataBegin = () => ({
  type: FETCH_WIDGETS_BEGIN
});

export const fetchWidgetsDataSuccess = widgetData => ({
  type: FETCH_WIDGETS_SUCCESS,
  payload: { widgetData }
});

export const fetchWidgetsDataFailure = error => ({
  type: FETCH_WIDGETS_FAILURE,
  payload: { error }
});

export const createCustomWidgetBegin = () => ({
  type: CREATE_CUSTOM_WIDGET_BEGIN
});

export const createCustomWidgetSuccess = widget => ({
  type: CREATE_CUSTOM_WIDGET_SUCCESS,
  payload: { widget }
});

export const createCustomWidgetFailure = error => ({
  type: CREATE_CUSTOM_WIDGET_FAILURE,
  payload: { error }
});

export const updateCustomWidgetBegin = () => ({
  type: UPDATE_CUSTOM_WIDGET_BEGIN
});

export const updateCustomWidgetSuccess = widget => ({
  type: UPDATE_CUSTOM_WIDGET_SUCCESS,
  payload: { widget }
});

export const updateCustomWidgetFailure = error => ({
  type: UPDATE_CUSTOM_WIDGET_FAILURE,
  payload: { error }
});

export const setEditWidgetMode = editWidgetMode => ({
  type: SET_EDIT_WIDGET_MODE,
  payload: editWidgetMode
});

export function createCustomWidget(widget) {
  return dispatch => {
    dispatch(createCustomWidgetBegin());
    postNewCustomWidget(
      { widget },
      widget => {
        dispatch(createCustomWidgetSuccess(widget));
      },
      error => dispatch(createCustomWidgetFailure(error))
    );
  };
}

export function updateCustomWidget(widget, dashboard) {
  return dispatch => {
    dispatch(updateCustomWidgetBegin());
    putCustomWidget(
      { widget, dashboard },
      widget => {
        dispatch(updateCustomWidgetSuccess(widget));
      },
      error => dispatch(updateCustomWidgetFailure(error))
    );
  };
}

export function updateWidgetsLayout(widgets, dashboard) {
  return dispatch => {
    const apiUrl = `${visionURL}/learning_center/api/widget_layout_adjustments?type=${dashboard}`;
    dispatch(updateWidgetsLayoutBegin(widgets));
    return postWidgets(
      apiUrl,
      { widgets },
      widgets => {
        dispatch(updateWidgetsLayoutSuccess(widgets));
      },
      error => dispatch(updateWidgetsLayoutFailure(error))
    );
  };
}

export function updateWidgets(widgets, dashboard) {
  return dispatch => {
    const apiUrl = `${visionURL}/learning_center/api/widget_configs?type=${dashboard}`;
    dispatch(updateWidgetsBegin(widgets));
    return postWidgets(
      apiUrl,
      { widgets },
      widgets => {
        dispatch(updateWidgetsSuccess(widgets));
      },
      error => dispatch(updateWidgetsFailure(error))
    );
  };
}

export function deleteWidget(widgetId, dashboard) {
  return (dispatch, getState) => {
    const widgets = getState().widgetsState.widgets.map(widget => {
      if (widget.key === widgetId) {
        return {
          ...widget,
          added: false,
          hidden: true
        };
      } else {
        return widget;
      }
    });

    const apiUrl = `${visionURL}/learning_center/api/widget_configs?type=${dashboard}`;
    dispatch(updateWidgetsBegin());
    return postWidgets(
      apiUrl,
      { widgets },
      widgets => {
        dispatch(updateWidgetsSuccess(widgets));
      },
      error => dispatch(updateWidgetsFailure(error))
    );
  };
}

export function fetchWidgets(dashboard) {
  return dispatch => {
    dispatch(fetchWidgetsBegin());
    visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/widgets?type=${dashboard}`,
      function(widgets) {
        return dispatch(fetchWidgetsSuccess(widgets));
      },
      function(response) {
        return dispatch(fetchWidgetsFailure(response));
      }
    );
  };
}

function postWidgets(apiUrl, widgetsData, successCallback, errorCallback) {
  return visionCallService.postData(
    widgetsData,
    apiUrl,
    successCallback,
    errorCallback
  );
}

function postNewCustomWidget({ widget }, successCallback, errorCallback) {
  const apiUrl = `${visionURL}/learning_center/api/widgets`;
  return visionCallService.postSerializedData(
    "widgets",
    widget,
    apiUrl,
    successCallback,
    errorCallback
  );
}

function putCustomWidget(
  { widget, dashboard },
  successCallback,
  errorCallback
) {
  const apiUrl = `${visionURL}/learning_center/api/widgets/${widget.id}?type=${dashboard}`;
  return visionCallService.postSerializedData(
    "widgets",
    widget,
    apiUrl,
    successCallback,
    errorCallback,
    "PUT"
  );
}
