const INITIAL_STATE = {
  error: null
};

function ErrorReducer(state = INITIAL_STATE, action) {
  if (action.payload) {
    const { error } = action.payload;
    if (error) {
      return { ...state, error: error };
    }
  }
  return state;
}

export default ErrorReducer;
