import { merge } from "lodash";
import { fromJS, updateIn } from "immutable";
import {
  FETCH_QUIZZES_BEGIN,
  FETCH_QUIZZES_SUCCESS,
  FETCH_QUIZZES_FAILURE,
  CREATE_QUIZ_BEGIN,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAILURE,
  UPDATE_QUIZ_BEGIN,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILURE,
  CREATE_QUESTIONS_BEGIN,
  CREATE_QUESTIONS_SUCCESS,
  CREATE_QUESTIONS_FAILURE,
  UPDATE_QUESTIONS_BEGIN,
  UPDATE_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_FAILURE,
  UPDATE_QUIZ_QUESTIONS_BEGIN,
  UPDATE_QUIZ_QUESTIONS_SUCCESS,
  UPDATE_QUIZ_QUESTIONS_FAILURE,
  UPDATE_QUIZ_QUESTION_BEGIN,
  UPDATE_QUIZ_QUESTION_SUCCESS,
  UPDATE_QUIZ_QUESTION_FAILURE,
  IMPORT_QUIZ_QUESTIONS_SUCCESS
} from "../actions/contentQuizActions";
import {
  mergeRelatedResources,
  replaceRelatedResources
} from "../utils/reducerHelpers";

const INITIAL_STATE = {
  quiz: {}
};
function contentQuizzesReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_QUIZZES_BEGIN:
    case CREATE_QUIZ_BEGIN:
    case UPDATE_QUIZ_BEGIN:
    case UPDATE_QUESTIONS_BEGIN:
    case UPDATE_QUIZ_QUESTIONS_BEGIN:
    case UPDATE_QUIZ_QUESTION_BEGIN:
    case CREATE_QUESTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_QUIZZES_SUCCESS:
    case CREATE_QUIZ_SUCCESS:
    case UPDATE_QUIZ_SUCCESS:
      return {
        ...state,
        quizzes: payload.quizzes,
        questions: payload.questions,
        answerChoice: payload.answerChoice,
        fibAnswer: payload.fibAnswer,
        fibElement: payload.fibElement,
        matchChoice: payload.matchChoice,
        matchClue: payload.matchClue,
        associatedCourses: payload.associatedCourses,
        item: payload.item,
        loading: false,
        error: null
      };
    case UPDATE_QUESTIONS_SUCCESS:
    case UPDATE_QUIZ_QUESTION_SUCCESS:
    case CREATE_QUESTIONS_SUCCESS:
      let newerState = {
        ...merge({}, state, payload),
        loading: false,
        error: null
      };
      if (payload.questions) {
        let question = Object.values(payload.questions)[0];
        newerState = fromJS(newerState);
        newerState = updateIn(
          newerState,
          [
            "quizzes",
            question.attributes.courseQuizId,
            "relationships",
            "questions",
            "data"
          ],
          relationships => {
            let questions = newerState
              .get("quizzes")
              .get(String(question.attributes.courseQuizId))
              .get("relationships")
              .get("questions")
              .get("data");
            let questionsIds = questions.map(item => item.get("id"));
            if (!questionsIds.includes(question.id)) {
              questions = questions.push(question);
            }
            return questions;
          }
        ).toJS();

        if (payload.matchClue) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "matchClues",
            payload.matchClue
          );
        }
        if (payload.matchChoice) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "matchChoices",
            payload.matchChoice
          );
        }
        if (payload.answerChoice) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "answerChoices",
            payload.answerChoice
          );
        }
        if (payload.fibAnswer) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "fibAnswers",
            payload.fibAnswer
          );
        }
        if (payload.fibElement) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "fibElements",
            payload.fibElement
          );
        }
      }
      return newerState;
    // TODO: this can probably be refactored to be combined with CREATE_QUESTIONS_SUCCESS above
    case IMPORT_QUIZ_QUESTIONS_SUCCESS:
      return {
        ...state,
        answerChoice: merge({}, state.answerChoice, payload.answerChoice),
        questions: merge({}, state.questions, payload.questions),
        quizzes: mergeRelatedResources(
          state.quizzes,
          payload.quizId,
          "questions",
          payload.questions
        )
      };
    case FETCH_QUIZZES_FAILURE:
    case CREATE_QUIZ_FAILURE:
    case UPDATE_QUIZ_FAILURE:
    case UPDATE_QUESTIONS_FAILURE:
    case UPDATE_QUIZ_QUESTIONS_FAILURE:
    case UPDATE_QUIZ_QUESTION_FAILURE:
    case CREATE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case UPDATE_QUIZ_QUESTIONS_SUCCESS:
      let newState = fromJS(state);
      newState = updateIn(
        newState,
        ["quizzes", payload.id, "relationships", "questions", "data"],
        () => payload.included.filter(i => i.type === "question")
      ).toJS();
      return newState;
    default:
      return state;
  }
}

export default contentQuizzesReducer;
