import { visionURL } from "../../config/environment";
import visionCallService from "../../services/visionCallService";
import { findObjectWithData } from "../../utils/helpers";

export const FETCH_AWARD_LEADERS_BEGIN = "FETCH_AWARD_LEADERS_BEGIN";
export const FETCH_AWARD_LEADERS_SUCCESS = "FETCH_AWARD_LEADERS_SUCCESS";
export const FETCH_AWARD_LEADERS_FAILURE = "FETCH_AWARD_LEADERS_FAILURE";
export const FETCH_AVAILABLE_AWARDS_BEGIN = "FETCH_AVAILABLE_AWARDS_BEGIN";
export const FETCH_AVAILABLE_AWARDS_SUCCESS = "FETCH_AVAILABLE_AWARDS_SUCCESS";
export const FETCH_AVAILABLE_AWARDS_FAILURE = "FETCH_AVAILABLE_AWARDS_FAILURE";
export const FETCH_USER_PERFORMANCE_INFO_BEGIN =
  "FETCH_USER_PERFORMANCE_INFO_BEGIN";
export const FETCH_USER_PERFORMANCE_INFO_SUCCESS =
  "FETCH_USER_PERFORMANCE_INFO_SUCCESS";
export const FETCH_USER_PERFORMANCE_INFO_FAILURE =
  "FETCH_USER_PERFORMANCE_INFO_FAILURE";

export const fetchAwardLeadersBegin = () => ({
  type: FETCH_AWARD_LEADERS_BEGIN
});

export const fetchAwardLeadersSuccess = awardLeadersData => ({
  type: FETCH_AWARD_LEADERS_SUCCESS,
  payload: { awardLeadersData }
});

export const fetchAwardLeadersFailure = error => ({
  type: FETCH_AWARD_LEADERS_FAILURE,
  payload: { error }
});

export const fetchAvailableAwardsBegin = () => ({
  type: FETCH_AVAILABLE_AWARDS_BEGIN
});

export const fetchAvailableAwardsSuccess = availableAwards => ({
  type: FETCH_AVAILABLE_AWARDS_SUCCESS,
  payload: { availableAwards }
});

export const fetchAvailableAwardsFailure = error => ({
  type: FETCH_AVAILABLE_AWARDS_FAILURE,
  payload: { error }
});

export function fetchAwardLeaders() {
  return dispatch => {
    dispatch(fetchAwardLeadersBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/award_leaders`,
      function(response) {
        const awardLeaders = findObjectWithData(response);
        dispatch(fetchAwardLeadersSuccess(awardLeaders));
      },
      function(error) {
        dispatch(fetchAwardLeadersFailure(error));
      }
    );
  };
}

export function fetchAvailableAwards() {
  return dispatch => {
    dispatch(fetchAvailableAwardsBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/available_awards`,
      function(availableAwards) {
        dispatch(fetchAvailableAwardsSuccess(availableAwards));
        return availableAwards;
      },
      function(error) {
        dispatch(fetchAvailableAwardsFailure(error));
      }
    );
  };
}
