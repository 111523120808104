import banner1 from "../assets/images/HeroBanner1.png";
import banner2 from "../assets/images/HeroBanner4.png";
import banner3 from "../assets/images/HeroBanner5.png";

export const statusInProgress = "In Progress";
export const statusCompleted = "Completed";
export const statusNotStarted = "Not Started";

export const enterKeyCode = 13;

export const activityTypes = {
  link: "link",
  file: "file",
  document: "document",
  pdf: "pdf",
  scorm: "scorm",
  audio: "audio",
  video: "video",
  survey: "survey",
  quiz: "quiz",
  upload: "upload",
  page: "page",
  checklist: "checklist",
  discussion: "discussion",
  homework: "homework",
  repositoryItem: "repositoryItem",
  policy: "policy",
  course: "course",
  image: "image",
  json: "json",
  policyDocument: "policy_document",
  acknowledgement: "acknowledgement"
};

export const validateOrderStatuses = {
  readyForReview: "ready_for_review",
  readyForSupervisorReview: "ready_for_supervisor_review",
  ordered: "ordered",
  completed: "completed",
  incomplete: "incomplete",
  inProgress: "in_progress"
};
export const validateStatuses = [
  { value: validateOrderStatuses.incomplete, label: "Incomplete" },
  { value: validateOrderStatuses.ordered, label: "Ordered" },
  { value: validateOrderStatuses.inProgress, label: "In Progress" },
  { value: validateOrderStatuses.readyForReview, label: "Ready For Review" },
  {
    value: validateOrderStatuses.readyForSupervisorReview,
    label: "Final Review"
  },
  { value: validateOrderStatuses.completed, label: "Completed" }
];

export const validateOrderTypes = {
  aLaCarte: "a_la_carte",
  standard: "standard"
};

export const validateOrderShopTypes = {
  internet: "Internet",
  phoneRecodered: "Phone (Recorded)",
  phoneNonRecorded: "Phone (Non Recorded)",
  onsiteRecordedAudio: "Onsite (Recorded Audio)",
  onsiteNonRecordedAudio: "Onsite (Non Recorded Audio)",
  videoShop: "Video Shop",
  video: "Video",
  virtualShopAudioRecorded: "Virtual Shop (Audio Recorded)",
  virtualShopAudioVideoRecorded: "Virtual Shop (Audio & Video Recorded)",
  selfGuidedTourNonRecorded: "Self-Guided Tour (Non Recorded)",
  selfGuidedTourAudioRecorded: "Self-Guided Tour (Audio Recorded)",
  selfGuidedTourAudioVideoRecorded: "Self-Guided Tour (Audio & Video Recorded)"
};

export const packageTypes = {
  manual: 4
};

export const courseTypes = {
  webinar: "Webinar",
  externalWebinar: "External Webinar",
  online: "Online",
  onlineCourse: "Online Course",
  ilt: "ILT",
  inPerson: "In-Person",
  instructorLedTraining: "Instructor-Led Training",
  virtual: "virtual",
  virtualClassroom: "Virtual Classroom",
  video: "Video",
  other: "Other",
  validate: "Validate Shopped",
  policy: "Policy"
};

export const courseStatuses = [
  {
    value: "",
    label: "manageCourses.coursesStatuses.all",
    id: 0
  },
  {
    value: "active",
    label: "manageCourses.coursesStatuses.active",
    id: 1
  },
  {
    value: "inactive",
    label: "manageCourses.coursesStatuses.inactive",
    id: 2
  }
];

export const policyStatuses = [
  {
    value: "active_subscribed",
    label: "managePolicies.policyStatuses.activeSubscribed",
    id: 0
  },
  {
    value: "active_unsubscribed",
    label: "managePolicies.policyStatuses.activeUnsubscribed",
    id: 1
  },
  {
    value: "inactive",
    label: "managePolicies.policyStatuses.inactive",
    id: 2
  },
  {
    value: "all_subscribed",
    label: "managePolicies.policyStatuses.allSubscribed",
    id: 3
  }
];

export const assignmentReferenceTypes = [
  {
    value: "Location",
    label: "manageCourses.assignmentReferenceTypes.location"
  },
  {
    value: "Position",
    label: "manageCourses.assignmentReferenceTypes.position"
  },
  {
    value: "Individual",
    label: "manageCourses.assignmentReferenceTypes.individual"
  },
  {
    value: "Special",
    label: "manageCourses.assignmentReferenceTypes.special"
  },
  {
    value: "Group",
    label: "manageCourses.assignmentReferenceTypes.group"
  }
];

export const courseTypesForAdmin = {
  online: "online",
  instructorLedTraining: "ilt",
  policy: "policy"
};

export const achievementTypes = {
  ...courseTypes,
  oldMysteryShopCompletion: "Mystery Shop Legacy"
};

export const resourceTypes = {
  video: "video",
  file: "file",
  document: "document",
  website: "website",
  image: "image",
  section: "section",
  scorm: "scorm",
  link: "link",
  survey: "survey",
  quiz: "quiz"
};

export const flags = {
  english: "English",
  spanish: "Spanish",
  naaEligible: "NAA Eligible"
};

export const todoTypes = {
  policy: "Policy",
  message: "Message",
  assignment: "Assignment",
  myCourses: "My Courses",
  announcement: "Announcement"
};

// the values below correspond to the values defined in the translation file
export const sortByMethods = {
  dueDate: "sortByMethods.dueDate",
  recentlyVisited: "sortByMethods.recentlyVisited",
  dueDateAscending: "sortByMethods.dueDateAscending",
  dueDateDescending: "sortByMethods.dueDateDescending",
  courseTitleAscending: "sortByMethods.courseTitleAscending",
  courseTitleDescending: "sortByMethods.courseTitleDescending",
  courseTypeAscending: "sortByMethods.courseTypeAscending",
  courseTypeDescending: "sortByMethods.courseTypeDescending",
  purchaseDateAscending: "sortByMethods.purchaseDateAscending",
  purchaseDateDescending: "sortByMethods.purchaseDateDescending",
  courseNameAscending: "sortByMethods.courseNameAscending",
  courseNameDescending: "sortByMethods.courseNameDescending",
  policyNameAscending: "sortByMethods.policyNameAscending",
  policyNameDescending: "sortByMethods.policyNameDescending",
  policyNumberAscending: "sortByMethods.policyNumberAscending",
  policyNumberDescending: "sortByMethods.policyNumberDescending",
  webinarNameAscending: "sortByMethods.webinarNameAscending",
  webinarNameDescending: "sortByMethods.webinarNameDescending",
  courseType: "sortByMethods.courseType",
  startDateAscending: "sortByMethods.startDateAscending",
  startDateDescending: "sortByMethods.startDateDescending",
  endDateAscending: "sortByMethods.endDateAscending",
  endtDateDescending: "sortByMethods.endtDateDescending",
  lastViewedDescending: "sortByMethods.lastViewedDescending",
  nameAscending: "sortByMethods.nameAscending",
  nameDescending: "sortByMethods.nameDescending"
};

export const myCoursesSortMethods = [
  { label: sortByMethods.courseNameAscending, value: "name_asc" },
  {
    label: sortByMethods.courseNameDescending,
    value: "name_desc"
  },
  { label: sortByMethods.lastViewedDescending, value: "last_viewed desc" }
];

export const upcomingIltSortMethods = {
  nameAscending: "name_asc",
  nameDescending: "name_desc",
  startDateAscending: "start_date_asc",
  startDateDescending: "start_date_desc"
};

export const policiesSortMethods = [
  { label: sortByMethods.policyNumberAscending, value: "number_asc" },
  { label: sortByMethods.policyNumberDescending, value: "number_desc" },
  { label: sortByMethods.policyNameAscending, value: "name_asc" },
  { label: sortByMethods.policyNameDescending, value: "name_desc" }
];

export const onlineCoursesSortMethods = [
  { label: sortByMethods.courseNameAscending, value: "name_asc" },
  { label: sortByMethods.courseNameDescending, value: "name_desc" }
];

export const webinarSortMethods = [
  { label: sortByMethods.courseNameAscending, value: "name_asc" },
  { label: sortByMethods.courseNameDescending, value: "name_desc" },
  { label: sortByMethods.startDateAscending, value: "start_date_asc" },
  { label: sortByMethods.startDateDescending, value: "start_date_desc" },
  { label: sortByMethods.endDateAscending, value: "end_date_asc" },
  { label: sortByMethods.endtDateDescending, value: "end_date_desc" }
];

export const virtualSortMethods = [
  { label: sortByMethods.nameAscending, value: "name_asc" },
  {
    label: sortByMethods.nameDescending,
    value: "name_desc"
  },
  { label: sortByMethods.startDateAscending, value: "start_date_asc" },
  { label: sortByMethods.startDateDescending, value: "start_date_desc" }
];

export const iltSortMethods = [
  { label: sortByMethods.nameAscending, value: "name_asc" },
  {
    label: sortByMethods.nameDescending,
    value: "name_desc"
  },
  { label: sortByMethods.startDateAscending, value: "start_date_asc" },
  { label: sortByMethods.startDateDescending, value: "start_date_desc" }
];

export const learningPathListSortByMethods = [
  {
    label: sortByMethods.dueDateAscending,
    value: "due_date_asc"
  },
  {
    label: sortByMethods.dueDateDescending,
    value: "due_date_desc"
  },
  {
    label: sortByMethods.courseTitleAscending,
    value: "name_asc"
  },
  {
    label: sortByMethods.courseTitleDescending,
    value: "name_desc"
  },
  {
    label: sortByMethods.courseType,
    value: "course_type_asc"
  }
];

export const assignmentsSortMethods = [
  {
    label: sortByMethods.dueDateAscending,
    value: "due_date_asc"
  },
  {
    label: sortByMethods.dueDateDescending,
    value: "due_date_desc"
  },
  {
    label: sortByMethods.courseTitleAscending,
    value: "name_asc"
  },
  {
    label: sortByMethods.courseTitleDescending,
    value: "name_desc"
  }
];

export const assignmentStatuses = {
  pending: "Pending",
  completed: "Completed",
  inProgress: "In Progress",
  failed: "Failed",
  notStarted: "Not Started",
  enrolled: "Enrolled",
  complete: "Complete",
  needsUnenroll: "Needs Unenroll"
};

export const instructorStatuses = {
  active: "1",
  inactive: "2"
};

export const librarySearchFilters = {
  myCourses: "my_courses",
  allCourses: "all_courses",
  allPolicies: "all_policies"
};

export const learningPathSearchFilters = {
  learningPathAssignments: "Learning Path Assignments"
};

export const transcriptReportFilterCriteria = [
  { label: "performance.transcriptReport.timeframe", value: "Timeframe" },
  { label: "performance.transcriptReport.thisMonth", value: "This Month" },
  { label: "performance.transcriptReport.60Days", value: "Last 60 Days" },
  { label: "performance.transcriptReport.thisQuarter", value: "This Quarter" },
  { label: "performance.transcriptReport.lastQuarter", value: "Last Quarter" },
  { label: "performance.transcriptReport.thisYear", value: "This Year" },
  { label: "performance.transcriptReport.lastYear", value: "Last Year" }
];

export const defaultEventDurations = {
  halfHour: "30mins",
  oneHour: "1hour",
  allDay: "allDay"
};

export const fileTypes = {
  pdf: "pdf",
  link: "link",
  scorm: "scorm",
  video: "video",
  file: "file",
  page: "page"
};

export const widgetNames = {
  myAssignments: "My Assignments",
  socialMedia: "Social Media",
  announcements: "Announcements",
  calendar: "Calendar",
  resources: "Resources",
  myAwards: "My Awards"
};

export const defaultBannerImages = [
  {
    label: "Banner Image 1",
    imgPath: banner1,
    default: true
  },
  {
    label: "Banner Image 2",
    imgPath: banner2,
    default: true
  },
  {
    label: "Banner Image 3",
    imgPath: banner3,
    default: true
  }
];

export const deletedBannerImages = [
  {
    label: "Banner Image 1",
    imgPath: null
  },
  {
    label: "Banner Image 2",
    imgPath: null
  },
  {
    label: "Banner Image 3",
    imgPath: null
  }
];

export const widgetKeys = {
  myAssignments: "assignments",
  socialMedia: "socialembed",
  announcements: "announcements",
  calendar: "calendar",
  resources: "resources",
  awards: "awards",
  ilt: "ilt",
  kingsley: "kingsley",
  quick: "quickaction",
  companyCompliance: "company_compliance"
};

export const trainingMinutesOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45];
export const repositoryItemDurationOptions = [0, 5, 10, 15, 20, 25, 30, 35, 45];
export const repositoryItemDurationHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const states = [
  {
    label: "Alabama",
    value: "AL"
  },
  {
    label: "Alaska",
    value: "AK"
  },
  {
    label: "American Samoa",
    value: "AS"
  },
  {
    label: "Arizona",
    value: "AZ"
  },
  {
    label: "Arkansas",
    value: "AR"
  },
  {
    label: "California",
    value: "CA"
  },
  {
    label: "Colorado",
    value: "CO"
  },
  {
    label: "Connecticut",
    value: "CT"
  },
  {
    label: "Delaware",
    value: "DE"
  },
  {
    label: "District Of Columbia",
    value: "DC"
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    label: "Florida",
    value: "FL"
  },
  {
    label: "Georgia",
    value: "GA"
  },
  {
    label: "Guam",
    value: "GU"
  },
  {
    label: "Hawaii",
    value: "HI"
  },
  {
    label: "Idaho",
    value: "ID"
  },
  {
    label: "Illinois",
    value: "IL"
  },
  {
    label: "Indiana",
    value: "IN"
  },
  {
    label: "Iowa",
    value: "IA"
  },
  {
    label: "Kansas",
    value: "KS"
  },
  {
    label: "Kentucky",
    value: "KY"
  },
  {
    label: "Louisiana",
    value: "LA"
  },
  {
    label: "Maine",
    value: "ME"
  },
  {
    label: "Marshall Islands",
    value: "MH"
  },
  {
    label: "Maryland",
    value: "MD"
  },
  {
    label: "Massachusetts",
    value: "MA"
  },
  {
    label: "Michigan",
    value: "MI"
  },
  {
    label: "Minnesota",
    value: "MN"
  },
  {
    label: "Mississippi",
    value: "MS"
  },
  {
    label: "Missouri",
    value: "MO"
  },
  {
    label: "Montana",
    value: "MT"
  },
  {
    label: "Nebraska",
    value: "NE"
  },
  {
    label: "Nevada",
    value: "NV"
  },
  {
    label: "New Hampshire",
    value: "NH"
  },
  {
    label: "New Jersey",
    value: "NJ"
  },
  {
    label: "New Mexico",
    value: "NM"
  },
  {
    label: "New York",
    value: "NY"
  },
  {
    label: "North Carolina",
    value: "NC"
  },
  {
    label: "North Dakota",
    value: "ND"
  },
  {
    label: "Northern Mariana Islands",
    value: "MP"
  },
  {
    label: "Ohio",
    value: "OH"
  },
  {
    label: "Oklahoma",
    value: "OK"
  },
  {
    label: "Oregon",
    value: "OR"
  },
  {
    label: "Palau",
    value: "PW"
  },
  {
    label: "Pennsylvania",
    value: "PA"
  },
  {
    label: "Puerto Rico",
    value: "PR"
  },
  {
    label: "Rhode Island",
    value: "RI"
  },
  {
    label: "South Carolina",
    value: "SC"
  },
  {
    label: "South Dakota",
    value: "SD"
  },
  {
    label: "Tennessee",
    value: "TN"
  },
  {
    label: "Texas",
    value: "TX"
  },
  {
    label: "Utah",
    value: "UT"
  },
  {
    label: "Vermont",
    value: "VT"
  },
  {
    label: "Virgin Islands",
    value: "VI"
  },
  {
    label: "Virginia",
    value: "VA"
  },
  {
    label: "Washington",
    value: "WA"
  },
  {
    label: "West Virginia",
    value: "WV"
  },
  {
    label: "Wisconsin",
    value: "WI"
  },
  {
    label: "Wyoming",
    value: "WY"
  }
];

export const pdfMimeType = "application/pdf";
export const audioMimeType = "audio/mpeg";
export const htmlMimeType = "text/html";

export const courseBuilderButtonIds = {
  upload: activityTypes.upload,
  page: activityTypes.page,
  survey: activityTypes.survey,
  checklist: activityTypes.checklist,
  link: activityTypes.link,
  discussion: activityTypes.discussion,
  homework: activityTypes.homework,
  quiz: activityTypes.quiz,
  repositoryItem: activityTypes.repositoryItem,
  policy: activityTypes.policy,
  course: activityTypes.course
};

export const officeMimeTypesEncoded = [
  "application%2Fdoc",
  "application%2Fms-doc",
  "application%2Fmsword",
  "application%2Fvnd.openxmlformats-officedocument.wordprocessingml.document",
  "application%2Fexcel",
  "application%2Fvnd.ms-excel",
  "application%2Fx-excel",
  "application%2Fx-ole-storage",
  "application%2Fx-msexcel",
  "application%2Fvnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application%2Fmspowerpoint",
  "application%2Fpowerpoint",
  "application%2Fvnd.ms-powerpoint",
  "application%2Fx-mspowerpoint",
  "application%2Fvnd.ms-powerpoint.presentation.macroenabled.12",
  "application%2Fvnd.openxmlformats-officedocument.presentationml.presentation",
  "application%2Fvnd.openxmlformats-officedocument.presentationml.slideshow"
];

export const officeMimeTypes = [
  "application/doc",
  "application/ms-doc",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/excel",
  "application/vnd.ms-excel",
  "application/x-excel",
  "application/x-msexcel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/mspowerpoint",
  "application/powerpoint",
  "application/vnd.ms-powerpoint",
  "application/x-mspowerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/plain",
  "text/csv"
];

export const imageFileMimeTypes = ["image/png", "image/gif", "image/jpeg"];

export const processVideoMimeTypes = ["video/quicktime", "video/x-ms-wmv"];

export const repositoryFileMimeTypes = [
  "application/pdf",
  "application/zip",
  "application/x-zip-compressed",
  "image/png",
  "image/gif",
  "image/jpeg",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-powerpoint.presentation.macroenabled.12",
  "video/mp4",
  "video/quicktime",
  ".mov",
  "video/x-flv",
  "video/flv",
  ".flv",
  "video/x-ms-wmv",
  "video/x-msvideo",
  "video/avi",
  "video/msvideo",
  "video/webm",
  "text/html",
  "text/plain",
  "text/csv",
  ".csv",
  "audio/mpeg"
];

export const repositoryVideoMimeTypes = [
  "video/mp4",
  "video/quicktime",
  ".mov",
  "video/x-flv",
  "video/flv",
  ".flv",
  "video/x-ms-wmv",
  "video/x-msvideo",
  "video/avi",
  "video/msvideo",
  "video/webm"
];

export const HTML5SupportedVideoMimeTypes = ["video/mp4", "video/webm"];

export const quizImportFileMimeTypes = [
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
  ".csv"
];

export const iltClassTypes = ["ilt", "webinar", "virtual"];

export const contentOwners = {
  custom: "Custom",
  master: "Master",
  graceHill: "Grace Hill"
};
export const defaultShortCourseTitle = "courseBuilder.newShortCourse";

export const questionTypes = {
  trueFalse: "boolean",
  multipleChoice: "multiple_choice",
  multiSelect: "multiple_select",
  longAnswer: "long_answer",
  shortAnswer: "short_answer",
  fillBlanks: "fill_in_the_blank",
  matching: "matching",
  ordering: "ordering"
};

export const statusTypes = {
  all: 0,
  active: 1,
  inactive: 2,
  closed: 3
};

export const statusIdValues = {
  0: courseStatuses[0].value,
  1: courseStatuses[1].value,
  2: courseStatuses[2].value
};

export const trainingObjectStatusIds = {
  active: statusTypes.active,
  inactive: statusTypes.inactive,
  sessionClosedOut: statusTypes.closed
};

export const enrollmentScoreIconColors = {
  green: "green",
  yellow: "yellow",
  red: "red"
};

export const dotIconColors = {
  green: "green",
  yellow: "yellow",
  red: "red"
};

export const quizQuestionTypes = {
  trueFalse: questionTypes.trueFalse,
  multipleChoice: questionTypes.multipleChoice,
  multiSelect: questionTypes.multiSelect,
  ordering: questionTypes.ordering,
  shortAnswer: questionTypes.shortAnswer,
  fillBlanks: questionTypes.fillBlanks,
  matching: questionTypes.matching
};

export const surveyQuestionTypes = {
  trueFalse: questionTypes.trueFalse,
  multipleChoice: questionTypes.multipleChoice,
  multiSelect: questionTypes.multiSelect,
  shortAnswer: questionTypes.shortAnswer,
  longAnswer: questionTypes.longAnswer,
  ordering: questionTypes.ordering,
  fillBlanks: questionTypes.fillBlanks,
  matching: questionTypes.matching
};

export const courseLocationTypes = {
  inPerson: "In-Person",
  online: "External Webinar",
  virtualClassroom: "Virtual Classroom"
};

export const timeZones = {
  at: "Atlantic Time (Canada)",
  et: "Eastern Time (US & Canada)",
  ct: "Central Time (US & Canada)",
  mt: "Mountain Time (US & Canada)",
  pt: "Pacific Time (US & Canada)",
  akt: "Alaska",
  hat: "Hawaii",
  st: "American Samoa",
  cht: "Guam"
};

export const quizQuestionTypesPresentation = {
  trueFalse: "T/F",
  multipleChoice: "MC",
  multiSelect: "M-S",
  longAnswer: "LA",
  shortAnswer: "SA",
  fillBlanks: "FIB",
  matching: "MAT",
  ordering: "ORD"
};

export const viewDashboardWidgets = {
  contentTools: "admin",
  learningCenter: "learning_center"
};

export const resourceObjectTypes = {
  contentRepositoryItem: "ContentRepository::Item",
  enrollments: "enrollments",
  userAssignment: "userAssignment",
  fillInTheBlankTextType: "fibElement",
  fillInTheBlankAnswerType: "fibAnswer",
  matchClueType: "matchClue",
  matchChoiceType: "matchChoice",
  homeworkSubscribedEmail: "homeworkSubscribedEmail",
  fileFormat: "fileFormat",
  quizContentable: "quizContentable",
  homeworAssignmentAttachment: "homeworkAssignmentAttachment",
  trainingObject: "trainingObject"
};

export const quizQuestionsScoringBehavior = {
  allOrNothing: "all_or_nothing",
  partialCredit: "partial_credit"
};

export const contentHosts = {
  brsp: "D2L",
  visionHosted: "Vision",
  scormEngine: "SCORM Engine"
};

export const courseSectionTypes = {
  mandatory: "mandatory",
  optional: "optional"
};

export const assignmentTypeIds = {
  selfAssigned: 3
};

export const homeworkSubmissionTypes = {
  file: "file_submission",
  fileAndText: "file_and_text_submission"
};

export const homeworkFeedbackResponseTypes = {
  writtenResponse: "text",
  uploadedResponse: "file"
};

export const assignmentTypes = {
  forceEnrolled: "enrolled",
  self: "self assigned"
};

export const instructorTypes = {
  internal: "internal",
  external: "external"
};

export const trainingLocationTypes = {
  internal: "internal",
  external: "external"
};

export const timeRangeOptions = [
  {
    value: "days",
    label: "days"
  },
  {
    value: "months",
    label: "months"
  },
  {
    value: "years",
    label: "years"
  }
];

export const maxFileSize = 1024 * 1024 * 1024; //1GB for video files

export const roleNames = {
  reporting: "Reporting",
  adminConsole: "Admin Console",
  dataManager: "Data Manager",
  manageEmployees: "Manage Employees",
  manageTeam: "Manage Team",
  instructor: "Instructor",
  policyAdmin: "Policy Admin",
  customRole: "Custom Role",
  performance: "Performance",
  validate: "Validate"
};

export const organizationalRoleNames = [
  roleNames.reporting,
  roleNames.adminConsole,
  roleNames.dataManager,
  roleNames.policyAdmin,
  roleNames.validate,
  roleNames.performance
];

export const learningManagementRoleNames = [
  roleNames.manageEmployees,
  roleNames.instructor,
  roleNames.customRole
];

export const referenceTypes = {
  location: "Location",
  position: "Position",
  group: "Group"
};

export const applicationCalendars = [
  { key: "google", name: "Google Calendar" },
  { key: "outlook", name: "Outlook Calendar" },
  { key: "iCal", name: "iCal" }
];

export const packageNames = {
  taxCreditSeries: "Tax Credit Series"
};

export const visionEmailAlerts = [
  "new",
  "changed",
  "dueReminder",
  "pastDue",
  "newLibraryItem",
  "summaryReport"
];

export const visionJwtKey = "vision_jwt";

export const companyComplianceWidgetFilters = [
  { label: "All", value: "all" },
  { label: "Online", value: "online" },
  { label: "Instructor-Led Training", value: "ilt" },
  { label: "Other", value: "other" },
  { label: "Policy", value: "policy" }
];
export const userAssignmentListTypes = {
  latest: "latest",
  recommended: "recommended",
  inPerson: "in-person",
  online: "online",
  webinar: "webinar",
  virtual: "virtual",
  userAssignments: "userAssignments",
  libraryCourses: "libraryCourses",
  adminEnrolled: "admin_enrolled",
  self: "self",
  naaEligible: "naa_eligible"
};

export const addNewVersionOptions = [{ value: "new" }, { value: "copy" }];

export const editorInlineItemsBehavior = {
  openInNewTab: "openInNewTab",
  embed: "embed"
};

export const specialErrors = {
  enrollOnInactive: "Attempting to enroll on inactive version"
};
