import {
  FETCH_SHORT_COURSE_PAGE_TEMPLATES_SUCCESS,
  FETCH_SHORT_COURSE_PAGE_TEMPLATES_FAILURE,
  FETCH_SHORT_COURSE_PAGE_TEMPLATES_BEGIN,
  FETCH_QUIZ_IMPORT_TEMPLATE_SUCCESS,
  FETCH_FILE_FORMATS_SUCCESS
} from "../actions/courseBuilderActions";
import { merge } from "lodash";

const INITIAL_STATE = {
  shortCoursePageTemplates: null,
  loading: false,
  error: null,
  fileFormat: null,
  quizImportTemplate: null
};

function courseBuilderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SHORT_COURSE_PAGE_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SHORT_COURSE_PAGE_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        shortCoursePageTemplates: action.payload
      };
    }
    case FETCH_QUIZ_IMPORT_TEMPLATE_SUCCESS:
    case FETCH_FILE_FORMATS_SUCCESS:
      return {
        ...merge({}, state, action.payload)
      };
    case FETCH_SHORT_COURSE_PAGE_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shortCoursePageTemplates: []
      };
    default:
      return state;
  }
}

export default courseBuilderReducer;
