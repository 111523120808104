import { assign, unionBy, merge } from "lodash";
import {
  ADD_COURSE_BEGIN,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAILURE,
  UPDATE_COURSE_BEGIN,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILURE,
  FETCH_COURSES_BEGIN,
  FETCH_COURSES_FAILURE,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSE_PACKAGES_BEGIN,
  FETCH_COURSE_PACKAGES_SUCCESS,
  FETCH_COURSE_PACKAGES_FAILURE,
  FETCH_COURSES_FILTER_DATA_BEGIN,
  FETCH_COURSES_FILTER_DATA_SUCCESS,
  FETCH_COURSES_FILTER_DATA_FAILURE,
  FETCH_COURSE_VERSIONS_BEGIN,
  FETCH_COURSE_VERSIONS_SUCCESS,
  FETCH_COURSE_VERSIONS_FAILURE,
  UPDATE_COURSE_SETTINGS_BEGIN,
  UPDATE_COURSE_SETTINGS_SUCCESS,
  UPDATE_COURSE_SETTINGS_FAILURE,
  FETCH_COURSE_PREREQS_BEGIN,
  FETCH_COURSE_PREREQS_SUCCESS,
  FETCH_COURSE_PREREQS_FAILURE,
  UPDATE_COURSE_PREREQS_BEGIN,
  UPDATE_COURSE_PREREQS_SUCCESS,
  UPDATE_COURSE_PREREQS_FAILURE,
  FETCH_COURSE_SESSION_BEGIN,
  FETCH_COURSE_SESSION_SUCCESS,
  FETCH_COURSE_SESSION_FAILURE,
  ADD_COURSE_SESSION_BEGIN,
  ADD_COURSE_SESSION_SUCCESS,
  ADD_COURSE_SESSION_FAILURE,
  ADD_COURSE_VERSION_SUCCESS,
  ADD_COURSE_VERSION_BEGIN,
  ADD_COURSE_VERSION_FAILURE,
  UPDATE_COURSE_VERSION_BEGIN,
  UPDATE_COURSE_VERSION_SUCCESS,
  UPDATE_COURSE_VERSION_FAILURE,
  FETCH_COURSE_VERSION_DATA_BEGIN,
  FETCH_COURSE_VERSION_DATA_SUCCESS,
  FETCH_COURSE_VERSION_DATA_FAILURE,
  FETCH_COURSE_SECTIONS_BEGIN,
  FETCH_COURSE_SECTIONS_SUCCESS,
  FETCH_COURSE_SECTIONS_FAILURE,
  UPDATE_COURSE_SECTION_BEGIN,
  UPDATE_COURSE_SECTION_SUCCESS,
  UPDATE_COURSE_SECTION_FAILURE,
  ADD_COURSE_SECTION_SUCCESS,
  ADD_COURSE_SECTION_BEGIN,
  ADD_COURSE_SECTION_FAILURE,
  ADD_ITEM_INTO_SHORT_COURSE_BEGIN,
  ADD_ITEM_INTO_SHORT_COURSE_FAILURE,
  ADD_ITEM_INTO_SHORT_COURSE_SUCCESS,
  ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_BEGIN,
  ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_SUCCESS,
  ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_FAILURE,
  DELETE_COURSE_SECTION_BEGIN,
  DELETE_COURSE_SECTION_SUCCESS,
  DELETE_COURSE_SECTION_FAILURE,
  FETCH_COURSE_EXCLUSIONS_BEGIN,
  FETCH_COURSE_EXCLUSIONS_FAILURE,
  FETCH_COURSE_EXCLUSIONS_SUCCESS,
  UPDATE_COURSE_EXCLUSIONS_BEGIN,
  UPDATE_COURSE_EXCLUSIONS_FAILURE,
  UPDATE_COURSE_EXCLUSIONS_SUCCESS,
  FETCH_COURSE_SETTINGS_DATA_BEGIN,
  FETCH_COURSE_SETTINGS_DATA_SUCCESS,
  FETCH_COURSE_SETTINGS_DATA_FAILURE,
  FETCH_CUSTOMER_ILT_DETAILS_BEGIN,
  FETCH_CUSTOMER_ILT_DETAILS_SUCCESS,
  FETCH_CUSTOMER_ILT_DETAILS_FAILURE,
  FETCH_COURSE_ENROLLMENTS_SUCCESS,
  FETCH_COURSE_ENROLLMENTS_BEGIN,
  FETCH_COURSE_ENROLLMENTS_FAILURE,
  UPDATE_COURSE_ENROLLMENTS_SUCCESS,
  UPDATE_COURSE_ENROLLMENTS_BEGIN,
  UPDATE_COURSE_ENROLLMENTS_FAILURE,
  CREATE_NEW_RELEASE_BEGIN,
  CREATE_NEW_RELEASE_SUCCESS,
  CREATE_NEW_RELEASE_FAILURE,
  DELETE_ITEM_FROM_COURSE_BEGIN,
  DELETE_ITEM_FROM_COURSE_SUCCESS,
  DELETE_ITEM_FROM_COURSE_FAILURE,
  FETCH_VC_ATTENDEES_SUCCESS,
  FETCH_SESSION_ROSTER_SUCCESS,
  UPDATE_SESSION_ROSTER_SUCCESS,
  DELETE_RELEASE_BEGIN,
  DELETE_RELEASE_SUCCESS,
  DELETE_RELEASE_FAILURE,
  VALIDATE_RELEASE_BEGIN,
  VALIDATE_RELEASE_SUCCESS,
  VALIDATE_RELEASE_FAILURE
} from "../actions/contentCoursesActions";
import {
  addRelatedResources,
  removeEntity,
  removeRelationshipFromEntity,
  replaceRelatedResources,
  updateRelatedResource
} from "../utils/reducerHelpers";

const INITIAL_STATE = {
  courses: {},
  version: {},
  exclusions: [],
  loading: false,
  isAddingCourseSection: false,
  error: null,
  coursesFilterData: {
    contentPackages: null
  },
  coursePackage: {},
  courseSettings: {},
  assignments: {},
  associatedEquivalent: {},
  prereqs: null,
  courseName: null,
  courseData: null,
  trainingObject: {},
  courseSection: {},
  enrollments: {},
  customerIltDetails: {
    defaultIltLocation: null,
    instructors: null,
    locations: null,
    virtualClassrooms: null
  }
};

function contentCoursesReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COURSES_BEGIN:
    case ADD_COURSE_BEGIN:
    case FETCH_COURSE_PREREQS_BEGIN:
    case UPDATE_COURSE_PREREQS_BEGIN:
    case FETCH_COURSE_PACKAGES_BEGIN:
    case FETCH_COURSES_FILTER_DATA_BEGIN:
    case FETCH_COURSE_VERSIONS_BEGIN:
    case ADD_COURSE_VERSION_BEGIN:
    case FETCH_COURSE_SESSION_BEGIN:
    case ADD_COURSE_SESSION_BEGIN:
    case FETCH_COURSE_SECTIONS_BEGIN:
    case FETCH_COURSE_EXCLUSIONS_BEGIN:
    case FETCH_CUSTOMER_ILT_DETAILS_BEGIN:
    case UPDATE_COURSE_EXCLUSIONS_BEGIN:
    case FETCH_COURSE_VERSION_DATA_BEGIN:
    case DELETE_RELEASE_BEGIN:
    case VALIDATE_RELEASE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_COURSE_SECTION_BEGIN:
      return {
        ...state,
        error: null,
        isAddingCourseSection: true
      };
    case DELETE_ITEM_FROM_COURSE_BEGIN:
    case UPDATE_COURSE_SECTION_BEGIN:
      return {
        ...state,
        error: null,
        isUpdatingCourseSection: true
      };
    case FETCH_COURSE_ENROLLMENTS_BEGIN:
      return {
        ...state,
        error: null,
        isFetchingEnrollments: true
      };
    case UPDATE_COURSE_ENROLLMENTS_BEGIN:
      return {
        ...state,
        error: null,
        isUpdatingEnrollments: true
      };
    case FETCH_COURSE_SETTINGS_DATA_BEGIN:
    case UPDATE_COURSE_SETTINGS_BEGIN:
      return {
        ...state,
        error: null,
        isFetchingCourseSettings: true
      };
    case ADD_ITEM_INTO_SHORT_COURSE_BEGIN:
      return {
        ...state,
        error: null
      };
    case ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_BEGIN:
      return {
        ...state,
        error: null,
        isAddingRepoItem: true
      };
    case UPDATE_COURSE_VERSION_BEGIN:
      return {
        ...state,
        error: null,
        isUpdatingCourseVersion: true
      };
    case ADD_COURSE_SUCCESS:
      /**
       * Right now this isn't doing anything. Qhen a new course gets added,
       * we redirect the user and fetch the new course. Instead, the response
       * from adding a course should return the course in JSON API format
       * and we should merge it with the existing table of trainingObjects in the state
       */
      return {
        ...state,
        courses: unionBy(state.courses, [payload.course], "id"),
        loading: false,
        error: null
      };
    case FETCH_COURSE_SETTINGS_DATA_SUCCESS:
    case UPDATE_COURSE_SETTINGS_SUCCESS:
      return {
        ...state,
        courseSettings: payload.courseSettings,
        assignments: payload.assignments,
        associatedEquivalent: payload.associatedEquivalent || {},
        isFetchingCourseSettings: false,
        error: null
      };
    case FETCH_COURSE_PREREQS_SUCCESS:
      return {
        ...state,
        courses: unionBy(state.courses, [payload.course], "id"),
        prereqs: payload.prereqs,
        courseName: payload.courseName,
        loading: false,
        error: null
      };
    case UPDATE_COURSE_PREREQS_SUCCESS:
      return {
        ...state,
        prereqs: payload.coursePrereqs,
        courseName: payload.courseName,
        loading: false,
        error: null
      };
    case ADD_COURSE_SESSION_SUCCESS:
    case FETCH_COURSE_SESSION_SUCCESS:
    case FETCH_CUSTOMER_ILT_DETAILS_SUCCESS:
      return {
        ...merge({}, state, payload),
        loading: false,
        error: null
      };
    case FETCH_COURSE_PACKAGES_SUCCESS:
      return {
        ...state,
        coursePackage: payload.coursePackage,
        loading: false,
        error: null
      };
    case FETCH_COURSES_SUCCESS:
      return {
        ...merge({}, state, payload),
        loading: false,
        error: null
      };
    case FETCH_COURSES_FILTER_DATA_SUCCESS:
      return {
        ...state,
        coursesFilterData: {
          contentPackages: payload
        },
        loading: false
      };
    case FETCH_COURSE_VERSIONS_SUCCESS:
    case ADD_COURSE_VERSION_SUCCESS:
      /**
       *  Adds payload to "version" table in redux state and  adds the payload to the
       *  versions relattionships for the parent training object that the versions
       **/
      return {
        ...state,
        version: merge(
          {},
          state.version,
          payload.version ? payload.version : payload.session
        ),
        trainingObject: addRelatedResources(
          state.trainingObject,
          payload.parentTrainingObjectId,
          "versions",
          payload.version
        ),
        loading: false,
        error: null
      };
    case FETCH_COURSE_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        enrollments: merge({}, state.enrollments, payload.enrollments),
        trainingObject: addRelatedResources(
          state.trainingObject,
          payload.courseId,
          "enrollments",
          payload.enrollments
        ),
        isFetchingEnrollments: false,
        error: null
      };
    case UPDATE_COURSE_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        enrollments: merge({}, state.enrollments, payload.enrollments),
        trainingObject: replaceRelatedResources(
          state.trainingObject,
          payload.courseId,
          "enrollments",
          payload.enrollments
        ),
        isUpdatingEnrollments: false,
        error: null
      };
    case FETCH_COURSE_VERSION_DATA_SUCCESS:
    case UPDATE_COURSE_VERSION_SUCCESS:
      return {
        ...state,
        version: merge({}, state.version, payload.version),
        error: null,
        isUpdatingCourseVersion: false
      };
    case FETCH_COURSE_SECTIONS_SUCCESS:
      return {
        ...state,
        courseSection: assign({}, state.courseSection, payload.courseSection),
        shortCourseActivity: assign(
          {},
          state.shortCourseActivity,
          payload.shortCourseActivity
        ),
        version: replaceRelatedResources(
          state.version,
          payload.versionId,
          "courseSections",
          payload.courseSection
        ),
        error: null,
        loading: false
      };
    case ADD_COURSE_SECTION_SUCCESS:
    case ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_SUCCESS:
    case ADD_ITEM_INTO_SHORT_COURSE_SUCCESS:
      /**
       *  Adds payload to "courseSection" table in redux state and  adds the payload to the
       *  courseSections relattionships for the version that contains the course section
       **/
      return {
        ...state,
        courseSection: merge({}, state.courseSection, payload.courseSection),
        shortCourseActivity: merge(
          {},
          state.shortCourseActivity,
          payload.shortCourseActivity
        ),
        version: addRelatedResources(
          state.version,
          payload.versionId,
          "courseSections",
          payload.courseSection
        ),
        error: null,
        loading: false
      };
    case UPDATE_COURSE_BEGIN:
      return {
        ...state,
        error: null,
        isUpdatingCourse: true
      };
    case UPDATE_COURSE_SUCCESS:
      return {
        ...merge({}, state, payload),
        courseSection: payload.courseSection,
        shortCourseActivity: payload.shortCourseActivity,
        trainingObject: {
          ...state.trainingObject,
          ...payload.trainingObject
        },
        isUpdatingCourse: false,
        error: null
      };
    case UPDATE_COURSE_FAILURE:
      return {
        ...state,
        isUpdatingCourse: false,
        error: action.payload.error
      };
    case UPDATE_COURSE_SECTION_SUCCESS:
      return {
        ...merge({}, state, payload),
        isUpdatingCourseSection: false,
        error: null
      };
    case DELETE_COURSE_SECTION_BEGIN:
      return {
        ...state,
        error: null
      };
    case DELETE_COURSE_SECTION_SUCCESS:
      let deleteCourseSectionState = removeEntity(
        state,
        "courseSection",
        payload.courseSectionId
      );
      deleteCourseSectionState = removeRelationshipFromEntity(
        deleteCourseSectionState,
        "version",
        payload.versionId,
        "courseSections",
        payload.courseSectionId
      );
      return {
        ...state,
        ...deleteCourseSectionState,
        error: null
      };
    case FETCH_SESSION_ROSTER_SUCCESS:
    case UPDATE_SESSION_ROSTER_SUCCESS:
      return {
        ...state,
        trainingObject: updateRelatedResource(
          state.trainingObject,
          payload.courseId,
          "roster",
          payload.roster
        ),
        roster: merge({}, state.roster, payload.roster),
        error: null
      };
    case FETCH_VC_ATTENDEES_SUCCESS: {
      return {
        ...state,
        virtualClassroomAttendee: merge(
          {},
          state.virtualClassroomAttendee,
          payload.virtualClassroomAttendee
        ),
        trainingObject: addRelatedResources(
          state.trainingObject,
          payload.trainingObjectId,
          "virtualClassroomAttendees",
          payload.virtualClassroomAttendee
        ),
        error: null,
        loading: false
      };
    }
    case FETCH_COURSE_EXCLUSIONS_SUCCESS:
    case UPDATE_COURSE_EXCLUSIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case ADD_COURSE_FAILURE:
    case FETCH_COURSE_PREREQS_FAILURE:
    case FETCH_COURSES_FAILURE:
    case UPDATE_COURSE_PREREQS_FAILURE:
    case FETCH_COURSE_PACKAGES_FAILURE:
    case FETCH_COURSES_FILTER_DATA_FAILURE:
    case FETCH_COURSE_VERSIONS_FAILURE:
    case ADD_COURSE_VERSION_FAILURE:
    case ADD_COURSE_SESSION_FAILURE:
    case FETCH_COURSE_SESSION_FAILURE:
    case FETCH_COURSE_SECTIONS_FAILURE:
    case DELETE_COURSE_SECTION_FAILURE:
    case FETCH_COURSE_EXCLUSIONS_FAILURE:
    case FETCH_CUSTOMER_ILT_DETAILS_FAILURE:
    case UPDATE_COURSE_EXCLUSIONS_FAILURE:
    case DELETE_ITEM_FROM_COURSE_FAILURE:
    case DELETE_RELEASE_FAILURE:
    case VALIDATE_RELEASE_FAILURE:
    case FETCH_COURSE_VERSION_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case UPDATE_COURSE_SECTION_FAILURE:
    case CREATE_NEW_RELEASE_FAILURE:
      return {
        ...state,
        loading: false,
        isUpdatingCourseSection: false,
        isCreatingNewRelease: false,
        error: payload.error
      };
    case ADD_ITEM_INTO_SHORT_COURSE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case ADD_REPOSITORY_ITEMS_INTO_SHORT_COURSE_FAILURE:
      return {
        ...state,
        isAddingRepoItem: false,
        error: action.payload.error
      };
    case ADD_COURSE_SECTION_FAILURE:
      return {
        ...state,
        isAddingCourseSection: false,
        error: action.payload.error
      };
    case FETCH_COURSE_SETTINGS_DATA_FAILURE:
    case UPDATE_COURSE_SETTINGS_FAILURE:
      return {
        ...state,
        isFetchingCourseSettings: false,
        error: action.payload.error
      };
    case FETCH_COURSE_ENROLLMENTS_FAILURE:
      return {
        ...state,
        isFetchingEnrollments: false,
        error: action.payload.error
      };
    case UPDATE_COURSE_ENROLLMENTS_FAILURE:
      return {
        ...state,
        isUpdatingEnrollments: false,
        error: action.payload.error
      };
    case UPDATE_COURSE_VERSION_FAILURE:
      return {
        ...state,
        isUpdatingCourseVersion: false,
        error: action.payload.error
      };
    case CREATE_NEW_RELEASE_BEGIN:
      return {
        ...state,
        isCreatingNewRelease: true,
        error: null
      };
    case CREATE_NEW_RELEASE_SUCCESS:
      return {
        ...merge({}, state, payload),
        isCreatingNewRelease: false,
        error: null
      };
    case DELETE_ITEM_FROM_COURSE_SUCCESS: {
      let deleteShortCourseActivityState = removeEntity(
        state,
        "shortCourseActivity",
        `${payload.activityId}`
      );
      deleteShortCourseActivityState = removeRelationshipFromEntity(
        deleteShortCourseActivityState,
        "courseSection",
        `${payload.courseSectionId}`,
        "shortCourseActivities",
        `${payload.activityId}`
      );
      return {
        ...deleteShortCourseActivityState,
        isUpdatingCourseSection: false
      };
    }
    case DELETE_RELEASE_SUCCESS:
    case VALIDATE_RELEASE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    default:
      return { ...state };
  }
}

export default contentCoursesReducer;
