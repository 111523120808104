import visionCallService from "../services/visionCallService";

export const noop = () => {};

export const findObjectWithData = obj => {
  if (obj !== null && obj.hasOwnProperty("data")) {
    obj = visionCallService.extractAttributes(obj);
  } else if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) continue;
      obj[key] = findObjectWithData(obj[key]);
    }
  }

  return obj;
};

/**
 * Strips an HTML string of all HTML and leaves only the text within all HTML tags
 *
 * @param {string} htmlString
 * @param {boolean} replaceImgTags If true, replaces image tags with "(image)"
 * @return {string} The resulting string stripped of all html
 */
export const stripHtml = (htmlString, replaceImgTags) => {
  let newString = htmlString;
  if (replaceImgTags) {
    newString = newString.replace(
      /<img\s[^>]*?src\s*=\s*['"]([^'"]*?)['"][^>]*?>/g,
      "(image) "
    );
  }
  return newString.replace(/(<([^>]+)>)/g, "");
};

/**
 * Searches an array for an object with a given key value pair and updates a property
 * with the provided new value. Returns the new array.
 *
 * @param {array} array The array to search and update
 * @param {string} lookupKey The key in the key value pair to search the array for
 * @param lookupValue The value in the key value pair to search the array for
 * @param {string} keyToUpdate If provided, will return the value for the provided key of the object that is found
 * @param newValue If provided, will return the value for the provided key of the object that is found
 */
export const updateInArrayByKeyValue = (
  array,
  lookupKey,
  lookupValue,
  keyToUpdate,
  newValue
) => {
  const newArray = [...array];
  const elementIndex = newArray.findIndex(
    element => element[lookupKey] === lookupValue
  );
  const newElement = { ...newArray[elementIndex], [keyToUpdate]: newValue };
  newArray[elementIndex] = newElement;
  return newArray;
};

/**
 * Searches an array for an object with a key value pair and if the object is found,
 * returns the
 *
 * @param {array} array The array to search
 * @param {string} lookupKey The key in the key value pair to search the array for
 * @param {string} lookupValue The value in the key value pair to search the array for
 * @param {string} keyToReturn If provided, will return the value for the provided key of the object that is found
 */
export const findByKey = (array, lookupKey, lookupValue, keyToReturn) => {
  const obj = array.find(object => object[lookupKey] === lookupValue);
  if (keyToReturn) {
    return obj ? obj[keyToReturn] : null;
  } else {
    return obj;
  }
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const isIosSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};
