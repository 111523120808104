export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = (notification, variant = "success") => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      options: {
        ...notification.options,
        variant
      },
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const enqueueSuccessSnackbar = notification => {
  return enqueueSnackbar(notification);
};

export const enqueueErrorSnackbar = notification => {
  return enqueueSnackbar(notification, "error");
};

export const enqueueInfoSnackbar = notification => {
  return enqueueSnackbar(notification, "info");
};

export const enqueueWarningSnackbar = notification => {
  return enqueueSnackbar(notification, "warning");
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
});
