import {
  FETCH_REPORT_DATA_BEGIN,
  FETCH_REPORT_DATA_SUCCESS,
  FETCH_REPORT_DATA_FAILURE,
  UPDATE_FILTER_VALUE
} from "../actions/transcriptReportActions";

const INITIAL_STATE = {
  tableFilter: "",
  reportData: [],
  loading: false,
  error: null
};

const transcriptReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_FILTER_VALUE:
      return {
        ...state,
        loading: false,
        error: null,
        tableFilter: action.payload.tableFilter
      };
    case FETCH_REPORT_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        reportData: action.payload.records
      };
    case FETCH_REPORT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        reportData: []
      };
    default:
      return state;
  }
};

export default transcriptReportReducer;
