import {
  FETCH_AC_CONTENT_ASSIGNMENTS_BEGIN,
  FETCH_AC_CONTENT_ASSIGNMENTS_SUCCESS,
  FETCH_AC_CONTENT_ASSIGNMENTS_FAILURE,
  UPDATE_AC_CONTENT_ASSIGNMENTS_BEGIN,
  UPDATE_AC_CONTENT_ASSIGNMENTS_SUCCESS,
  UPDATE_AC_CONTENT_ASSIGNMENTS_JOB_SUCCESS,
  UPDATE_AC_CONTENT_ASSIGNMENTS_FAILURE
} from "../../../actions/adminConsole/content/assignmentsActions";

const INITIAL_STATE = {
  referenceObjects: {
    assigned: [],
    unassigned: []
  },
  jobId: null,
  loading: false,
  error: null
};

function assignmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AC_CONTENT_ASSIGNMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_AC_CONTENT_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        referenceObjects: action.payload.referenceObjects
      };
    }

    case FETCH_AC_CONTENT_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case UPDATE_AC_CONTENT_ASSIGNMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_AC_CONTENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        jobId: action.payload.result.jobId,
        loading: false,
        error: null
      };
    case UPDATE_AC_CONTENT_ASSIGNMENTS_JOB_SUCCESS:
      return {
        ...state,
        jobId: null,
        loading: false
      };
    case UPDATE_AC_CONTENT_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export default assignmentsReducer;
