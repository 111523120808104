import { polyfillsForEnvironment } from "../utils/polyfills";

polyfillsForEnvironment();

const NUM_SANDBOXES = 20;

let visionEnvironments = {
    test: "http://localhost:3001",
    development: "http://localhost:3000",
    production: "https://www.gracehillvision.com"
  },
  visionWSChannels = {
    development: "ws://localhost:3000/cable",
    production: "wss://www.gracehillvision.com/cable"
  },
  numSandboxes = 20,
  sandboxIndexes = Array.from({ length: numSandboxes }, (v, k) => k + 1),
  apiDomain = "gracehillvision.com",
  sandboxPrefixes = ["dev", "qa"],
  envPrefixes = [
    "dev",
    "dev-no-bs",
    "dev-ruby2-7",
    "staging",
    "staging2",
    "production2"
  ];

function addVisionEnvironment(envName) {
  visionEnvironments[envName] = `https://${envName}.${apiDomain}`;
}

function addVisionWSChannel(envName) {
  visionWSChannels[envName] = `wss://${envName}.${apiDomain}/cable`;
}

envPrefixes.forEach(function(prefix) {
  addVisionEnvironment(prefix);
  addVisionWSChannel(prefix);
});

sandboxPrefixes.forEach(function(prefix) {
  sandboxIndexes.forEach(function(sboxIdx) {
    let suffix = sboxIdx.toString().padStart(2, "0"),
      envName = `${prefix}-sandbox-${suffix}`;
    addVisionEnvironment(envName);
    addVisionWSChannel(envName);
  });
});

const sandboxes = (envPrefix, phqSubdomain) =>
  new Array(NUM_SANDBOXES).fill("").reduce((s, c, i) => {
    const sandboxNum = (i + 1).toString().padStart(2, "0");
    s[`${envPrefix}-sandbox-${sandboxNum}`] = `https://${
      phqSubdomain ? `${phqSubdomain}.` : ""
    }phq-${envPrefix}-sandbox-${sandboxNum}.gracehill.com`;
    return s;
  }, {});

export const phqUrl = phqSubdomain => {
  const subdomain = phqSubdomain ? `${phqSubdomain}.` : "";
  const phqFrontendUrls = {
    development: `https://${subdomain}us.dev.app.gracehill.com`,
    dev: `https://${subdomain}us.dev.app.gracehill.com`,
    staging: `https://${subdomain}us.qa.app.gracehill.com`,
    production: `https://${subdomain}performancehq.gracehill.com`,
    ...sandboxes("qa", phqSubdomain),
    ...sandboxes("dev", phqSubdomain)
  };

  return phqFrontendUrls[process.env.REACT_APP_ENVIRONMENT];
};

export const visionURL = visionEnvironments[process.env.REACT_APP_ENVIRONMENT];
export const visionWSRootURL =
  visionWSChannels[process.env.REACT_APP_ENVIRONMENT];
