import React from "react";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const styles = () => ({
  progress: {
    margin: "auto",
    display: "block"
  }
});

class LoadingSpinner extends React.Component {
  render() {
    const {
      classes,
      size,
      containerHeight,
      padding,
      color,
      containerFloat,
      sx
    } = this.props;

    return (
      <Box
        data-qa="loading-spinner"
        style={{ padding, height: containerHeight, float: containerFloat }}
        sx={sx}
      >
        <CircularProgress
          className={classes.progress}
          size={size}
          color={color}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(LoadingSpinner);

LoadingSpinner.defaultProps = {
  size: 80,
  containerHeight: 50,
  padding: "50px 0",
  color: "primary"
};
