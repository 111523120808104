import { serialize } from "../redux/utils/jsona";
import { isIframed } from "../utils/iframe";

export default class JsonApiService {
  // Example: service = new JsonApiService('widgets')
  constructor(resource, options = {}) {
    let {
      baseUrl,
      headers,
      filter,
      include,
      page,
      orderBy,
      orderDirection,
      sort,
      fields,
      customParams
    } = {
      ...JsonApiService.defaultOptions(),
      ...options
    };

    this._resource = resource;
    this._baseUrl = baseUrl;
    this.setHeader(JsonApiService.defaultHeaders());
    this.setHeader(headers);
    this._filter = filter;
    this._page = page;
    this._orderBy = orderBy;
    this._orderDirection = orderDirection;
    this._include = include;
    this._page = page;
    this._sort = sort;
    this._fields = fields;
    this._customParams = customParams;
    this._options = {};

    // Need to duplicate the object to avoid reference issues
    // when using `delete`.
    Object.assign(this._options, options);
    delete this._options.baseUrl;
    delete this._options.headers;
    delete this._options.filter;
    delete this._options.page;
    delete this._options.sort;
    delete this._options.fields;
    delete this._options.include;
    delete this._options.orderBy;
    delete this._options.orderDirection;
    delete this._options.customParams;
  }

  // Make a Fetch API call for all resources.
  async all() {
    const fetchOpts = { ...{ method: "GET" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.collection_url(fetchOpts.method === "GET"),
      {
        ...{ headers: this.headers },
        ...fetchOpts
      }
    );
  }

  // Make a Fetch API call for a single resources.
  async find(id) {
    const fetchOpts = { ...{ method: "GET" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.resource_url(id, fetchOpts.method === "GET"),
      {
        ...{ headers: this.headers },
        ...fetchOpts
      }
    );
  }

  async create(data) {
    const fetchOpts = { ...{ method: "POST" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.collection_url(fetchOpts.method === "POST"),
      {
        ...{ headers: this.headers },
        ...fetchOpts,
        body:
          typeof data === "object"
            ? JSON.stringify(data ? serialize(data) : {})
            : data
      }
    );
  }

  async update(id, resource) {
    const fetchOpts = { ...{ method: "PATCH" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.resource_url(id, fetchOpts.method === "PATCH"),
      {
        ...{ headers: this.headers },
        ...fetchOpts,
        body: resource
      }
    );
  }

  async operations(payload, bulk) {
    const fetchOpts = { ...{ method: "POST" }, ...this._options };
    const url = `${this.collection_url(false)}/operations` + this.queryString;
    return await this._fetchWithMiddleware(url, {
      ...{ headers: this.headers },
      ...fetchOpts,
      body: JSON.stringify({ "atomic:operations": payload, bulk: bulk })
    });
  }

  async bulk_update(resources) {
    const fetchOpts = { ...{ method: "PATCH" }, ...this._options };
    return await this._fetchWithMiddleware(this.collection_url(), {
      ...{ headers: this.headers },
      ...fetchOpts,
      body: resources
    });
  }

  async bulk_delete(resources) {
    const fetchOpts = { ...{ method: "DELETE" }, ...this._options };
    return await this._fetchWithMiddleware(this.collection_url(), {
      ...{ headers: this.headers },
      ...fetchOpts,
      body: resources
    });
  }

  async delete(id) {
    const fetchOpts = { ...{ method: "DELETE" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.resource_url(id, fetchOpts.method === "DELETE"),
      {
        ...{ headers: this.headers },
        ...fetchOpts
      }
    );
  }

  // Make a Fetch API call for one or more related resources as primary data.
  async related(id, relationship) {
    const fetchOpts = { ...{ method: "GET" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.related_url(id, relationship, fetchOpts.method),
      {
        ...{ headers: this.headers },
        ...fetchOpts
      }
    );
  }

  async relatedResource(id, relationship, resourceId) {
    const fetchOpts = { ...{ method: "GET" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.related_resource_url(id, relationship, resourceId, fetchOpts.method),
      {
        ...{ headers: this.headers },
        ...fetchOpts
      }
    );
  }

  async createRelated(id, relationship, resource) {
    const fetchOpts = { ...{ method: "POST" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.related_url(id, relationship, fetchOpts.method),
      {
        ...{ headers: this.headers },
        ...fetchOpts,
        body: resource
      }
    );
  }

  async updateRelatedResource(id, relationship, resourceId, resource) {
    const fetchOpts = { ...{ method: "PATCH" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.related_resource_url(id, relationship, resourceId, fetchOpts.method),
      {
        ...{ headers: this.headers },
        ...fetchOpts,
        body: resource
      }
    );
  }

  async updateRelated(id, relationship, resource) {
    const fetchOpts = { ...{ method: "PATCH" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.related_url(id, relationship, fetchOpts.method),
      {
        ...{ headers: this.headers },
        ...fetchOpts,
        body: resource
      }
    );
  }

  async createRelationships(id, relationshipKey, relationships) {
    const fetchOpts = { ...{ method: "POST" }, ...this._options };
    return await this._fetchWithMiddleware(
      this.relationships_url(id, relationshipKey, fetchOpts.method),
      {
        ...{ headers: this.headers },
        ...fetchOpts,
        body: relationships
      }
    );
  }

  // set the current column to sort by
  orderBy(orderBy) {
    this._orderBy = orderBy;
    return this;
  }

  // set the current number of results per page if using pagination
  orderDirection(orderDirection) {
    this._orderDirection = orderDirection;
    return this;
  }

  // Modify the filter object
  // Example: service.where({active: true}).all()
  // This method is chainable.
  where(filters) {
    // Remove empty filters
    if (filters) {
      const _filters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => !!v)
      );
      this._filter = { ...this.filter, ..._filters };
    }
    return this;
  }

  // Modify the page object
  // Example: service.page({number: 1, size: 20}).all()
  // This method is chainable.
  page(pagination) {
    this._page = { ...this._page, ...pagination };
    return this;
  }

  // Modify the sort parameter
  // Example: service.sort("-name").all()
  // This method is chainable.
  sort(sort) {
    this._sort = sort;
    return this;
  }

  // You can add your custom param to a route
  // Example: service.customParam({ paramKey: paramValue, paramKey2: paramValue2 }).all()
  // This method is chainable
  customParams(customParams) {
    if (customParams) {
      // Remove empty filters
      const _customParams = Object.fromEntries(
        Object.entries(customParams).filter(([_, v]) => !!v)
      );
      this._customParams = { ...this._customParams, ..._customParams };
    }
    return this;
  }

  // Modify the include object
  // Example: service.include('widegets')
  // or service.include(['widegets', 'widgets.widget_group'])
  // This method is chainable.
  include(include) {
    if (!Array.isArray(include)) {
      include = [include];
    }

    this._include = this._include.concat(include).filter((v, i, a) => {
      return a.indexOf(v) === i;
    });

    return this;
  }

  // Modify the fields object
  // Examples:
  // service.fields(["kind", "description"])
  // service.fields("description")
  // service.fields(["kind", "description"], "widgets")
  // service.include("features").fields({
  //   widgets: ["kind", "description"],
  //   features: ["foo", "bar"]
  // })
  // This method is chainable.
  fields(fields, type = false) {
    if (type === false) {
      type = this._resource;
    }
    if (typeof fields === "string") {
      fields = [fields];
    }
    if (Array.isArray(fields)) {
      fields = { [type]: fields };
    }
    this._fields = { ...this._fields, ...fields };
    return this;
  }

  // Return the options passed to `fetch()`.
  get fetchOptions() {
    return this._options;
  }

  // Replace all Fetch API options passed to `fetch()`.
  // This method is chainable.
  set fetchOptions(value) {
    this._options = value;
    return this;
  }

  // Add or replace a Fetch API option passed to `fetch()`.
  // This method is chainable.
  setFetchOption(option, value) {
    if (typeof option === "string") {
      option = { [option]: value };
    }

    this._options = { ...this._options, ...option };
    return this;
  }

  // Remove a Fetch API option passed to `fetch()`.
  // This method is chainable.
  deleteFetchOption(key) {
    delete this._options[key];
    return this;
  }

  // Return the headers passed to `fetch()`.
  get headers() {
    return this._headers;
  }

  // Replace all Fetch API headers.
  set headers(value) {
    return (this._headers = value);
  }

  // Add or replace a Fetch API header(s) passed to `fetch()`.
  // This method is chainable.
  setHeader(header, value) {
    if (typeof header === "string") {
      header = { [header]: value };
    }

    this._headers = { ...this._headers, ...header };
    return this;
  }

  // Remove a Fetch API header.
  // This method is chainable.
  deleteHeader(key) {
    delete this._headers[key];
    return this;
  }

  // Convenience method to get the token header.
  // Removes credentials Fetch API option if set.
  // This method is chainable.
  setAuthToken(token) {
    delete this._options.credentials;
    this.setHeader("Authorization", `Bearer ${token}`);
    return this;
  }

  // Returns the URL for the colletion endpoint.
  collection_url(queryString = true) {
    return this._maybeAppendQueryString(
      `${this.baseUrl}/${this._resource}`,
      queryString
    );
  }

  resource_url(id, queryString = true) {
    return this._maybeAppendQueryString(
      `${this.collection_url(false)}/${id}`,
      queryString
    );
  }

  related_url(id, relationship, queryString = true) {
    return this._maybeAppendQueryString(
      `${this.resource_url(id, false)}/${relationship}`,
      queryString
    );
  }

  relationships_url(id, relationship, queryString = true) {
    return this._maybeAppendQueryString(
      `${this.resource_url(id, false)}/relationships/${relationship}`,
      queryString
    );
  }

  related_resource_url(
    id,
    relationship,
    relatedResourceId,
    queryString = true
  ) {
    return this._maybeAppendQueryString(
      `${this.collection_url(
        false
      )}/${id}/${relationship}/${relatedResourceId}`,
      queryString
    );
  }

  get baseUrl() {
    return this._baseUrl;
  }

  // Build the query string based on the current state.
  // Prepends a `?` if the string is not blank.
  get queryString() {
    let params = {};
    let queryString = "";

    if (this._hasIncluded()) {
      params.include = this._include.join(",");
    }

    if (this._hasFilters()) {
      Object.keys(this._filter).forEach(key => {
        params[`filter[${key}]`] = encodeURIComponent(this._filter[key]);
      });
    }

    if (this._hasPage()) {
      params.page = this._page;
    }

    if (this._hasOrderBy()) {
      params.orderBy = this._orderBy;
    }

    if (this._hasOrderDirection()) {
      params.orderDirection = this._orderDirection;
    }

    if (this._hasSorting()) {
      params.sort = this._sort;
    }

    if (this._hasCustomParams()) {
      Object.keys(this._customParams).forEach(key => {
        params[key] = this._customParams[key];
      });
    }

    if (this._hasPagination()) {
      Object.keys(this._page).forEach(key => {
        if (this._page[key] !== undefined) {
          params[`page[${key}]`] = this._page[key];
        }
      });
    }

    if (this._hasFields()) {
      Object.keys(this._fields).forEach(key => {
        params[`fields[${key}]`] = this._fields[key].join(",");
      });
    }

    queryString = Object.keys(params)
      .map(key => {
        return `${key}=${params[key]}`;
      })
      .join("&");

    if (queryString.length > 0) {
      return `?${queryString}`;
    }

    return queryString;
  }

  static defaultOptions() {
    return {
      baseUrl: "http://localhost:3002",
      filter: {},
      include: [],
      page: {},
      fields: {}
    };
  }

  static defaultHeaders() {
    return {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json"
    };
  }

  // Private
  _hasKeys(obj) {
    return !!Object.keys(obj).length > 0;
  }

  _hasFilters() {
    return this._hasKeys(this._filter);
  }

  _hasPage() {
    return Number.isInteger(this._page);
  }

  _hasOrderBy() {
    return !!this._orderBy;
  }

  _hasOrderDirection() {
    return !!this._orderDirection;
  }

  _hasIncluded() {
    return this._include.length > 0;
  }

  _hasPagination() {
    return this._hasKeys(this._page);
  }

  _hasSorting() {
    return !!this._sort;
  }

  _hasCustomParams() {
    return !!this._customParams;
  }

  _hasFields() {
    return this._hasKeys(this._fields);
  }

  _maybeAppendQueryString(url, append = true) {
    return append ? `${url}${this.queryString}` : url;
  }

  async _fetchWithMiddleware(url, options) {
    const response = await fetch(url, {
      ...options
    });

    const unauthorized = response.status === 401;

    if (isIframed && unauthorized) {
      window.parent.postMessage("sessionTimeout", "*");
    }

    return response;
  }
}
