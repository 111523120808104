import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_REPORT_DATA_BEGIN = "FETCH_REPORT_DATA_BEGIN";
export const FETCH_REPORT_DATA_SUCCESS = "FETCH_REPORT_DATA_SUCCESS";
export const FETCH_REPORT_DATA_FAILURE = "FETCH_REPORT_DATA_FAILURE";

export const UPDATE_FILTER_VALUE = "UPDATE_FILTER_VALUE";

export const updateFilterValue = tableFilter => ({
  type: UPDATE_FILTER_VALUE,
  payload: { tableFilter }
});

const fetchReportDataBegin = () => ({
  type: FETCH_REPORT_DATA_BEGIN
});

const fetchReportDataSuccess = records => ({
  type: FETCH_REPORT_DATA_SUCCESS,
  payload: { records }
});

const fetchReportDataFailure = error => ({
  type: FETCH_REPORT_DATA_FAILURE,
  payload: { error }
});

export const fetchReportData = (queryData, filter) => dispatch => {
  let apiUrl = `${visionURL}/learning_center/api/performance/transcript_reports?`;

  if (filter && filter !== "Timeframe") {
    apiUrl += `time_range=${filter}`;
  }

  apiUrl += visionCallService.buildTableDataQueryString(queryData);

  return new Promise((resolve, reject) => {
    dispatch(fetchReportDataBegin());
    fetch(apiUrl, {
      method: "GET",
      credentials: "include"
    })
      .then(response => {
        visionCallService.redirectForAuth(response);
        return response.json();
      })
      .then(response => {
        const result = {
          data: visionCallService.extractAttributes(response),
          page: response.meta ? parseInt(response.meta.current_page) - 1 : 0,
          totalCount: response.meta ? response.meta.total_count : 0
        };
        dispatch(fetchReportDataSuccess(result));
        return resolve(result);
      })
      .catch(error => {
        dispatch(fetchReportDataFailure(error));
        reject(error);
      });
  });
};
