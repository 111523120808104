export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const addModal = modalData => dispatch => {
  return dispatch({
    type: OPEN_MODAL,
    payload: modalData
  });
};

export const removeModal = modalData => dispatch => {
  return dispatch({
    type: CLOSE_MODAL,
    payload: modalData
  });
};
