import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_USER_BEGIN = "FETCH_USER_BEGIN";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN
});

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: { user }
});

export const fetchUserFailure = error => ({
  type: FETCH_USER_FAILURE,
  payload: { error }
});

export function fetchUser(params) {
  return dispatch => {
    dispatch(fetchUserBegin());
    return fetchCurrentUser(
      function(user) {
        dispatch(fetchUserSuccess(user));
      },
      function(error) {
        dispatch(fetchUserFailure(error));
      },
      { include: "roles", ...params }
    );
  };
}

const fetchCurrentUser = (successCallback, errorCallback, params) => {
  visionCallService.fetchRequest(
    `${visionURL}/learning_center/api/users`,
    function(currentUser) {
      localStorage.setItem("policyPartnerUrl", currentUser.policyPartnerUrl);
      successCallback(currentUser);
      return currentUser;
    },
    function(error) {
      console.log("request failed", error);
      errorCallback(error);
    },
    params
  );
};

export const updateUserBegin = () => ({
  type: UPDATE_USER_BEGIN
});

export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS
});

export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  payload: { error }
});

export const updateUserSettings = (userId, params) => {
  return dispatch => {
    dispatch(updateUserBegin());
    return visionCallService.postJsonDataRequest(
      `${visionURL}/learning_center/api/users/${userId}`,
      { id: userId, user: params },
      () => dispatch(updateUserSuccess()),
      error => dispatch(updateUserFailure(error))
    );
  };
};
