import {
  FETCH_USER_PROFILE_BEGIN,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_BEGIN,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_PICTURE_BEGIN,
  UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  UPDATE_USER_PROFILE_PICTURE_FAILURE
} from "../actions/userProfileActions";

const INITIAL_STATE = {
  userProfile: null,
  loading: false,
  error: null,
  updating: false
};

function UserProfileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_PROFILE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        userProfile: null,
        loading: true,
        error: null
      };

    case FETCH_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        userProfile: action.payload.userProfile
      };
    }

    case FETCH_USER_PROFILE_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        userProfile: null
      };

    case UPDATE_USER_PROFILE_BEGIN:
      return {
        ...state,
        updating: true,
        error: null
      };

    case UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        updating: false,
        userProfile: action.payload.userProfile
      };
    }

    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload.error
      };

    case UPDATE_USER_PROFILE_PICTURE_BEGIN:
      return {
        ...state,
        updating: true,
        error: null
      };

    case UPDATE_USER_PROFILE_PICTURE_SUCCESS: {
      let userProfile = { ...state.userProfile };
      userProfile.avatar = action.payload.userProfilePictureUrl;
      return {
        userProfile: userProfile,
        loading: false,
        error: null,
        updating: false
      };
    }

    case UPDATE_USER_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload.error
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default UserProfileReducer;
