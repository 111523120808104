import {
  FETCH_PUBLIC_BEGIN,
  FETCH_PUBLIC_SUCCESS,
  FETCH_PUBLIC_FAILURE
} from "../actions/publicActions";

const INITIAL_STATE = {
  publicData: {},
  loading: true,
  fetched: false,
  error: null
};

export default function PublicReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PUBLIC_BEGIN:
      return {
        ...state,
        fetched: false,
        loading: true,
        error: null
      };

    case FETCH_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        publicData: action.payload.publicData
      };

    case FETCH_PUBLIC_FAILURE:
      return {
        ...state,
        loading: false,
        fetched: false,
        error: action.payload.error,
        publicData: {}
      };
    default:
      return state;
  }
}
