export const SET_EXPANDED_LIST = "SET_EXPANDED_LIST";

export const setExpandedListAttribute = (key, value) => ({
  type: SET_EXPANDED_LIST,
  payload: { key, value }
});

export function setExpandedList(key, value) {
  return dispatch => {
    dispatch(setExpandedListAttribute(key, value));
  };
}
