import React, { useEffect, useState } from "react";
import { useGoogleMapsApiKey } from "../../hooks/apiHooks/googleMaps";

const GoogleMapWrapper = ({ children }) => {
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState("");
  const { getGoogleMapsApiKey } = useGoogleMapsApiKey();

  const fetchGoogleMapsApiKey = () => {
    getGoogleMapsApiKey().then(key => {
      return setGoogleMapsApiKey(key["google_maps_key"]);
    });
  };

  useEffect(() => {
    fetchGoogleMapsApiKey();
  }, []);

  useEffect(() => {
    if (googleMapsApiKey) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      document.body.appendChild(script);
    }
  }, [googleMapsApiKey]);

  return <div>{children}</div>;
};

export default GoogleMapWrapper;
