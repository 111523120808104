import {
  FETCH_CUSTOMER_APPS_BEGIN,
  FETCH_CUSTOMER_APPS_SUCCESS,
  FETCH_CUSTOMER_APPS_FAILURE,
  CREATE_CUSTOMER_APP_BEGIN,
  CREATE_CUSTOMER_APP_SUCCESS,
  CREATE_CUSTOMER_APP_FAILURE,
  UPDATE_CUSTOMER_APP_BEGIN,
  UPDATE_CUSTOMER_APP_SUCCESS,
  UPDATE_CUSTOMER_APP_FAILURE,
  DELETE_CUSTOMER_APP_BEGIN,
  DELETE_CUSTOMER_APP_SUCCESS,
  DELETE_CUSTOMER_APP_FAILURE
} from "../actions/customerAppsActions";

const INITIAL_STATE = {
  apps: null,
  loading: false,
  fetched: false,
  updating: false,
  creating: false,
  deleting: false,
  error: null
};

function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_APPS_BEGIN:
      return {
        ...state,
        fetched: false,
        loading: true,
        error: null
      };

    case FETCH_CUSTOMER_APPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        fetched: true,
        apps: action.payload.customerApps
      };
    }

    case FETCH_CUSTOMER_APPS_FAILURE:
      return {
        ...state,
        loading: false,
        fetched: false,
        error: action.payload.error,
        apps: null
      };

    case CREATE_CUSTOMER_APP_BEGIN:
      return {
        ...state,
        creating: true,
        error: null
      };

    case CREATE_CUSTOMER_APP_SUCCESS: {
      return {
        ...state,
        creating: false,
        apps: action.payload.customerApps
      };
    }

    case CREATE_CUSTOMER_APP_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.payload.error
      };

    case UPDATE_CUSTOMER_APP_BEGIN:
      return {
        ...state,
        updating: true,
        error: null
      };

    case UPDATE_CUSTOMER_APP_SUCCESS: {
      return {
        ...state,
        updating: false,
        apps: action.payload.customerApps
      };
    }

    case UPDATE_CUSTOMER_APP_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload.error
      };

    case DELETE_CUSTOMER_APP_BEGIN:
      return {
        ...state,
        deleting: true,
        error: null
      };

    case DELETE_CUSTOMER_APP_SUCCESS: {
      return {
        ...state,
        deleting: false,
        apps: action.payload.customerApps
      };
    }

    case DELETE_CUSTOMER_APP_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.payload.error
      };

    default:
      return state;
  }
}

export default UserReducer;
