import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const defaultPrimaryColor = "#0f6ab3";
const defaultSecondaryColor = "#2e3192";

const useCustomerColors = () => {
  const [customerColors, setCustomerColors] = useState({
    primaryColor: defaultPrimaryColor,
    secondaryColor: defaultSecondaryColor
  });

  const { primaryColor, secondaryColor } = useSelector(state => ({
    primaryColor: state.learningCenterSettingsState.customColor,
    secondaryColor: state.learningCenterSettingsState.secondaryColor
  }));

  useEffect(() => {
    const storedPrimaryColor =
      localStorage.getItem("customerPrimaryColor") || defaultPrimaryColor;
    const storedSecondaryColor =
      localStorage.getItem("customerSecondaryColor") || defaultSecondaryColor;

    if (
      (primaryColor && primaryColor !== storedPrimaryColor) ||
      (secondaryColor && secondaryColor !== storedSecondaryColor)
    ) {
      localStorage.setItem("customerPrimaryColor", primaryColor);
      localStorage.setItem("customerSecondaryColor", secondaryColor);

      setCustomerColors({ primaryColor, secondaryColor });
    } else {
      setCustomerColors({
        primaryColor: storedPrimaryColor,
        secondaryColor: storedSecondaryColor
      });
    }
  }, [primaryColor, secondaryColor]);

  return customerColors;
};

export default useCustomerColors;
