import { merge } from "lodash";
import {
  FETCH_GROUPS_BEGIN,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  FETCH_LOCATIONS_BEGIN,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_POSITIONS_BEGIN,
  FETCH_POSITIONS_FAILURE,
  FETCH_POSITIONS_SUCCESS
} from "../../actions/adminCustomerActions";

const INITIAL_STATE = {
  position: {},
  group: {},
  location: {},
  loading: false,
  error: null
};

export default function adminCustomerReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_GROUPS_BEGIN:
    case FETCH_LOCATIONS_BEGIN:
    case FETCH_POSITIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_GROUPS_SUCCESS:
    case FETCH_LOCATIONS_SUCCESS:
    case FETCH_POSITIONS_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        error: null
      };
    case FETCH_GROUPS_FAILURE:
    case FETCH_LOCATIONS_FAILURE:
    case FETCH_POSITIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    default:
      return state;
  }
}
