import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUPS_BEGIN = "FETCH_GROUPS_BEGIN";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";

export const FETCH_POSITIONS = "FETCH_POSITIONS";
export const FETCH_POSITIONS_BEGIN = "FETCH_POSITIONS_BEGIN";
export const FETCH_POSITIONS_SUCCESS = "FETCH_POSITIONS_SUCCESS";
export const FETCH_POSITIONS_FAILURE = "FETCH_POSITIONS_FAILURE";

export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const FETCH_LOCATIONS_BEGIN = "FETCH_LOCATIONS_BEGIN";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";

const fetchGroupsBegin = () => ({
  type: FETCH_GROUPS_BEGIN
});

const fetchGroupsSuccess = payload => ({
  type: FETCH_GROUPS_SUCCESS,
  payload
});

const fetchGroupsFailure = error => ({
  type: FETCH_GROUPS_FAILURE,
  payload: { error }
});

export const fetchGroups = () => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/administration/groups`;
  dispatch(fetchGroupsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchGroupsSuccess(response));
    },
    function(error) {
      dispatch(fetchGroupsFailure(error));
    }
  );
};

const fetchPositionsBegin = () => ({
  type: FETCH_POSITIONS_BEGIN
});

const fetchPositionsSuccess = payload => ({
  type: FETCH_POSITIONS_SUCCESS,
  payload
});

const fetchPositionsFailure = error => ({
  type: FETCH_POSITIONS_FAILURE,
  payload: { error }
});

export const fetchPositions = () => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/administration/positions`;
  dispatch(fetchPositionsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchPositionsSuccess(response));
    },
    function(error) {
      dispatch(fetchPositionsFailure(error));
    }
  );
};

const fetchLocationsBegin = () => ({
  type: FETCH_LOCATIONS_BEGIN
});

const fetchLocationsSuccess = payload => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload
});

const fetchLocationsFailure = error => ({
  type: FETCH_LOCATIONS_FAILURE,
  payload: { error }
});

export const fetchLocations = () => dispatch => {
  let apiUrl = `${visionURL}/admin_console/api/administration/locations`;
  dispatch(fetchLocationsBegin());
  return visionCallService.requestWithNormalizer(
    apiUrl,
    function(response) {
      dispatch(fetchLocationsSuccess(response));
    },
    function(error) {
      dispatch(fetchLocationsFailure(error));
    }
  );
};
