export const appPrefix = "learning-center";
export const root = `/${appPrefix}`;
export const announcements = `${root}/announcements`;
export const apps = `${root}/my-apps`;
export const calendar = `${root}/calendar`;
export const dashboard = `${root}/dashboard`;
export const learn = `${root}/learn`;
export const librarySearch = `${root}/library-search`;
export const contentViewer = `${root}/attempts/:user_assignment_id/activities/:course_contentable_id`;
export const courseOverview = `${root}/course-overview/:assignedTrainingObjectId/:versionId`;
export const coursePlayer = `${root}/courses/:id`;
export const messages = `${root}/messages`;
export const performance = `${root}/performance`;
export const profile = `${root}/profile`;
export const resourceViewer = `${root}/resource-sections/:sectionId/items/:itemId`;
export const resources = `${root}/resources`;
export const settings = `${root}/settings`;
export const policyPlayerResourceViewer = `${root}/policy-player/items/:itemId`;

export const learnLibrary = `${learn}/library`;
export const learnLearningPath = `${learn}/my-learning-path`;

export const performanceAchievements = `${performance}/achievements`;
export const performanceTranscript = `${performance}/transcript_report`;
export const performanceLeaderboard = `${performance}/leaderboard`;

export const learnLearningPathListView = `${learnLearningPath}/list-view`;
export const learnLearningPathPathView = `${learnLearningPath}/path-view`;

export const learnLibraryAllCourses = `${learnLibrary}/all-courses`;
export const learnLibraryAllPolicies = `${learnLibrary}/all-policies`;
export const learnLibraryPolicies = `${learnLibrary}/policies`;
export const learnLibraryMyItems = `${learnLibrary}/my-items`;

export const libraryAllCoursesHome = `${learnLibraryAllCourses}/home`;
export const libraryAllCoursesOnline = `${learnLibraryAllCourses}/online-courses`;
export const libraryAllCoursesWebinar = `${learnLibraryAllCourses}/webinar`;
export const libraryAllCoursesVirtual = `${learnLibraryAllCourses}/virtual-classroom`;
export const libraryAllCoursesInPerson = `${learnLibraryAllCourses}/in-person`;
export const libraryAllCoursesRecommended = `${learnLibraryAllCourses}/recommended`;
export const libraryAllCoursesFavorites = `${learnLibraryAllCourses}/favorites`;

export const libraryMyItemsMyCourses = `${learnLibraryMyItems}/my-courses`;
export const libraryMyItemsMyPolicies = `${learnLibraryMyItems}/my-policies`;

export const announcementsList = `${announcements}/list`;
export const announcementsCreate = `${announcements}/create`;

export const buildCoursePlayerRoute = courseId => `${root}/courses/${courseId}`;

export const buildLibrarySearchRoute = searchParam =>
  `${librarySearch}?search=${searchParam}`;

export const buildCourseOverviewRoute = (assignedTrainingObjectId, versionId) =>
  `${root}/course-overview/${assignedTrainingObjectId}/${versionId}`;

export const buildContentViewerRoute = (userAssignmentId, courseContentId) =>
  `${root}/attempts/${userAssignmentId}/activities/${courseContentId}`;

export const buildResourceViewerRoute = (sectionId, itemId) =>
  `${root}/resource-sections/${sectionId}/items/${itemId}`;

export const buildPolicyPlayerResourceViewerRoute = itemId =>
  `${root}/policy-player/items/${itemId}`;

export const buildMyItemsCalendarRoute = (
  assignmentId,
  trainingObjectId,
  enrolledTrainingObjectId
) => {
  let route = `${root}/learning-center/learn/library/my-items/my-courses?assignment=${assignmentId}&training_object=${trainingObjectId}`;
  route = enrolledTrainingObjectId
    ? route + `&enrolled_training_object=${enrolledTrainingObjectId}`
    : route;
  return route;
};

export const routePermissions = {
  performanceLeaderboard: "allowPerformanceLeaderboard",
  learnLearningPath: "allowLearningPathAssignments",
  learnLibrary: "allowLibraryAccess",
  settings: "isAdmin",
  libraryAllCoursesWebinar: "allowAppsView",
  libraryAllCoursesVirtual: "platinum",
  libraryAllCoursesInPerson: "allowAppsView"
};
