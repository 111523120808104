import {
  SHOW_CONTENT_PLAYER_SPINNER,
  HIDE_CONTENT_PLAYER_SPINNER
} from "../../actions/learningCenter/contentPlayerActions";

const INITIAL_STATE = {
  showSpinner: false
};

function contentPlayerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_CONTENT_PLAYER_SPINNER:
      return {
        ...state,
        showSpinner: true
      };
    case HIDE_CONTENT_PLAYER_SPINNER:
      return {
        ...state,
        showSpinner: false
      };
    default:
      return state;
  }
}

export default contentPlayerReducer;
