import visionCallService from "../../../../services/visionCallService";
import { uploadFile } from "../../../../services/fileUploadService";
import { visionURL } from "../../../../config/environment";
import { enqueueErrorSnackbar } from "../../snackbarActions";

export const ADD_UPLOAD_AC_COURSE_BUILDER_BEGIN =
  "ADD_UPLOAD_AC_COURSE_BUILDER_BEGIN";
export const ADD_UPLOAD_AC_COURSE_BUILDER_SUCCESS =
  "ADD_UPLOAD_AC_COURSE_BUILDER_SUCCESS";
export const ADD_UPLOAD_AC_COURSE_BUILDER_FAILURE =
  "ADD_UPLOAD_AC_COURSE_BUILDER_FAILURE";
export const MARK_JOB_DONE_AC_COURSE_BUILDER_SUCCESS =
  "MARK_JOB_DONE_AC_COURSE_BUILDER_SUCCESS";
export const FETCH_ITEM_AC_COURSE_BUILDER_BEGIN =
  "FETCH_ITEM_AC_COURSE_BUILDER_BEGIN";
export const FETCH_ITEM_AC_COURSE_BUILDER_SUCCESS =
  "FETCH_ITEM_AC_COURSE_BUILDER_SUCCESS";
export const FETCH_ITEM_AC_COURSE_BUILDER_FAILURE =
  "FETCH_ITEM_AC_COURSE_BUILDER_FAILURE";
export const UPDATE_ITEM_AC_COURSE_BUILDER_BEGIN =
  "UPDATE_ITEM_AC_COURSE_BUILDER_BEGIN";
export const UPDATE_ITEM_AC_COURSE_BUILDER_SUCCESS =
  "UPDATE_ITEM_AC_COURSE_BUILDER_SUCCESS";
export const UPDATE_ITEM_AC_COURSE_BUILDER_FAILURE =
  "UPDATE_ITEM_AC_COURSE_BUILDER_FAILURE";

const addUploadBegin = () => ({
  type: ADD_UPLOAD_AC_COURSE_BUILDER_BEGIN
});

const addUploadSuccess = result => ({
  type: ADD_UPLOAD_AC_COURSE_BUILDER_SUCCESS,
  payload: { result }
});

const addUploadFailure = error => ({
  type: ADD_UPLOAD_AC_COURSE_BUILDER_FAILURE,
  payload: { error }
});

export const addUpload = (
  courseId,
  shortCourseId,
  data,
  successCallback
) => dispatch => {
  dispatch(addUploadBegin());
  uploadFile(data.file)
    .then(response => {
      const dataCopy = {
        ...data,
        file: undefined,
        fileId: response.fileId
      };
      delete dataCopy.file;
      return visionCallService.postFormDataRequest(
        `${visionURL}/admin_console/api/course_builder/course_sections/${shortCourseId}/uploads`,
        visionCallService.normalizeFormData(dataCopy),
        response => {
          dispatch(addUploadSuccess(response));
          successCallback(response);
        },
        error => dispatch(addUploadFailure(error))
      );
    })
    .catch(reason => dispatch(addUploadFailure(reason)));
};

export const markJobFinished = () => dispatch => {
  dispatch({
    type: MARK_JOB_DONE_AC_COURSE_BUILDER_SUCCESS,
    payload: {}
  });
};

const fetchItemBegin = () => ({
  type: FETCH_ITEM_AC_COURSE_BUILDER_BEGIN
});

const fetchItemSuccess = item => ({
  type: FETCH_ITEM_AC_COURSE_BUILDER_SUCCESS,
  payload: { item }
});

const fetchItemFailure = error => ({
  type: FETCH_ITEM_AC_COURSE_BUILDER_FAILURE,
  payload: { error }
});

export const fetchItem = (
  courseId,
  sectionId,
  itemId,
  successCallback
) => dispatch => {
  dispatch(fetchItemBegin);
  return visionCallService.fetchRequest(
    `${visionURL}/admin_console/api/content/courses/${courseId}/course_sections/${sectionId}/activities/${itemId}/edit`,
    response => {
      dispatch(fetchItemSuccess(response));
      successCallback();
    },
    error => dispatch(fetchItemFailure(error))
  );
};

const updateItemBegin = () => ({
  type: UPDATE_ITEM_AC_COURSE_BUILDER_BEGIN
});

const updateItemSuccess = result => ({
  type: UPDATE_ITEM_AC_COURSE_BUILDER_SUCCESS,
  payload: { result }
});

const updateItemFailure = error => ({
  type: UPDATE_ITEM_AC_COURSE_BUILDER_FAILURE,
  payload: { error }
});

export const updateItem = (
  courseId,
  sectionId,
  itemId,
  data,
  successCallback,
  errorCallback
) => dispatch => {
  const updateItemRequest = data =>
    visionCallService.putFormDataRequest(
      `${visionURL}/admin_console/api/content/courses/${courseId}/course_sections/${sectionId}/activities/${itemId}`,
      visionCallService.normalizeFormData(data),
      response => {
        if (response.errors && response.errors.length > 0) {
          dispatch(updateItemFailure(response));
          if (errorCallback && typeof errorCallback === "function") {
            errorCallback(response.errors);
          }
          response.errors.forEach(x =>
            dispatch(enqueueErrorSnackbar({ message: x.title }))
          );
        } else {
          dispatch(updateItemSuccess(response));
          successCallback(response);
        }
      },
      error => {
        dispatch(updateItemFailure(error));
        if (errorCallback && typeof errorCallback === "function") {
          errorCallback(error);
        }
      }
    );

  dispatch(updateItemBegin());

  if (!data.file) {
    return updateItemRequest(data);
  }

  uploadFile(data.file)
    .then(response => {
      const dataCopy = {
        ...data,
        file: undefined,
        fileId: response.fileId
      };
      delete dataCopy.file;
      return updateItemRequest(dataCopy);
    })
    .catch(reason => dispatch(updateItemFailure(reason)));
};
