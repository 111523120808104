import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_PUBLIC_BEGIN = "FETCH_PUBLIC_BEGIN";
export const FETCH_PUBLIC_SUCCESS = "FETCH_PUBLIC_SUCCESS";
export const FETCH_PUBLIC_FAILURE = "FETCH_PUBLIC_FAILURE";

export const fetchPublicDataBegin = () => ({
  type: FETCH_PUBLIC_BEGIN
});

export const fetchPublicDataSuccess = publicData => ({
  type: FETCH_PUBLIC_SUCCESS,
  payload: { publicData }
});

export const fetchPublicDataFailure = error => ({
  type: FETCH_PUBLIC_FAILURE,
  payload: { error }
});

export function fetchPublicData() {
  return dispatch => {
    dispatch(fetchPublicDataBegin());
    return visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/public`,
      function(data) {
        dispatch(fetchPublicDataSuccess(data));
      },
      function(error) {
        dispatch(fetchPublicDataFailure(error));
      }
    );
  };
}
