import { visionURL } from "../config/environment";
import visionCallService from "./visionCallService";
import CryptoJS from "crypto-js";
import { fetch } from "whatwg-fetch";

function getLinkForFile(id, filename) {
  return `${visionURL}/rails/active_storage/blobs/${id}/${filename}?disposition=attachment`;
}

export async function filesPreloading(filesList, content) {
  let imagesList = filesList.filter(file => file.type === "image");
  let mediaList = filesList.filter(file => file.type === "media");
  let attachmentsList = filesList.filter(file => file.type === "file");
  let images = await uploadFiles(content, imagesList);
  let mediaFiles = await uploadFiles(content, mediaList);
  let attachments = await uploadFiles(content, attachmentsList);
  let tmpContent = content;

  images.forEach(image => {
    tmpContent = tmpContent.replace(
      image.tempLink,
      getLinkForFile(image.id, image.filename)
    );
  });
  mediaFiles.forEach(media_file => {
    tmpContent = tmpContent.replace(
      media_file.tempLink,
      getLinkForFile(media_file.id, media_file.filename)
    );
  });
  attachments.forEach(attachment => {
    tmpContent = tmpContent.replace(
      attachment.tempLink,
      getLinkForFile(attachment.id, attachment.filename)
    );
  });

  let tmpObj = {
    content: tmpContent
  };

  tmpObj.images = images.map(image => {
    return image.id;
  });
  tmpObj.media = mediaFiles.map(media_file => {
    return media_file.id;
  });
  tmpObj.attachments = attachments.map(attachment => {
    return attachment.id;
  });

  return tmpObj;
}

const uploadFiles = async (content, filesList) => {
  let filesToUpload = filesList.filter(
    file =>
      content.indexOf(file.tempLink) > 0 ||
      content.indexOf(
        "data:" + file.fileObj.blob().type + ";base64," + file.fileObj.base64()
      ) > 0
  );

  filesToUpload.forEach(file => {
    let blobStr =
      "data:" + file.fileObj.blob().type + ";base64," + file.fileObj.base64();

    if (content.indexOf(blobStr) > 0) {
      content = content.replace(blobStr, file.tempLink);
    }
  });

  return await Promise.all(filesToUpload.map(file => directUploadFile(file)));
};

export const uploadFile = async (
  file,
  directUploadUrl = `${visionURL}/rails/active_storage/direct_uploads`,
  token
) => {
  const checksum = await getChecksum(file);
  let headers = {
    "Content-Type": "application/json"
  };
  if (!!token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  return fetch(directUploadUrl, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify({
      blob: {
        filename: file.name,
        content_type: file.type,
        byte_size: file.size,
        checksum
      }
    })
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(async function(response) {
      const uploadResponse = await upload(response.direct_upload, file);
      if (uploadResponse.ok) {
        return {
          path: uploadResponse.url,
          contentType: file.type,
          fileName: file.name,
          fileId: response.id
        };
      } else {
        if (!!response.key) {
          cleanup(response.key);
        }
        throw uploadResponse;
      }
    });
};

/**
 * TODO: refactor & combine with uploadFile method to reduce code duplication
 */
async function directUploadFile(file) {
  let blobInfo = file.fileObj;
  let blob = blobInfo.blob();
  let base64_string = CryptoJS.MD5(
    CryptoJS.enc.Latin1.parse(atob(blobInfo.base64()))
  ).toString(CryptoJS.enc.Base64);
  return fetch(`${visionURL}/rails/active_storage/direct_uploads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({
      blob: {
        filename: blobInfo.filename(),
        content_type: blob.type,
        byte_size: blob.size,
        checksum: base64_string
      }
    })
  })
    .then(response => {
      visionCallService.redirectForAuth(response);
      return response.json();
    })
    .then(async function(response) {
      await upload(response.direct_upload, blobInfo.blob());
      return {
        id: response.signed_id,
        tempLink: file.tempLink,
        filename: blobInfo.filename()
      };
    })
    .catch(function(error) {
      console.error("request failed", error);
    });
}

function upload(directUploadInfo, blob) {
  const headers = directUploadInfo.headers;

  return fetch(directUploadInfo.url, {
    method: "PUT",
    headers: headers,
    body: blob
  });
}

function cleanup(blobId) {
  return fetch(`${visionURL}/rails/active_storage/direct_uploads/${blobId}`, {
    method: "DELETE",
    credentials: "include"
  });
}

const getChecksum = file =>
  new Promise((resolve, reject) => {
    let md5 = CryptoJS.algo.MD5.create();
    processChunked(
      file,
      chunk => {
        md5.update(CryptoJS.enc.Latin1.parse(atob(chunk)));
      },
      err => {
        if (err) {
          reject(err);
        } else {
          const checkSum = md5.finalize().toString(CryptoJS.enc.Base64);
          resolve(checkSum);
        }
      }
    );
  });

const processChunked = (file, progressingCallback, endCallback) => {
  const chunkSize = 4 * 1024 * 1024;
  const fileSize = file.size;
  let offset = 0;
  const reader = new FileReader();

  const loadFragment = () => {
    const end =
      offset + chunkSize - 1 > fileSize ? fileSize : offset + chunkSize - 1;
    const fileSlice = file.slice(offset, end);
    reader.readAsDataURL(fileSlice);
    return end;
  };

  reader.onload = () => {
    if (reader.error) {
      console.log("reader.error", reader.error);
      endCallback(reader.error || {});
      return;
    }
    progressingCallback(reader.result.replace("data:", "").replace(/^.+,/, ""));
    if (offset >= fileSize) {
      endCallback(null);
    } else {
      offset = loadFragment();
    }
  };
  reader.onerror = error => endCallback(error);

  offset = loadFragment();
};
