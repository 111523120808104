import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_ACHIEVEMENTS_SUCCESS = "FETCH_ACHIEVEMENTS_SUCCESS";
export const FETCH_ACHIEVEMENTS_FAILURE = "FETCH_ACHIEVEMENTS_FAILURE";
export const FETCH_ACHIEVEMENTS_IN_PROGRESS = "FETCH_ACHIEVEMENTS_IN_PROGRESS";

export const fetchAchievementsInProgress = () => ({
  type: FETCH_ACHIEVEMENTS_IN_PROGRESS
});

export const fetchAchievementsSuccess = achievements => ({
  type: FETCH_ACHIEVEMENTS_SUCCESS,
  payload: { achievements }
});

export const fetchAchievementsFailure = error => ({
  type: FETCH_ACHIEVEMENTS_FAILURE,
  payload: { error }
});

export function fetchAchievements() {
  const apiUrl = `${visionURL}/learning_center/api/achievements`;
  return dispatch => {
    dispatch(fetchAchievementsInProgress);
    visionCallService.fetchRequest(
      apiUrl,
      function(achievements) {
        dispatch(fetchAchievementsSuccess(achievements));
      },
      function(error) {
        dispatch(fetchAchievementsFailure(error));
      }
    );
  };
}
