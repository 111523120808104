import { useState } from "react";
import { visionURL } from "../../config/environment";
import { isIframed } from "../../utils/iframe";

export const useGoogleMapsApiKey = () => {
  const [isLoading, setIsLoading] = useState(false);
  return {
    getGoogleMapsApiKey: async () => {
      setIsLoading(true);
      const response = await fetch(`${visionURL}/api/google_maps`, {
        credentials: "include"
      });

      const unauthorized = response.status === 401;

      if (isIframed && unauthorized) {
        window.parent.postMessage("sessionTimeout", "*");
      }

      const res = await response.json();
      setIsLoading(false);
      return res;
    },
    isLoading
  };
};
