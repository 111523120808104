import {
  FETCH_ACTIVITY_BEGIN,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_FAILURE
} from "../../actions/learningCenter/activityActions";
import { merge } from "lodash";

const INITIAL_STATE = {
  loading: false,
  activity: {},
  error: null
};

function ActivityReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ACTIVITY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        error: null
      };
    case FETCH_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export default ActivityReducer;
