export const camelizeString = string => {
  return string.replace(/^([A-Z])|[\s-_]+(\w)/g, function(
    match,
    p1,
    p2,
    offset
  ) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};

export const capitalize = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return null;
  }
};

export const toSnakeCase = s => {
  return s.replace(/([A-Z])/g, $1 => {
    return `_${$1.toLowerCase()}`;
  });
};

export const transformParamsToSnakeCase = params => {
  const newSnakeKeys = {};
  for (let key in params) {
    let newKey = toSnakeCase(key);
    newSnakeKeys[key] = newKey;
  }
  function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }
  return renameKeys(params, newSnakeKeys);
};

export const prependHttps = (url, http) => {
  return url.replace(/^(?!(?:\w+:)?\/\/)/, !http ? "https://" : "http://");
};

export const toBoolean = str => str === "true";

/**
 * Can be used in text fields to ensure that user only enters an integer
 *
 * @param {string} value The string that will be converted into an integer
 * @returns {number}
 */
export const convertToInteger = value => {
  if (!!value && !Number.isInteger(value)) {
    const newValue = parseInt(value.toString().replace(/[^0-9]/g, ""));
    return Number.isInteger(newValue) ? newValue : "";
  } else {
    return value;
  }
};

export const getLocationString = (city, state) =>
  `${!!city ? `${city}, ` : ""}${state}`;
