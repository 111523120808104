import { OPEN_MODAL, CLOSE_MODAL } from "../actions/modalActions";

const INITIAL_STATE = {
  activeModals: []
};

export default function modalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        activeModals: [...state.activeModals, action.payload]
      };

    case CLOSE_MODAL:
      return {
        ...state,
        activeModals: state.activeModals.filter(
          modal => modal !== action.payload
        )
      };

    default:
      return state;
  }
}
