import { visionURL } from "../config/environment";
import JsonApiService from "./jsonapiService.js";

const tokenService = new JsonApiService("tokens", {
  baseUrl: `${visionURL}/repository/api/v1`,
  credentials: "include"
});

export default async function getToken() {
  const json = await fetchActiveTokens().then(result => {
    return result.json();
  });

  return json.data[0].attributes.token;
}

async function fetchActiveTokens() {
  return await tokenService.where({ isActive: true }).all();
}
