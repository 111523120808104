import { visionURL } from "../../config/environment";
import visionCallService from "../../services/visionCallService";

export const FETCH_ANNOUNCEMENTS_BEGIN = "FETCH_ANNOUNCEMENTS_BEGIN";
export const FETCH_ANNOUNCEMENTS_SUCCESS = "FETCH_ANNOUNCEMENTS_SUCCESS";
export const FETCH_ANNOUNCEMENTS_FAILURE = "FETCH_ANNOUNCEMENTS_FAILURE";
export const UPSERT_ANNOUNCEMENT_BEGIN = "UPSERT_ANNOUNCEMENT_BEGIN";
export const UPDATE_ANNOUNCEMENT_SUCCESS = "UPDATE_ANNOUNCEMENT_SUCCESS";
export const CREATE_ANNOUNCEMENT_SUCCESS = "CREATE_ANNOUNCEMENT_SUCCESS";
export const UPSERT_ANNOUNCEMENT_FAILURE = "UPDATE_ANNOUNCEMENT_FAILURE";
export const DELETE_ANNOUNCEMENT_BEGIN = "DELETE_ANNOUNCEMENT_BEGIN";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_FAILURE = "DELETE_ANNOUNCEMENT_FAILURE";
export const SHOW_ANNOUNCEMENT_MODAL = "SHOW_ANNOUNCEMENT_MODAL";
export const HIDE_ANNOUNCEMENT_MODAL = "HIDE_ANNOUNCEMENT_MODAL";
export const SET_ATTRIBUTE = "SET_ATTRIBUTE";
export const SET_CURRENT = "SET_CURRENT";

export const fetchAnnouncementsBegin = () => ({
  type: FETCH_ANNOUNCEMENTS_BEGIN
});

export const fetchAnnouncementsSuccess = announcements => ({
  type: FETCH_ANNOUNCEMENTS_SUCCESS,
  payload: { announcements }
});

export const fetchAnnouncementsFailure = error => ({
  type: FETCH_ANNOUNCEMENTS_FAILURE,
  payload: { error }
});

export const upsertAnnouncementBegin = () => ({
  type: UPSERT_ANNOUNCEMENT_BEGIN
});

export const updateAnnouncementSuccess = () => ({
  type: UPDATE_ANNOUNCEMENT_SUCCESS
});

export const upsertAnnouncementFailure = error => ({
  type: UPSERT_ANNOUNCEMENT_FAILURE,
  payload: { error }
});

export const createAnnouncementSuccess = id => ({
  type: CREATE_ANNOUNCEMENT_SUCCESS,
  payload: { id: id }
});

export const deleteAnnouncementBegin = () => ({
  type: DELETE_ANNOUNCEMENT_BEGIN
});

export const deleteAnnouncementSuccess = id => ({
  type: DELETE_ANNOUNCEMENT_SUCCESS,
  payload: { id: id }
});

export const deleteAnnouncementFailure = error => ({
  type: DELETE_ANNOUNCEMENT_FAILURE,
  payload: { error }
});

export const showEditAnnouncementModal = announcement => ({
  type: SHOW_ANNOUNCEMENT_MODAL,
  payload: { announcement }
});

export const hideEditAnnouncementModal = () => ({
  type: HIDE_ANNOUNCEMENT_MODAL
});

export const setAnnouncementAttribute = (key, value) => ({
  type: SET_ATTRIBUTE,
  payload: { key: key, value: value }
});

export const setCurrentAnnouncement = announcement => ({
  type: SET_CURRENT,
  payload: { announcement }
});

export function fetchAnnouncements(purpose) {
  return dispatch => {
    dispatch(fetchAnnouncementsBegin());
    return fetchAnnouncementsRequest(
      purpose,
      function(announcements) {
        dispatch(fetchAnnouncementsSuccess(announcements));
      },
      function(error) {
        dispatch(fetchAnnouncementsFailure(error));
      }
    );
  };
}

export function upsertAnnouncement(successCallback, announcement, status) {
  const data = prepareData(announcement, status);
  return dispatch => {
    dispatch(upsertAnnouncementBegin());
    if (announcement.id > 0) {
      updateAnnouncementRequest(
        data,
        function() {
          if (successCallback && typeof successCallback === "function") {
            successCallback();
          }
          dispatch(updateAnnouncementSuccess());
        },
        function(error) {
          alert("Something went wrong!");
          dispatch(upsertAnnouncementFailure(error));
        }
      );
    } else {
      createAnnouncementRequest(
        data,
        function(id) {
          if (successCallback && typeof successCallback === "function") {
            successCallback();
          }
          dispatch(createAnnouncementSuccess(id));
        },
        function(error) {
          alert("Something went wrong!");
          dispatch(upsertAnnouncementFailure(error));
        }
      );
    }
  };
}

export function deleteAnnouncement(announcement) {
  return dispatch => {
    dispatch(deleteAnnouncementBegin());
    return deleteAnnouncementRequest(
      announcement.id,
      function(id) {
        dispatch(deleteAnnouncementSuccess(id));
      },
      function(error) {
        dispatch(deleteAnnouncementFailure(error));
      }
    );
  };
}

export function showAnnouncementModal(announcement) {
  return dispatch => {
    dispatch(showEditAnnouncementModal(prepareForm(announcement)));
  };
}

export function hideAnnouncementModal() {
  return dispatch => {
    dispatch(hideEditAnnouncementModal());
  };
}

export function setAttribute(key, value) {
  return dispatch => {
    dispatch(setAnnouncementAttribute(key, value));
  };
}

export function setAnnouncement(announcement) {
  return dispatch => {
    dispatch(setCurrentAnnouncement(prepareForm(announcement)));
  };
}

function fetchAnnouncementsRequest(purpose, successCallback, errorCallback) {
  return visionCallService.fetchRequest(
    `${visionURL}/learning_center/api/announcements?style=${purpose}`,
    successCallback,
    errorCallback
  );
}

function createAnnouncementRequest(
  announcement,
  successCallback,
  errorCallback
) {
  return visionCallService.postJsonDataRequest(
    `${visionURL}/learning_center/api/announcements`,
    announcement,
    data => successCallback(data.id),
    errorCallback
  );
}

function updateAnnouncementRequest(
  announcement,
  successCallback,
  errorCallback
) {
  return visionCallService.putJsonDataRequest(
    `${visionURL}/learning_center/api/announcements/${announcement.id}`,
    announcement,
    successCallback,
    errorCallback
  );
}

function deleteAnnouncementRequest(
  announcementId,
  successCallback,
  errorCallback
) {
  return visionCallService.deleteRequest(
    `${visionURL}/learning_center/api/announcements/${announcementId}`,
    () => successCallback(announcementId),
    errorCallback
  );
}

function prepareData(announcement, status) {
  const obj = {
    title: announcement.title,
    body: announcement.body,
    post_date: announcement.postDate,
    end_date: announcement.removeOnEndDate ? announcement.endDate : null,
    status_type_id: status === "Draft" ? 2 : 1,
    images: announcement.images,
    media: announcement.media,
    attachments: announcement.attachments
  };

  let res = {
    announcement: obj
  };

  if (announcement.id) {
    res.id = announcement.id;
  }

  return res;
}

function prepareForm(announcement) {
  const dataToPass = announcement || {};
  if (!dataToPass.removeOnEndDate) {
    dataToPass.removeOnEndDate =
      dataToPass.id > 0 && dataToPass.endDate !== null;
  }
  return dataToPass;
}
