import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

export const sizes = {
  topNavBarHeight: 72,
  sideNavBarWidth: 236,
  slideOutWidth: 640,
  sideNavBarWidthCondensed: 72,
  calendarControlPanelWidth: 334,
  calendarControlPanelWidthCodensedMobile: 44,
  calendarHeaderHeight: 138,
  widgetMaxHeights: {
    ilt: 948
  }
};

// general overrides used by both learning center theme and admin theme
const visionTheme = (primaryColor, secondaryColor) => {
  const theme = createTheme({
    palette: {
      error: {
        main: "#FF0C3E"
      },
      primary: {
        main: primaryColor,
        600: "#1E88E5"
      },
      background: {
        default: "#fafafa"
      },
      secondary: {
        main: secondaryColor,
        50: "#FAFAFA",
        100: "#F5F5F5",
        200: "#EEEEEE",
        300: "#E0E0E0",
        400: "#BDBDBD",
        500: "#9E9E9E",
        600: "#757575",
        700: "#616161",
        800: "#212F49"
      },
      inputBackground: "#F9F9F9",
      transparent: "rgba(0, 0, 0, 0)",
      table: {
        filterRow: "#fff"
      },
      visto: {
        main: "#00AFAB",
        light: "#E6F7F7"
      },
      action: {
        hover: alpha(primaryColor, 0.04),
        hoverActive: alpha(primaryColor, 0.1),
        alert: alpha("#FF0000", 0.02),
        disabled: "rgba(0, 0, 0, 0.38)"
      },
      text: {
        secondary: "rgba(0, 0, 0, 0.6)"
      },
      active: {
        text: "#FFFFFF",
        background: primaryColor
      },
      body: "#e3f2fd",
      gradient: `linear-gradient(90deg, ${primaryColor}, ${secondaryColor})`
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    sizes,
    widgetLayoutBreakpoints: {
      sm: 0,
      md: 800,
      lg: 1100
    },
    mixins: {
      dashedBorder: {
        backgroundImage:
          "repeating-linear-gradient(to right, #bdbdbd 0%, #bdbdbd 50%, transparent 50%, transparent 100%), " +
          "repeating-linear-gradient(to right, #bdbdbd 0%, #bdbdbd 50%, transparent 50%, transparent 100%), " +
          "repeating-linear-gradient(to bottom, #bdbdbd 0%, #bdbdbd 50%, transparent 50%, transparent 100%), " +
          "repeating-linear-gradient(to bottom, #bdbdbd 0%, #bdbdbd 50%, transparent 50%, transparent 100%)",
        backgroundPosition: "left top, left bottom, left top, right top",
        backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
        backgroundSize: "15px 1px, 15px 1px, 1px 15px, 1px 15px",
        minHeight: "448px",
        border: "solid 1px transparent"
      },
      slideOutContainer: {
        height: "100%",
        position: "absolute",
        left: `${sizes.sideNavBarWidthCondensed}px`,
        zIndex: 11,
        "@media (max-width:600px)": {
          left: 0
        }
      },
      slideOut: {
        background: "linear-gradient(180deg, #F6FDFF 0%, #FFFFFF 100%)",
        height: "100%",
        zIndex: 13,
        position: "relative",
        boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
        minHeight: "100vh"
      },
      draggableRow: {
        background: "#FAFAFA",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)"
      },
      hoverableRow: {
        "&:hover": {
          background: alpha(primaryColor, 0.04),
          cursor: "pointer",
          borderRadius: 4,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)"
        }
      },
      tooltipLight: {
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#fff",
        borderRadius: 4,
        fontSize: "0.75rem",
        maxWidth: "none"
      }
    },
    typography: {
      fontFamily: [
        '"Avenir Next"',
        '"Helvetica"',
        '"Arial"',
        "sans-serif"
      ].join(",")
    }
  });

  theme.components = {
    ...createOverrides(theme)
  };
  //console.log(theme);
  return theme;
};

export function createOverrides(theme) {
  //console.log("theme", theme);
  return {
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.primary.dark,
          "& svg": {
            color: theme.palette.primary.main
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: "100vw",
          minHeight: 64,
          justifyContent: "center"
        },
        positionFixed: {
          left: 0
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1301
        },
        endAdornment: {
          top: "unset"
        },
        inputRoot: {
          "&[class*='MuiFilledInput-root']": {
            paddingTop: 10,
            paddingBottom: 9
          }
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: "0.65rem",
          maxWidth: 16,
          minWidth: 16,
          height: 16,
          padding: 0,
          fontWeight: "bold"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2.5px",
          padding: "6px 16px",
          textTransform: "capitalize",
          whiteSpace: "nowrap",
          "&.link": {
            padding: 0,
            "& > span > a": {
              width: "100%",
              padding: "6px 16px"
            }
          },
          "&.Mui-disabled": {
            color: theme.palette.primary.main,
            opacity: 0.3
          },
          "&.wide": {
            minWidth: 150
          }
        },
        text: {
          padding: "6px 16px"
        },
        contained: {
          boxShadow: "none",
          "&.Mui-disabled": {
            color: theme.palette.action.disabled
          }
        },
        outlinedPrimary: {
          border: `solid 1px ${theme.palette.primary.main}`
        },
        sizeSmall: {
          fontSize: "0.75rem",
          fontWeight: "normal"
        },
        iconSizeLarge: {
          marginRight: 16
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "& + li[role=separator]": {
            marginTop: -1,
            marginBottom: -1
          },
          "&.active": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.action.hoverActive
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "4px 0 4px 24px",
          minHeight: 57,
          background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.secondary})`,
          "@media (min-width:600px)": {
            padding: "2px 0 2px 24px"
          }
        },
        content: {
          flex: "unset",
          display: "flex",
          color: "white"
        },
        action: {
          flex: 1,
          alignSelf: "unset",
          marginTop: 0,
          marginRight: 0
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          padding: 32
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: 10
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "unset",
          maxWidth: "100%"
        },
        label: {
          padding: "5px 12px",
          whiteSpace: "normal",
          overflowWrap: "break-word"
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorSecondary: {
          color: "#FFF"
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          "& > * + *": {
            marginLeft: 24
          },
          "& > :not(:first-child)": {
            marginLeft: 24
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          flexGrow: 1
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        button: {
          fontSize: "inherit"
        }
      }
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingBottom: 0,
          paddingTop: 0
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          minHeight: 54
        },
        gutters: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 24,
          paddingRight: 24
        },
        dense: {
          minHeight: "unset"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.action.active,
          minWidth: 48
        }
      }
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up("sm")]: {
            minHeight: 48
          }
        }
      }
    },
    MuiPopper: {
      defaultProps: {
        container: document.getElementById("tooltipContainer")
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& + span": {
            marginLeft: theme.spacing(1.25)
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "0 16px",
          textTransform: "capitalize",
          fontSize: "0.9375rem",
          fontWeight: 500,
          "@media (min-width:960px)": {
            flex: "unset",
            fontSize: "0.9375rem",
            minWidth: 206
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.primary.main
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        flexContainer: {
          height: 64
        },
        scroller: {
          borderBottom: `solid 1px ${theme.palette.divider}`
        },
        scrollButtons: {
          borderBottom: `solid 1px ${theme.palette.divider}`
        }
      }
    },
    MuiTableRow: {
      defaultProps: {
        "data-qa": "mui-table-row"
      },
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover:hover": {
            backgroundColor: theme.palette.action.hover,
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
            "& .hover-action": {
              visibility: "visible"
            }
          },
          "&.MuiTableRow-hover": {
            "& .hover-action": {
              visibility: "hidden"
            }
          }
        }
      }
    },
    MuiTableCell: {
      defaultProps: {
        "data-qa": "mui-table-cell"
      },
      styleOverrides: {
        body: {
          color: "inherit"
        },
        root: {
          borderBottom: `solid 1px ${theme.palette.divider}`,
          padding: "18px 12px 18px 12px"
        },
        sizeSmall: {
          padding: "8px 12px 6px 12px"
        },
        paddingCheckbox: {
          padding: "0 0 0 4px",
          width: 48
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          "@media (min-width:600px)": {
            paddingLeft: 16,
            paddingRight: 16
          }
        }
      }
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "none"
          }
        },
        icon: {
          color: "inherit"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "& > button": {
            color: theme.palette.text.secondary
          },
          "&$focused > button": {
            color: theme.palette.primary.main
          }
        },
        input: {
          caretColor: theme.palette.primary.main
        },
        underline: {
          "&:before, &:hover:not($disabled):before": {
            borderBottom: `solid 2px ${theme.palette.action.disabled}`
          },
          "&:after": {
            borderBottomColor: theme.palette.primary.main
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "inherit",
          "& > button": {
            color: theme.palette.text.secondary
          },
          "&.Mui-error ::-webkit-input-placeholder": {
            color: theme.palette.error.main
          }
        },
        input: {
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.secondary,
            opacity: 1
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.secondary[200]
        }
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          height: 44,
          width: 44,
          "&.filled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderRadius: 0
          }
        },
        sizeSmall: {
          maxWidth: 26,
          maxHeight: 26
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflowY: "auto",
          display: "flex",
          flexDirection: "column"
        }
      }
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          zIndex: 1301
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: "1.125rem"
        },
        colorAction: {
          color: theme.palette.action.active
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.disabled": {
            color: theme.palette.text.disabled
          },
          "&.nav-item": {
            fontSize: "0.75rem",
            fontWeight: "600",
            textTransform: "uppercase"
          },
          "&.nested-nav-item": {
            fontSize: "0.8125rem",
            fontWeight: 500
          },
          "&.transparent": {
            opacity: "0.5"
          },
          "&.italic": {
            fontStyle: "italic"
          },
          "&.emphasized": {
            fontStyle: "italic",
            fontWeight: 500
          }
        },
        body1: {
          lineHeight: 1.5
        },
        subtitle1: {
          fontWeight: 500,
          letterSpacing: "0.15px",
          lineHeight: 1.5
        },
        subtitle2: {
          lineHeight: "24px",
          fontWeight: 600
        },
        caption: {
          letterSpacing: "0.4px"
        },
        overline: {
          fontWeight: 500,
          fontSize: "0.625rem",
          letterSpacing: "1.5px"
        },
        h1: {
          fontSize: "4rem",
          fontWeight: 600
        },
        h2: {
          fontSize: "2.25rem"
        },
        h3: {
          fontSize: "2.25rem"
        },
        h4: {
          fontSize: "1.5rem",
          fontWeight: 600
        },
        h5: {
          fontSize: "1.25rem",
          fontWeight: 600,
          lineHeight: "27px"
        },
        h6: {
          fontSize: "1.125rem",
          fontWeight: 600
        },
        colorSecondary: {
          color: theme.palette.secondary[500]
        },
        noWrap: {
          textOverflow: "unset",
          overflow: "visible"
        },
        button: {
          fontWeight: 700,
          textTransform: "capitalize",
          fontSize: "0.9375rem"
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.inputBackground,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          fontWeight: 500,
          "&:hover": {
            backgroundColor: theme.palette.action.hover
          },
          "&.Mui-error": {
            color: theme.palette.error.main
          }
        },
        adornedEnd: {
          paddingRight: theme.spacing(1)
        },
        underline: {
          "&.Mui-disabled": {
            "&:before": {
              borderBottom: "none"
            }
          },
          "&$focused": {
            "&:after": {
              borderBottom: "none"
            }
          },
          "&:after": {
            borderBottomColor: theme.palette.primary.main
          },
          "&:before": {
            borderBottomWidth: 2
          }
        },
        multiline: {
          // padding: "27px 40px 10px 16px",
          "&$adornedEnd": {
            paddingRight: theme.spacing(1)
          }
        },
        input: {
          //  padding: "27px 16px 10px",
          "&[type=button]": {
            textAlign: "left",
            cursor: "pointer",
            "&$disabled": {
              cursor: "default",
              color: theme.palette.text.disabled
            }
          },
          "&.small": {
            padding: "10px 8px",
            height: 19,
            ...theme.typography.body2
          }
        },
        inputMarginDense: {
          paddingBottom: 0,
          paddingTop: 0,
          marginRight: 8
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          padding: "40px 32px",
          maxWidth: 492,
          width: 492
        },
        paperScrollPaper: {
          maxHeight: "unset"
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          background: "none",
          justifyContent: "center"
        },
        dotActive: {
          backgroundColor: "white"
        },
        dot: {
          backgroundColor: theme.palette.secondary[300],
          width: 10,
          height: 10,
          margin: "0 5px"
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:before": {
            backgroundColor: "unset"
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        gutters: {
          height: 0
        },
        root: {
          height: 0,
          padding: 0,
          minHeight: 0,
          "&.Mui-expanded": {
            minHeight: 0
          }
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 6,
          marginBottom: 6
        }
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "&[role=radiogroup]": {
            "& label:not(:last-child)": {
              marginRight: 52
            }
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 12
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: "column",
          padding: 0,
          margin: "24px 0"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          zIndex: 1
        },
        filled: {
          "&.MuiInputLabel-shrink": {
            transform: "translate(12px, 10px) scale(0.75)"
          }
        },
        shrink: {
          zIndex: 1,
          transform: "translate(12px, 10px) scale(0.75)"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          fontWeight: 500
        },
        asterisk: {
          color: theme.palette.error.main
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.secondary[200],
          lineHeight: "32px"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none"
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#EEEEEE", 0.75)
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          flexWrap: "nowrap"
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-disabled.Mui-checked": {
            color: alpha(theme.palette.primary.main, 0.5),
            "& + span": {
              backgroundColor: theme.palette.primary.main
            }
          }
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "denseWhite" },
          style: {
            backgroundColor: theme.palette.common.white,
            height: 40,
            paddingRight: 0
          }
        }
      ]
    },
    WAMuiChipInput: {
      styleOverrides: {
        inputRoot: {
          paddingRight: 12
        },
        label: {
          top: 4
        }
      }
    }
  };
}

export default visionTheme;
