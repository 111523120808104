import merge from "lodash/merge";

import {
  FETCH_REPOSITORY_BEGIN,
  FETCH_REPOSITORY_SUCCESS,
  FETCH_REPOSITORY_FAILURE,
  FETCH_REPOSITORIES_BEGIN,
  FETCH_REPOSITORIES_SUCCESS,
  FETCH_REPOSITORIES_FAILURE,
  FETCH_INTEGRATIONS_BEGIN,
  FETCH_INTEGRATIONS_SUCCESS,
  FETCH_INTEGRATIONS_FAILURE,
  CREATE_INTEGRATIONS_BEGIN,
  CREATE_INTEGRATIONS_SUCCESS,
  CREATE_INTEGRATIONS_FAILURE,
  FETCH_ITEMS_BEGIN,
  FETCH_ITEM_BEGIN,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAILURE,
  FETCH_ITEM_SUCCESS,
  UPDATE_ITEMS_BEGIN,
  UPDATE_ITEMS_SUCCESS,
  UPDATE_ITEMS_FAILURE,
  CREATE_ITEMS_BEGIN,
  CREATE_ITEMS_SUCCESS,
  CREATE_ITEMS_FAILURE,
  CLEAR_ITEMS
} from "../../../actions/repositoryActions";

const INITIAL_STATE = {
  items: {},
  courseSections: {},
  itemList: [],
  fetchedItemPages: []
};

function repositoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_REPOSITORY_BEGIN:
    case FETCH_REPOSITORIES_BEGIN:
    case FETCH_INTEGRATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REPOSITORY_FAILURE:
    case FETCH_REPOSITORIES_FAILURE:
    case FETCH_INTEGRATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_REPOSITORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetchedItemPages: [],
        itemList: new Array(action.payload.itemCount).fill(null),
        loading: false
      };
    case FETCH_REPOSITORIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case FETCH_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case CREATE_INTEGRATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_INTEGRATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case CREATE_INTEGRATIONS_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        error: null
      };
    case FETCH_ITEMS_BEGIN || FETCH_ITEM_BEGIN:
      let fetchedItemPages = state.fetchedItemPages;
      if (action.payload && action.payload.pageNumber) {
        fetchedItemPages = [...fetchedItemPages, action.payload.pageNumber];
      }
      return {
        ...state,
        fetchedItemPages,
        loading: true,
        error: null
      };
    case FETCH_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case FETCH_ITEMS_SUCCESS:
      const { number, size, itemList, meta, ...payload } = action.payload;
      let items = [...state.itemList];
      if (items.length !== meta.total) {
        items = new Array(meta.total).fill(null);
      }
      items.splice((number - 1) * size, itemList.length, ...itemList);
      return {
        ...merge({}, state, payload),
        itemList: items,
        loading: false,
        error: null
      };
    case FETCH_ITEM_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        error: null
      };
    case UPDATE_ITEMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case UPDATE_ITEMS_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        error: null
      };

    case CREATE_ITEMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case CREATE_ITEMS_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        error: null
      };
    case CLEAR_ITEMS:
      return {
        ...state,
        ...action.payload,
        fetchedItemPages: [],
        loading: false,
        error: null
      };
    default:
      return state;
  }
}

export default repositoryReducer;
