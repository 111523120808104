import Jsona from "jsona";
import normalize from "./normalize";
import _ from "lodash";

const jsona = new Jsona();

export function denormalizeEntity(reduxObject, entityType, entityId) {
  return jsona.denormalizeReduxObject({
    reduxObject,
    entityType,
    entityIds: entityId,
    returnBuilderInRelations: true
  });
}
/**
 * Work-around for creating data in format necessary for material-table
 * returns an object containing the data, totalCount, and page
 */
export function denormalizeTableEntity(reduxObject, type = "data") {
  const { meta } = reduxObject;
  const data = jsona.denormalizeReduxObject({
    reduxObject,
    entityType: type,
    returnBuilderInRelations: true
  });
  return {
    data: data || [],
    totalCount: meta && meta.totalCount ? meta.totalCount : 0,
    page: meta && meta.page ? meta.page - 1 : 0
  };
}

// fetchData = new Promise(Non Normalized JSON API Response)
export function fetchTableData(fetchData, type = "data", saveSort = false) {
  return fetchData
    .then(response => {
      // TODO: The calls for fetchTableData need to be refactored to remove this patch
      // if the type has been passed in we want to normalize so we can build relationships to get related columns
      if (type !== "data") {
        if (saveSort)
          return denormalizeTableEntity(
            normalizeSaveServerSort(response, type),
            type
          );
        else return denormalizeTableEntity(normalize(response), type);
      }
      return denormalizeTableEntity(response, type);
    })
    .catch(function(error) {
      return error;
    });
}

function normalizeSaveServerSort(data, type) {
  const sortedIds = data.data.map(item => {
    return item.id;
  });
  const normalizedData = normalize(data);
  return sortByArrayIds(normalizedData, sortedIds, type);
}

function sortByArrayIds(data, by, type) {
  data[type] = _.sortBy(data[type], item => {
    return by.indexOf(item.id);
  });
  return data;
}

export function deserialize(data) {
  return jsona.deserialize(data);
}

/**
 * Converts the deserialized data to JSON API format. To include any
 * resources that are related to the resources in the primary
 * data, pass in an array of the relationship keys for the related
 * resources which you wish to include.
 * @param  {} data The deserialized data
 * @param  {string[]} includeNames An array of relationship key names
 */
export function serialize(data, includeNames = []) {
  return jsona.serialize({ stuff: data, includeNames });
}
