import {
  FETCH_SUPPORT_BEGIN,
  FETCH_SUPPORT_SUCCESS,
  FETCH_SUPPORT_FAILURE,
  CHANGE_OPEN_STATE
} from "../actions/supportActions";

const INITIAL_STATE = {
  supportInfo: {
    loading: false,
    error: null,
    open: false,
    openCustomSupportMessage: false,
    customSupportDisplay: "primary",
    graceHillSupportDisplay: "primary",
    graceHillSupport: {
      header: "",
      message: "",
      email: "",
      operationHours: "",
      phoneNumber: ""
    },
    customSupport: {
      header: "Custom Support",
      subtext:
        "Please log in using the URL provided by your Training Administrator",
      message: `
      <p>If you need help, we’re here to provide you with fast, friendly
      assistance so you can get back to business. Whether a Training
      Administrator or Learner, you have four options to get help:</p>
      <ol>
        <li>
          <span variant="body1" color="textSecondary">
            If you need help logging in, click the Forgot Username or Password
            link on the login screen.
          </span>
        </li>
        <li>
          <span variant="body1" color="textSecondary">
            For Learners: If the Forgot Username and Password link is not
            available, contact your Training Administrator. Visit and explore
            our <a href="#">VisionX Self-Help Site</a>.
          </span>
        </li>
        <li>
          <span variant="body1" color="textSecondary">
            Call us at (866) 472-2344 Mon - Fri, 8:00am - 8:00pm EST.
          </span>
        </li>
        <li>
          <span variant="body1" color="textSecondary">
            Put in a <a href="#">support ticket</a>.
          </span>
        </li>
      </ol>
      <p>Find out if your computer is ready. View the <a href="#">system requirements</a></p>`,
      email: "helpdesk@custom.com",
      operationHours: "Mon - Fri, 8:00am - 8:00pm EST",
      phoneNumber: "(866) 414-1234"
    }
  }
};

function supportReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SUPPORT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SUPPORT_SUCCESS:
      console.log("FETCH_SUPPORT_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        supportInfo: {
          ...state.supportInfo,
          customSupportDisplay: action.payload.supportInfo.customSupportDisplay,
          graceHillSupportDisplay:
            action.payload.supportInfo.graceHillSupportDisplay,
          customSupport: action.payload.supportInfo.customSupport,
          graceHillSupport: action.payload.supportInfo.graceHillSupport
        }
      };
    case FETCH_SUPPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        supportInfo: {
          ...state.supportInfo,
          customSupport: {}
        }
      };
    case CHANGE_OPEN_STATE: {
      state.supportInfo.open = action.payload.open;
      return {
        ...state
      };
    }
    default:
      return state;
  }
}

export default supportReducer;
