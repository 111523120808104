import { visionURL } from "../../../../config/environment";
import JsonApiService from "../../../../services/jsonapiService";
import normalize from "../../../utils/normalize";

export const FETCH_TODO_BEGIN = "FETCH_TODO_BEGIN";
export const FETCH_TODO_SUCCESS = "FETCH_TODO_SUCCESS";
export const FETCH_TODO_FAILURE = "FETCH_TODO_FAILURE";

export const fetchTodosBegin = () => ({
  type: FETCH_TODO_BEGIN
});

export const fetchTodosSuccess = payload => ({
  type: FETCH_TODO_SUCCESS,
  payload
});

export const fetchTodosFailure = error => ({
  type: FETCH_TODO_FAILURE,
  payload: { error }
});

const todosService = async () =>
  new JsonApiService("todo", {
    baseUrl: `${visionURL}/learning_center/api/users`,
    credentials: "include"
  });

export function fetchTodos() {
  return async dispatch => {
    const service = await todosService();
    dispatch(fetchTodosBegin());
    const response = await service.all().then(response => response.json());
    if (response && response.errors) {
      dispatch(fetchTodosFailure(response.errors));
    } else {
      dispatch(fetchTodosSuccess(normalize(response)));
    }
  };
}
