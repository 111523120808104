import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import announcementsReducer from "./announcementsReducer";
import learningCenterSettingsReducer from "./learningCenterSettingsReducer";
import UserAssignmentsListReducer from "./userAssignmentsList";
import ExpandedAssignmentsReducer from "./learningCenter/expandedAssignmentsReducer";
import UserReducer from "./userReducer";
import CustomerReducer from "./customerReducer";
import UserProfileReducer from "./userProfileReducer";
import calendarReducer from "./calendar";
import customerAppsReducer from "./customerAppsReducer";
import resourcesReducer from "./learningCenter/resources";
import leaderboardReducer from "./leaderboard";
import widgetsReducer from "./widgets";
import supportReducer from "./support";
import messagesReducer from "./messagesReducer";
import achievementsReducer from "./achievementsReducer";
import repositoryReducer from "./adminConsole/repository/repository";
import contentCoursesReducer from "./contentCourses";
import todoReducer from "./learningCenter/dashboard/todoReducer";
import contentableReducer from "./learningCenter/contentableReducer";
import activityReducer from "./learningCenter/activityReducer";
import contentPlayerReducer from "./learningCenter/contentPlayerReducer";
import transcriptReportReducer from "./transcriptReportReducer";
import publicReducer from "./publicReducer";
import errorReducer from "./errorReducer";
import courseBuilderReducer from "./courseBuilder";
import contentQuizzesReducer from "./contentQuizzes";
import contentSurveysReducer from "./contentSurveys";
import usersReducer from "./usersReducer";
import validateReducer from "./adminConsole/validate/validateReducer";
import acContentAssignmentsReducer from "./adminConsole/content/assignmentsReducer";
import acCourseBuilderReducer from "./adminConsole/content/courseBuilderReducer";
import adminCustomerReducer from "./adminConsole/adminCustomerReducer";
import snackbarReducer from "./snackbarReducer";
import loadingReducer from "./loadingReducer";
import errorsReducer from "./errorsReducer";
import tablesReducer from "./tablesReducer";
import modalReducer from "./modalReducer";

const createRootReducer = history =>
  combineReducers({
    acContentAssignmentsState: acContentAssignmentsReducer,
    acCourseBuilderState: acCourseBuilderReducer,
    achievementsState: achievementsReducer,
    adminCustomerState: adminCustomerReducer,
    announcementsState: announcementsReducer,
    calendarState: calendarReducer,
    contentCoursesState: contentCoursesReducer,
    contentPlayerState: contentPlayerReducer,
    contentQuizzesState: contentQuizzesReducer,
    contentSurveysState: contentSurveysReducer,
    courseBuilderState: courseBuilderReducer,
    customerAppsState: customerAppsReducer,
    customerState: CustomerReducer,
    errorState: errorReducer,
    errorsState: errorsReducer,
    expandedAssignmentsState: ExpandedAssignmentsReducer,
    leaderboardState: leaderboardReducer,
    learningCenter: combineReducers({
      contentable: contentableReducer
    }),
    learningCenterActivityData: activityReducer,
    learningCenterSettingsState: learningCenterSettingsReducer,
    loadingState: loadingReducer,
    messagesState: messagesReducer,
    modalState: modalReducer,
    publicState: publicReducer,
    repositoryState: repositoryReducer,
    resourcesState: resourcesReducer,
    router: connectRouter(history),
    snackbarState: snackbarReducer,
    supportState: supportReducer,
    tablesState: tablesReducer,
    todoState: todoReducer,
    transcriptReportState: transcriptReportReducer,
    userAssignmentsListState: UserAssignmentsListReducer,
    userProfileState: UserProfileReducer,
    userState: UserReducer,
    usersState: usersReducer,
    validateState: validateReducer,
    widgetsState: widgetsReducer
  });

export default createRootReducer;
