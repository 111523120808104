import {
  FETCH_CUSTOMER_BEGIN,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_BEGIN,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE
} from "../actions/customerActions";

const INITIAL_STATE = {
  customer: {},
  loading: true,
  error: null
};

function CustomerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loading: false,
        customer: action.payload.customer
      };
    }

    case FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        customer: {}
      };

    case UPDATE_CUSTOMER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loading: false,
        customer: action.payload.customer
      };
    }

    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        customer: {}
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default CustomerReducer;
