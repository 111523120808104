import { courseStatuses, policyStatuses } from "../../constants";
import { UPDATE_TABLE_STATUS } from "../actions/tablesActions";
import { RESET_TABLE_STATUS } from "../actions/tablesActions";

const INITIAL_STATE = {
  courses: {
    columns: {
      type: {
        orderDirection: null
      },
      name: {
        orderDirection: null,
        filterValue: ""
      },
      trainingHours: {
        orderDirection: null
      },
      assigned: {
        orderDirection: null
      },
      inProgress: {
        orderDirection: null
      },
      completed: {
        orderDirection: null
      }
    },
    page: 1,
    pageSize: 50,
    filters: {
      contentPackage: "",
      hideAdditionalContent: true,
      searchInput: "",
      startLetter: "",
      status: courseStatuses[1]
    }
  },
  policies: {
    columns: {
      type: {
        orderDirection: null
      },
      name: {
        orderDirection: null,
        filterValue: ""
      },
      manualName: {
        orderDirection: null
      },
      assigned: {
        orderDirection: null
      },
      inProgress: {
        orderDirection: null
      },
      completed: {
        orderDirection: null
      }
    },
    page: 1,
    pageSize: 50,
    filters: {
      contentPackage: "",
      search: "",
      startLetter: "",
      status: policyStatuses[0].value
    }
  },
  pickableCourses: {
    columns: {
      type: {
        orderDirection: null
      },
      title: {
        orderDirection: null
      }
    },
    page: 1,
    pageSize: 50,
    filters: {
      type: "",
      searchName: ""
    }
  },
  validateOrders: {
    columns: {
      id: {
        orderDirection: "asc",
        filterValue: ""
      },
      dateCreated: {
        orderDirection: null
      },
      employeeName: {
        orderDirection: null,
        filterValue: ""
      },
      status: {
        orderDirection: null,
        filterValue: ""
      },
      location: {
        orderDirection: null,
        filterValue: ""
      },
      shopType: {
        orderDirection: null,
        filterValue: ""
      },
      programRange: {
        orderDirection: null
      }
    },
    page: 1,
    pageSize: 50,
    filters: {
      location: "",
      shopType: "",
      statusValue: "incomplete",
      createdAfter: null,
      createdBefore: null,
      programRangeStartedBefore: null,
      programRangeStartedAfter: null,
      searchInput: ""
    }
  }
};

function tablesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_TABLE_STATUS:
      const {
        payload: { tableQueryData, filters, table }
      } = action;
      let tableData = { ...INITIAL_STATE[table] };
      if (tableQueryData) {
        tableQueryData.filters.forEach(filter => {
          tableData.columns = {
            ...tableData.columns,
            [filter.column.field]: { filterValue: filter.value }
          };
        });
        if (tableQueryData.orderBy) {
          tableData.columns = {
            ...tableData.columns,
            [tableQueryData.orderBy.field]: {
              ...tableData.columns[tableQueryData.orderBy.field],
              orderDirection: tableQueryData.orderDirection
            }
          };
        }
        tableData.page = tableQueryData.page;
        tableData.pageSize = tableQueryData.pageSize;
      }
      if (filters) {
        Object.keys(filters).forEach(filterKey => {
          tableData.filters = {
            ...tableData.filters,
            [filterKey]: filters[filterKey]
          };
        });
      }
      return {
        ...state,
        [table]: { ...tableData }
      };
    case RESET_TABLE_STATUS:
      return {
        ...state,
        [action.payload.table]: { ...INITIAL_STATE[action.payload.table] }
      };
    default:
      return state;
  }
}

export default tablesReducer;
