import { visionURL } from "../../config/environment";
import visionCallService from "../../services/visionCallService";

export const FETCH_USER_PROFILE_BEGIN = "FETCH_USER_PROFILE_BEGIN";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE_BEGIN = "UPDATE_USER_PROFILE_BEGIN";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE_PICTURE_BEGIN =
  "UPDATE_USER_PROFILE_PICTURE_BEGIN";
export const UPDATE_USER_PROFILE_PICTURE_SUCCESS =
  "UPDATE_USER_PROFILE_PICTURE_SUCCESS";
export const UPDATE_USER_PROFILE_PICTURE_FAILURE =
  "UPDATE_USER_PROFILE_PICTURE_FAILURE";

export const fetchUserProfileBegin = () => ({
  type: FETCH_USER_PROFILE_BEGIN
});

export const fetchUserProfileSuccess = userProfile => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: { userProfile }
});

export const fetchUserProfileFailure = error => ({
  type: FETCH_USER_PROFILE_FAILURE,
  payload: { error }
});

export const updateUserProfileBegin = () => ({
  type: UPDATE_USER_PROFILE_BEGIN
});

export const updateUserProfileSuccess = userProfile => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: { userProfile }
});

export const updateUserProfileFailure = error => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload: { error }
});

export const updateUserProfilePictureBegin = () => ({
  type: UPDATE_USER_PROFILE_PICTURE_BEGIN
});

export const updateUserProfilePictureSuccess = userProfilePictureUrl => ({
  type: UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  payload: { userProfilePictureUrl }
});

export const updateUserProfilePictureFailure = error => ({
  type: UPDATE_USER_PROFILE_PICTURE_FAILURE,
  payload: { error }
});

export function fetchUserProfile() {
  return dispatch => {
    dispatch(fetchUserProfileBegin());
    visionCallService.fetchRequest(
      `${visionURL}/learning_center/api/user_profile`,
      function(currentUserProfile) {
        dispatch(fetchUserProfileSuccess(currentUserProfile));
      },
      function(error) {
        dispatch(fetchUserProfileFailure(error));
      }
    );
  };
}

export function updateUserProfile(userProfile, successCallback, errorCallback) {
  return dispatch => {
    dispatch(updateUserProfileBegin());
    visionCallService.putJsonDataRequest(
      `${visionURL}/learning_center/api/user_profile`,
      serializeUserProfile(userProfile),
      function(currentUserProfile) {
        dispatch(updateUserProfileSuccess(currentUserProfile));
        successCallback();
      },
      function(error) {
        dispatch(updateUserProfileFailure(error));
        errorCallback(error);
      }
    );
  };
}

export function updateUserProfilePicture(dataObject, userId, successCallback) {
  let data = new FormData();
  Object.keys(dataObject).forEach(x =>
    data.append("profile_picture[" + x + "]", dataObject[x])
  );
  return dispatch => {
    dispatch(updateUserProfilePictureBegin());
    visionCallService.putFormDataRequest(
      `${visionURL}/learning_center/api/users/${userId}/user_profile_image`,
      data,
      function(response) {
        dispatch(updateUserProfilePictureSuccess(response.url));
        successCallback();
      },
      function(error) {
        dispatch(updateUserProfilePictureFailure(error));
      }
    );
  };
}

const serializeUserProfile = userProfile => {
  return {
    settings: userProfile.settings
  };
};
