import {
  FETCH_MESSAGES_BEGIN,
  FETCH_MESSAGES_SUCCESS,
  FETCH_UNREAD_MESSAGES_BEGIN,
  FETCH_UNREAD_MESSAGES_SUCCESS,
  FETCH_UNREAD_MESSAGES_FAILURE,
  FETCH_MESSAGES_FAILURE,
  DISMISS_MESSAGES_BEGIN,
  DISMISS_MESSAGES_SUCCESS,
  DISMISS_MESSAGES_FAILURE,
  VIEW_MESSAGE_BEGIN,
  VIEW_MESSAGE_SUCCESS,
  VIEW_MESSAGE_FAILURE
} from "../actions/messagesActions";

const INITIAL_STATE = {
  messages: [],
  unreadMessages: [],
  showModal: false,
  loading: false,
  unreadLoading: false,
  upLoading: false,
  error: null,
  unreadError: null,
  updateError: null
};

function messagesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MESSAGES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MESSAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        messages: action.payload.messages
      };
    }

    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        messages: []
      };
    case FETCH_UNREAD_MESSAGES_BEGIN:
      return {
        ...state,
        unreadLoading: true,
        unreadError: null
      };
    case FETCH_UNREAD_MESSAGES_SUCCESS: {
      return {
        ...state,
        unreadLoading: false,
        unreadMessages: action.payload.messages
      };
    }
    case FETCH_UNREAD_MESSAGES_FAILURE:
      return {
        ...state,
        unreadLoading: false,
        unreadError: action.payload.error,
        messages: []
      };
    case DISMISS_MESSAGES_BEGIN:
      return {
        ...state,
        uploading: true,
        updateError: null
      };

    case DISMISS_MESSAGES_SUCCESS: {
      return {
        ...state,
        uploading: false
      };
    }

    case DISMISS_MESSAGES_FAILURE:
      return {
        ...state,
        uploading: false,
        updateError: action.payload.error
      };
    case VIEW_MESSAGE_BEGIN:
      return {
        ...state,
        uploading: true,
        updateError: null
      };

    case VIEW_MESSAGE_SUCCESS: {
      return {
        ...state,
        uploading: false,
        messages: state.messages.map((item, index) => {
          if (parseInt(item.id) === parseInt(action.payload.message.id)) {
            return {
              ...item,
              unread: false
            };
          }
          return item;
        }),
        unreadMessages: state.unreadMessages.filter((item, index) => {
          if (parseInt(item.id) === parseInt(action.payload.message.id)) {
            return false;
          }
          return true;
        })
      };
    }

    case VIEW_MESSAGE_FAILURE:
      return {
        ...state,
        uploading: false,
        updateError: action.payload.error
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default messagesReducer;
