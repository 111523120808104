import {
  FETCH_AWARD_LEADERS_BEGIN,
  FETCH_AWARD_LEADERS_SUCCESS,
  FETCH_AWARD_LEADERS_FAILURE,
  FETCH_AVAILABLE_AWARDS_BEGIN,
  FETCH_AVAILABLE_AWARDS_SUCCESS,
  FETCH_AVAILABLE_AWARDS_FAILURE
} from "../actions/leaderboardActions";

const INITIAL_STATE = {
  availableAwards: null,
  awardLeadersData: {
    userAchievements: null
  }
};

function awardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AWARD_LEADERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_AWARD_LEADERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        awardLeadersData: action.payload.awardLeadersData
      };
    }

    case FETCH_AWARD_LEADERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
        //awardLeadersData: null
      };
    case FETCH_AVAILABLE_AWARDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_AVAILABLE_AWARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        availableAwards: action.payload.availableAwards
      };
    }

    case FETCH_AVAILABLE_AWARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
        // availableAwards: null
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default awardReducer;
