import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from "../actions/usersActions";
import { merge } from "lodash";

const INITIAL_STATE = {
  publicData: {},
  loading: true,
  fetched: false,
  error: null
};

export default function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        fetched: false,
        loading: true,
        error: null
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...merge({}, state, action.payload),
        loading: false,
        fetched: true,
        publicData: action.payload
      };

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        fetched: false,
        error: action.payload.error,
        publicData: {}
      };
    default:
      return state;
  }
}
