import visionCallService from "../../services/visionCallService";
import { visionURL } from "../../config/environment";

export const FETCH_SUPPORT_BEGIN = "FETCH_SUPPORT_BEGIN";
export const FETCH_SUPPORT_SUCCESS = "FETCH_SUPPORT_SUCCESS";
export const FETCH_SUPPORT_FAILURE = "FETCH_SUPPORT_FAILURE";
export const CHANGE_OPEN_STATE = "CHANGE_OPEN_STATE";

export const fetchSupportBegin = () => ({
  type: FETCH_SUPPORT_BEGIN
});

export const fetchSupportSuccess = supportInfo => ({
  type: FETCH_SUPPORT_SUCCESS,
  payload: { supportInfo }
});

export const fetchSupportFailure = error => ({
  type: FETCH_SUPPORT_FAILURE,
  payload: { error }
});

export const changeSupportModalOpenState = open => ({
  type: CHANGE_OPEN_STATE,
  payload: { open }
});

export function fetchSupportInfo() {
  return dispatch => {
    dispatch(fetchSupportBegin());
    return fetchSupportRequest(
      function(supportInfo) {
        dispatch(fetchSupportSuccess(supportInfo));
      },
      function(error) {
        dispatch(fetchSupportFailure(error));
      }
    );
  };
}

function fetchSupportRequest(successCallback, errorCallback) {
  visionCallService.fetchRequest(
    `${visionURL}/learning_center/api/support`,
    successCallback,
    errorCallback
  );
}
