import { visionURL } from "../../config/environment";
import JsonApiService from "../../services/jsonapiService";
import normalize from "../utils/normalize";

const userAssignmentsService = async () =>
  new JsonApiService("my_courses", {
    baseUrl: `${visionURL}/learning_center/api`,
    credentials: "include"
  });

const courseCtoaService = async () =>
  new JsonApiService("course_content", {
    baseUrl: `${visionURL}/learning_center/api`,
    credentials: "include"
  });

export const FETCH_USER_ASSIGNMENTS_LIST_BEGIN =
  "FETCH_USER_ASSIGNMENTS_LIST_BEGIN";
export const FETCH_USER_ASSIGNMENTS_LIST_SUCCESS =
  "FETCH_USER_ASSIGNMENTS_LIST_SUCCESS";
export const FETCH_USER_ASSIGNMENTS_LIST_FAILURE =
  "FETCH_USER_ASSIGNMENTS_LIST_FAILURE";

export const fetchUserAssignmentsListBegin = listType => ({
  type: FETCH_USER_ASSIGNMENTS_LIST_BEGIN,
  payload: listType
});

export const fetchUserAssignmentsListSuccess = (payload, listType) => ({
  type: FETCH_USER_ASSIGNMENTS_LIST_SUCCESS,
  payload: { ...payload, listType }
});

export const fetchUserAssignmentsListFailure = error => ({
  type: FETCH_USER_ASSIGNMENTS_LIST_FAILURE,
  payload: { error }
});

export const FETCH_COURSE_CTOA_BEGIN = "FETCH_COURSE_CTOA_BEGIN";
export const FETCH_COURSE_CTOA_SUCCESS = "FETCH_COURSE_CTOA_SUCCESS";
export const FETCH_COURSE_CTOA_FAILURE = "FETCH_COURSE_CTOA_FAILURE";

export const fetchCourseCtoaBegin = () => ({
  type: FETCH_COURSE_CTOA_BEGIN
});

export const fetchCourseCtoaSuccess = (userAssignmentId, payload) => ({
  type: FETCH_COURSE_CTOA_SUCCESS,
  payload: { userAssignmentId, ...payload }
});

export const fetchCourseCtoaFailure = error => ({
  type: FETCH_COURSE_CTOA_FAILURE,
  payload: { error }
});

export const UPDATE_DISPLAYED_COURSES = "UPDATE_DISPLAYED_COURSES";

export const updateDisplayedCourses = payload => ({
  type: UPDATE_DISPLAYED_COURSES,
  payload
});

export const UPDATE_DISPLAYED_COURSE = "UPDATE_DISPLAYED_COURSE";

// Used to replace displayed course if the user assignment ID changes
export const updateDisplayedCourse = (type, idToAdd, idToRemove) => ({
  type: UPDATE_DISPLAYED_COURSE,
  payload: { type, idToAdd, idToRemove }
});

export function fetchUserAssignmentsList(params) {
  return async dispatch => {
    const service = await userAssignmentsService();
    dispatch(fetchUserAssignmentsListBegin(params?.type));
    const response = await service
      .include(["versions", "enrolled_training_object", "ilt_info"])
      .customParams(params)
      .all()
      .then(response => response.json());
    if (response && response.errors) {
      dispatch(fetchUserAssignmentsListFailure(response.errors));
    } else if (response) {
      const normalizedResponse = normalize(response);
      dispatch(
        fetchUserAssignmentsListSuccess(normalizedResponse, params?.type)
      );
      dispatch(
        updateDisplayedCourses({
          [params.type]: response.data.map(({ id }) => id)
        })
      );
    }
  };
}

export function fetchCourseCtoa(courseId, userAssignmentId) {
  return async dispatch => {
    const service = await courseCtoaService();
    dispatch(fetchCourseCtoaBegin());
    const response = await service
      .include([
        "course_sections",
        "course_sections.course_contentables",
        "user_assignment"
      ])
      .find(courseId)
      .then(response => response.json());
    if (response && response.errors) {
      dispatch(fetchCourseCtoaFailure(response.errors));
    } else {
      dispatch(fetchCourseCtoaSuccess(userAssignmentId, normalize(response)));
    }
  };
}

export const UPDATE_USER_ASSIGNMENT = "UPDATE_USER_ASSIGNMENT";
export const UPDATE_USER_ASSIGNMENT_BEGIN = "UPDATE_USER_ASSIGNMENT_BEGIN";
export const UPDATE_USER_ASSIGNMENT_SUCCESS = "UPDATE_USER_ASSIGNMENT_SUCCESS";
export const UPDATE_USER_ASSIGNMENT_FAILURE = "UPDATE_USER_ASSIGNMENT_FAILURE";

export const updateUserAssignmentBegin = () => ({
  type: UPDATE_USER_ASSIGNMENT_BEGIN,
  payload: {}
});
export const updateUserAssignmentSuccess = payload => ({
  type: UPDATE_USER_ASSIGNMENT_SUCCESS,
  payload
});

export const updateUserAssignmentFailure = error => ({
  type: UPDATE_USER_ASSIGNMENT_FAILURE,
  payload: { error }
});

export const DELETE_USER_ASSIGNMENT_BEGIN = "DELETE_USER_ASSIGNMENT_BEGIN";
export const DELETE_USER_ASSIGNMENT_SUCCESS = "DELETE_USER_ASSIGNMENT_SUCCESS";
export const DELETE_USER_ASSIGNMENT_FAILURE = "DELETE_USER_ASSIGNMENT_FAILURE";

export const deleteUserAssignmentBegin = () => ({
  type: DELETE_USER_ASSIGNMENT_BEGIN,
  payload: {}
});

export const deleteUserAssignmentSuccess = payload => ({
  type: DELETE_USER_ASSIGNMENT_SUCCESS,
  payload
});

export const deleteUserAssignmentFailure = error => ({
  type: DELETE_USER_ASSIGNMENT_FAILURE,
  payload: { error }
});

export const CLEAR_USER_ASSIGNMENTS = "CLEAR_USER_ASSIGNMENTS";

export const clearUserAssignments = () => ({
  type: CLEAR_USER_ASSIGNMENTS
});
