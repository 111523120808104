import { merge } from "lodash";
import { fromJS, updateIn } from "immutable";
import {
  FETCH_SURVEYS_BEGIN,
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_FAILURE,
  CREATE_SURVEY_BEGIN,
  CREATE_SURVEY_SUCCESS,
  CREATE_SURVEY_FAILURE,
  UPDATE_SURVEY_BEGIN,
  UPDATE_SURVEY_SUCCESS,
  UPDATE_SURVEY_FAILURE,
  SURVEY_CREATE_QUESTIONS_BEGIN,
  SURVEY_CREATE_QUESTIONS_SUCCESS,
  SURVEY_CREATE_QUESTIONS_FAILURE,
  SURVEY_UPDATE_QUESTIONS_BEGIN,
  SURVEY_UPDATE_QUESTIONS_SUCCESS,
  SURVEY_UPDATE_QUESTIONS_FAILURE,
  UPDATE_SURVEY_QUESTIONS_BEGIN,
  UPDATE_SURVEY_QUESTIONS_SUCCESS,
  UPDATE_SURVEY_QUESTIONS_FAILURE,
  UPDATE_QUESTION_BEGIN,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  IMPORT_SURVEY_QUESTIONS_SUCCESS
} from "../actions/contentSurveyActions";
import {
  mergeRelatedResources,
  replaceRelatedResources
} from "../utils/reducerHelpers";

const INITIAL_STATE = {
  courseSurvey: {}
};
function contentSurveysReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SURVEYS_BEGIN:
    case CREATE_SURVEY_BEGIN:
    case UPDATE_SURVEY_BEGIN:
    case SURVEY_UPDATE_QUESTIONS_BEGIN:
    case UPDATE_SURVEY_QUESTIONS_BEGIN:
    case UPDATE_QUESTION_BEGIN:
    case SURVEY_CREATE_QUESTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_SURVEYS_SUCCESS:
    case CREATE_SURVEY_SUCCESS:
    case UPDATE_SURVEY_SUCCESS:
      return {
        ...merge({}, state, payload),
        item: payload.item,
        surveys: payload.surveys,
        associatedCourses: payload.associatedCourses,
        loading: false,
        error: null
      };
    case SURVEY_UPDATE_QUESTIONS_SUCCESS:
    case UPDATE_QUESTION_SUCCESS:
    case SURVEY_CREATE_QUESTIONS_SUCCESS:
      let newerState = {
        ...merge({}, state, payload),
        loading: false,
        error: null
      };
      if (payload.questions) {
        let question = Object.values(payload.questions)[0];
        newerState = fromJS(newerState);
        newerState = updateIn(
          newerState,
          [
            "surveys",
            question.attributes.courseQuizId,
            "relationships",
            "questions",
            "data"
          ],
          relationships => {
            let questions = newerState
              .get("surveys")
              .get(String(question.attributes.courseQuizId))
              .get("relationships")
              .get("questions")
              .get("data");
            let questionsIds = questions.map(item => item.get("id"));
            if (!questionsIds.includes(question.id)) {
              questions = questions.push(question);
            }
            return questions;
          }
        ).toJS();

        if (payload.matchClue) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "matchClues",
            payload.matchClue
          );
        }
        if (payload.matchChoice) {
          newerState.questions = replaceRelatedResources(
            newerState.questions,
            question.id,
            "matchChoices",
            payload.matchChoice
          );
        }
      }
      return newerState;
    // TODO: this can probably be refactored to be combined with CREATE_QUESTIONS_SUCCESS above
    case IMPORT_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        answerChoice: merge({}, state.answerChoice, payload.answerChoice),
        questions: merge({}, state.questions, payload.questions),
        surveys: mergeRelatedResources(
          state.surveys,
          payload.surveyId,
          "questions",
          payload.questions
        )
      };
    case FETCH_SURVEYS_FAILURE:
    case CREATE_SURVEY_FAILURE:
    case UPDATE_SURVEY_FAILURE:
    case SURVEY_UPDATE_QUESTIONS_FAILURE:
    case UPDATE_SURVEY_QUESTIONS_FAILURE:
    case UPDATE_QUESTION_FAILURE:
    case SURVEY_CREATE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case UPDATE_SURVEY_QUESTIONS_SUCCESS:
      let newState = fromJS(state);
      newState = updateIn(
        newState,
        ["surveys", payload.id, "relationships", "questions", "data"],
        () => payload.included.filter(i => i.type === "question")
      ).toJS();
      return newState;
    default:
      return state;
  }
}

export default contentSurveysReducer;
