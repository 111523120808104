import {
  FETCH_USER_ASSIGNMENTS_LIST_BEGIN,
  FETCH_USER_ASSIGNMENTS_LIST_SUCCESS,
  FETCH_USER_ASSIGNMENTS_LIST_FAILURE,
  FETCH_COURSE_CTOA_SUCCESS,
  UPDATE_USER_ASSIGNMENT_SUCCESS,
  UPDATE_DISPLAYED_COURSES,
  UPDATE_DISPLAYED_COURSE,
  DELETE_USER_ASSIGNMENT_SUCCESS,
  CLEAR_USER_ASSIGNMENTS
} from "../actions/userAssignmentsListActions";
import { FETCH_TODO_SUCCESS } from "../actions/learningCenter/dashboard/todoActions";
import { replaceRelatedResources } from "../utils/reducerHelpers";
import { merge, mergeWith, omit, isArray } from "lodash";
import { userAssignmentListTypes } from "../../constants";

const INITIAL_STATE = {
  userAssignment: {},
  courseSection: {},
  version: {},
  trainingObject: {},
  customerTrainingObjectAssignment: {},
  displayedLibraryCourseIds: [],
  displayedCourses: {
    [userAssignmentListTypes.latest]: [],
    [userAssignmentListTypes.recommended]: [],
    [userAssignmentListTypes.inPerson]: [],
    [userAssignmentListTypes.online]: [],
    [userAssignmentListTypes.webinar]: [],
    [userAssignmentListTypes.virtual]: [],
    [userAssignmentListTypes.userAssignments]: [],
    [userAssignmentListTypes.libraryCourses]: [],
    [userAssignmentListTypes.adminEnrolled]: [],
    [userAssignmentListTypes.self]: [],
    [userAssignmentListTypes.naaEligible]: []
  },
  libraryCoursesTotalCount: 0,
  loading: false,
  isFetched: false,
  loadingState: {
    [userAssignmentListTypes.latest]: false,
    [userAssignmentListTypes.recommended]: false,
    [userAssignmentListTypes.inPerson]: false,
    [userAssignmentListTypes.online]: false,
    [userAssignmentListTypes.webinar]: false,
    [userAssignmentListTypes.virtual]: false,
    [userAssignmentListTypes.userAssignments]: false,
    [userAssignmentListTypes.libraryCourses]: false,
    [userAssignmentListTypes.adminEnrolled]: false,
    [userAssignmentListTypes.self]: false,
    [userAssignmentListTypes.naaEligible]: false
  },
  error: null
};

function UserAssignmentsListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_ASSIGNMENTS_LIST_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        ...(action.payload && {
          loadingState: {
            ...state.loadingState,
            [action.payload]: true
          }
        })
      };

    case FETCH_USER_ASSIGNMENTS_LIST_SUCCESS:
    case UPDATE_USER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        trainingObject: {
          ...state.trainingObject,
          ...action.payload.trainingObject
        },
        userAssignment: mergeWith(
          {},
          state.userAssignment,
          action.payload.userAssignment,
          (a, b) => (isArray(b) ? b : undefined)
        ),
        version: merge({}, state.version, action.payload.version),
        iltInfo: {
          ...state.iltInfo,
          ...action.payload.iltInfo
        },
        instructor: {
          ...state.instructor,
          ...action.payload.instructor
        },
        trainingLocation: {
          ...state.trainingLocation,
          ...action.payload.trainingLocation
        },
        loading: false,
        isFetched: true,
        ...(action.payload.meta && {
          totalCount: action.payload.meta.totalCount
        }),
        ...(action.payload.listType && {
          loadingState: {
            ...state.loadingState,
            [action.payload.listType]: false
          }
        })
      };

    case FETCH_USER_ASSIGNMENTS_LIST_FAILURE:
      console.log("FETCH_USER_ASSIGNMENTS_LIST_FAILURE", action.payload.error);

      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case DELETE_USER_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        userAssignment: omit(state.userAssignment, action.payload)
      };
    }

    case FETCH_COURSE_CTOA_SUCCESS: {
      let newState = {
        ...state,
        userAssignment: {
          ...state.userAssignment,
          ...action.payload.userAssignment
        },
        courseSection: {
          ...state.courseSection,
          ...action.payload.courseSection
        },
        courseContentable: {
          ...state.courseContentable,
          ...action.payload.courseContentable
        },
        customerTrainingObjectAssignment: {
          ...state.customerTrainingObjectAssignment,
          ...action.payload.customerTrainingObjectAssignment
        }
      };

      if (action.payload.userAssignmentId) {
        newState = {
          ...newState,
          userAssignment: replaceRelatedResources(
            newState.userAssignment,
            action.payload.userAssignmentId,
            "courseSections",
            action.payload.courseSection
          )
        };
      }
      return newState;
    }

    case FETCH_TODO_SUCCESS: {
      return {
        ...state,
        userAssignment: merge(
          {},
          state.userAssignment,
          action.payload.userAssignment
        ),
        version: merge({}, state.version, action.payload.version)
      };
    }
    case UPDATE_DISPLAYED_COURSES: {
      return {
        ...state,
        displayedCourses: {
          ...state.displayedCourses,
          ...action.payload
        }
      };
    }
    case UPDATE_DISPLAYED_COURSE: {
      const displayedCourses = [...state.displayedCourses[action.payload.type]];
      displayedCourses.splice(
        displayedCourses.indexOf(action.payload.idToRemove),
        1,
        action.payload.idToAdd
      );
      return {
        ...state,
        displayedCourses: {
          ...state.displayedCourses,
          [action.payload.type]: displayedCourses
        }
      };
    }
    case CLEAR_USER_ASSIGNMENTS: {
      return {
        ...state,
        userAssignment: {}
      };
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default UserAssignmentsListReducer;
