import {
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
} from "../actions/userActions";

const INITIAL_STATE = {
  user: {
    firstName: "",
    lastName: "",
    settings: { show_banner: false, preferred_language: "en" },
    signInCount: 0,
    permissions: {}
  },
  loading: true,
  error: null
};

function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload.user
      };
    }

    case FETCH_USER_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        user: {}
      };

    case UPDATE_USER_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }
    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

export default UserReducer;
