const errorsReducer = (state = {}, action) => {
  const { type, payload } = action;

  const matches = /(.*)_(BEGIN|SUCCESS|FAILURE)/.exec(type);

  // not a *_BEGIN / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store whether a request received an error
    // e.g. will be true when receiving GET_TODOS_FAILURE
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_BEGIN
    [requestName]: requestState === "FAILURE" ? payload : null
  };
};

export default errorsReducer;
